import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
import axios from 'axios';
// import "./style.scss";
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { useHistory } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Switch from 'react-switch';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import Swal from 'sweetalert2';
const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState('');
  const [Max_rate, setMax_rate] = useState('');
  const [DeleteId, setDeleteId] = useState('');
  const [Data, setData] = useState({});
  const latestSliderValue = React.useRef([0, 0]);

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    // console.log('Filter changed. Calling GetAllprojects...');
    getData({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const setPriceFilter = () => {
    setFilter({ ...filters, min_rate: Min_rate, max_rate: Max_rate });
    getData({ min_rate: Min_rate, max_rate: Max_rate });
  };

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem('permission'));
  const Role = [
    {
      key: 'staff',
      name: 'Staff',
    },
    {
      key: 'carrier',
      name: 'Carrier',
    },
  ];
  let ListingData = [];
  if (user?.role == 'staff') {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };
  const sampledownload = async () => {
    try {
      const res = await axios({
        method: 'get',
        url: `${environment.api}sample-excel-truck`,
        responseType: 'blob',
      });
      var blob = new Blob([res.data], {
        type: res.headers['content-type'],
      });
      let downloadAnchor = document.createElement('a');
      downloadAnchor.href = window.URL.createObjectURL(blob);
      downloadAnchor.download = `SampleTruckFile.xlsx`;
      downloadAnchor.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const ImportFile = (e) => {
    loader(true);
    let files = e.target.files;
    let file = files?.item(0);
    let url = 'import/truck';
    if (!file) return;
    ApiClient.postFormData(url, { file }).then((res) => {
      if (res.success) {
        // toast.success(res.message);
        Swal.fire({
          // title: "Good job!",
          text: res.message,
          icon: 'success',
        });
        getData();
      }
      loader(false);
    });
  };
  return (
    <Layout2 title="Trucks" title2="Trucks">
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="mainareas">
        <div className="staff-full">
          <div className="d-flex justify-content-between align-items-center mb-4 wrapflex flex-set-w">
            <div className="bids-top">
              <h5 className="mb-0">
                Results <span>{total}</span>
              </h5>
            </div>

            <div className="d-flex gap-2 align-items-center flex-wrap">
              <div className=" searchParent">
                <input
                  className="form-control "
                  placeholder="Search..."
                  type="search"
                  value={filters.search}
                  name="search"
                  onChange={(e) => Handlefilter(e)}
                />
                <span class="material-icons">search</span>
              </div>
              {Permission?.carrier_add || user?.role == 'admin' ? (
                <>
                  <button className="btn btn-primary" onClick={(e) => add()}>
                    Add {role ? rolesModel.name(role) : 'Load'}
                  </button>
                </>
              ) : (
                <></>
              )}

              {filters.status || filters.role ? (
                <>
                  <button className="btn btn-primary " onClick={(e) => reset()}>
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
              <button className="btn btn-primary " onClick={(e) => add()}>
                <i class="fa fa-plus me-2" aria-hidden="true"></i>
                Add
              </button>
              <div>
                <button
                  onClick={(e) => sampledownload()}
                  className="btn dark-btn  btn-set"
                >
                  <i className="fa fa-download me-2"></i>Download
                </button>
              </div>
              <div className="result-set">
                <div className="relative text-center">
                  <label className="cursor-pointer  dark-btn btn">
                    <i className="fa fa-download me-2"></i>
                    <span>Import</span>
                    <input
                      id="bannerImage"
                      type="file"
                      className="hidden"
                      accept=".xlsx,.csv"
                      // value={form.baseImg ? form.baseImg : ""}
                      onChange={(e) => {
                        ImportFile(e);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <>
            <div>
              {/* <div className="staff-listing trucks-listing">
                <div className="staff-main">
                  <ul className="staff-inner">
                    <li>
                      <h5>Truck Number </h5>
                      <p> {itm.truck_number}</p>
                    </li>
                    <li>
                      <h5>VIN Number </h5>
                      <p>{itm.vin_number}</p>
                    </li>
                    <li>
                      <h5>Date Created</h5>
                      <p> {datepipeModel.date(itm.createdAt) || '--'}</p>
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="table-responsive table_section job-sect-table new-table">
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        Truck Number
                      </th>
                      <th scope="col" className="table_data pointer">
                        VIN Number
                      </th>
                      <th scope="col" className="table_data pointer">
                        Carrier Name
                      </th>
                      <th scope="col" className="table_data pointer">
                        Date Created
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      ListingData &&
                      ListingData.map((itm, i) => {
                        return (
                          <tr>
                            <td>{itm.truck_number}</td>
                            <td>{itm.vin_number}</td>
                            <td>{itm?.addedByDetails?.fullName}</td>
                            <td>
                              {' '}
                              {datepipeModel.date(itm.createdAt) || '--'}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>

          {!loaging && total == 0 ? (
            <div className=" no-data">
              <img src="assets/img/no-data.png" />
              No Data
            </div>
          ) : (
            <></>
          )}
          {!loaging && total > 0 ? (
            <div className="paginationWrapper main-pagination page-new">
              <div className="d-flex align-items-center">
                <div className="me-2 user-name-color">Show</div>
                {total > 0 && (
                  <div className="dropdown addDropdown chnagesname ">
                    <select
                      className="form-control"
                      value={filters?.count}
                      onChange={(e) => {
                        setFilter({ ...filters, count: e.target.value });
                        getData({ ...filters, count: e.target.value });
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="30">30</option>
                    </select>
                    <div
                      className="dropdown-menu shadow bg_hover"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 5 });
                        }}
                      >
                        5
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 10 });
                        }}
                      >
                        10
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 20 });
                        }}
                      >
                        20
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 30 });
                        }}
                      >
                        30
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 40 });
                        }}
                      >
                        40
                      </a>
                    </div>
                  </div>
                )}{' '}
                <div className="ms-2 user-name-color">from {total} Trucks</div>
              </div>

              <div className="">
                <Pagination
                  currentPage={filters.page}
                  totalSize={total}
                  sizePerPage={filters.count}
                  changeCurrentPage={pageChange}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {loaging ? (
            <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
