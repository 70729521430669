import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { useHistory } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Switch from 'react-switch';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import ApiClient from '../../methods/api/apiClient';
import { Tooltip } from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { FaCirclePlus } from 'react-icons/fa6';

const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState('');
  const [Max_rate, setMax_rate] = useState('');
  const [DeleteId, setDeleteId] = useState('');
  const latestSliderValue = React.useRef([0, 0]);

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    // console.log('Filter changed. Calling GetAllprojects...');
    getData({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const setPriceFilter = () => {
    setFilter({ ...filters, min_rate: Min_rate, max_rate: Max_rate });
    getData({ min_rate: Min_rate, max_rate: Max_rate });
  };

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem('permission'));
  const Role = [
    {
      key: 'staff',
      name: 'Staff',
    },
    {
      key: 'carrier',
      name: 'Carrier',
    },
  ];
  let ListingData = [];
  if (user?.role == 'staff') {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  const Handlefilter = (e) => {
    const value = e.target.value;

    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };
  return (
    <Layout2 title="Staff" title2="Staff">
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="mainareas">
        <div className="staff-full">
          <div className="d-flex justify-content-between align-items-center mb-4 wrapflex">
            <div className="bids-top">
              <h5 className="mb-0">
                Results <span>{total}</span>
              </h5>
            </div>

            <div className=" d-flex align-items-center">
              <div className=" searchParent me-2">
                <input
                  className="form-control me-2"
                  placeholder="Search..."
                  type="search"
                  value={filters.search}
                  name="search"
                  onChange={(e) => Handlefilter(e)}
                />
                <span class="material-icons">search</span>
              </div>
              <div className="dropdown addDropdown chnagesg ms-0 p-0 borer-c d-flex align-items-center ">
                <button
                  className="btn blck-border-btn dropdown-toggle removeBg"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters.status
                    ? filters.status == 'deactive'
                      ? 'Inactive'
                      : filters.status
                    : 'All Status'}
                </button>

                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className={
                      filters.status == ''
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('')}
                  >
                    All Status
                  </a>
                  <a
                    className={
                      filters.status == 'active'
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('active')}
                  >
                    Active
                  </a>
                  <a
                    className={
                      filters.status == 'Inactive'
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('deactive')}
                  >
                    Inactive
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center   justify-content-end ms-2">
                {Permission?.carrier_add || user?.role == 'admin' ? (
                  <>
                    <button
                      className="btn btn-primary   "
                      onClick={(e) => add()}
                    >
                      {/* <i className="fa fa-plus me-2"></i> */}
                      Add {role ? rolesModel.name(role) : 'Load'}
                    </button>
                  </>
                ) : (
                  <></>
                )}

                {filters.status || filters.role ? (
                  <>
                    <button
                      className="btn btn-primary undo me-2"
                      onClick={(e) => reset()}
                    >
                      <i class="fa fa-undo me-2" aria-hidden="true"></i>
                      Reset
                    </button>
                  </>
                ) : (
                  <></>
                )}
                <button
                  className="btn btn-primary d-flex align-items-center "
                  onClick={(e) => add()}
                >
                  <FaCirclePlus className="me-2 " />
                  Add Staff
                </button>
              </div>
            </div>
          </div>

          <>

            <div>

              <div className="table-responsive table_section job-sect-table new-table">
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        Staff Name
                      </th>
                      <th scope="col" className="table_data pointer">
                        Email
                      </th>
                      <th scope="col" className="table_data pointer">
                        Carrier/Staff Name
                      </th>
                      <th scope="col" className="table_data pointer">
                        Date Created
                      </th>

                      <th scope="col" className="table_data pointer">
                        Status
                      </th>
                      <th scope="col" className="table_data pointer">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      ListingData &&
                      ListingData.map((itm, i) => {
                        return (
                          <tr>
                            <td>
                              {methodModel.capitalizeFirstLetter(itm?.fullName)}
                            </td>
                            <td>{itm?.email}</td>
                            <td>{itm?.addedBy_name}</td>
                            <td>
                              {datepipeModel.date(itm.createdAt) || '--'}
                            </td>

                            <td>
                              <div className={` ${itm?.status}`}>
                                <span className="custom-toggle-btn heightbtn">
                                  {itm?.status == 'deactive' ? (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={false}
                                    />
                                  ) : (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={true}
                                    />
                                  )}
                                </span>
                              </div>
                            </td>

                            <td>

                              <div className="d-flex align-items-center action-btns ">
                                <a
                                  class="  me-2"
                                  onClick={() => {
                                    Navigate.push(`/users/detail/${itm.id}`);
                                  }}
                                >
                                  <span className="">
                                    <i class="fa fa-eye"></i>
                                  </span>{' '}
                                </a>

                                <a
                                  onClick={() => {
                                    Navigate.push(`/users/edit/${itm?.id}`);
                                  }}
                                  class="  "
                                >
                                  <span className="">
                                    <i class="material-icons edit editButton">
                                      {' '}
                                      edit
                                    </i>
                                  </span>{' '}
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

          </>

          {!loaging && total == 0 ? (
            <div className=" no-data">
              <img src="assets/img/no-data.png" />
              No Data
            </div>
          ) : (
            <></>
          )}

          {!loaging && total > 0 ? (
            <div className="paginationWrapper main-pagination page-new ">
              <div className="d-flex align-items-center">
                <div className="me-2 user-name-color">Show</div>
                {total > 0 && (
                  <div className="dropdown addDropdown chnagesname ">
                    <select
                      className="form-control"
                      value={filters?.count}
                      onChange={(e) => {
                        setFilter({ ...filters, count: e.target.value });
                        getData({ ...filters, count: e.target.value });
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="30">30</option>
                    </select>
                    <div
                      className="dropdown-menu shadow bg_hover"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 5 });
                        }}
                      >
                        5
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 10 });
                        }}
                      >
                        10
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 20 });
                        }}
                      >
                        20
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 30 });
                        }}
                      >
                        30
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 40 });
                        }}
                      >
                        40
                      </a>
                    </div>
                  </div>
                )}{' '}
                <div className="ms-2 user-name-color">from {total} Staff</div>
              </div>

              <div className="">
                <Pagination
                  currentPage={filters.page}
                  totalSize={total}
                  sizePerPage={filters.count}
                  changeCurrentPage={pageChange}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {loaging ? (
            <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
