import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./style.scss";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import methodModel from "../../methods/methods";

const Forgotpassword = () => {
  const history = useHistory();
  const website = useSelector((state) => state.website);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/dashboard");
    }
  }, []);

  const [form, setForm] = useState({ email: "" });

  useEffect(() => {}, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    loader(true);

    ApiClient.post("user/forgot-password", { ...form }).then((res) => {
      if (res.success) {
        // toast.success(res.message);
        history.push("/otp-verify");
      }
      loader(false);
    });
  };
  useEffect(() => {
    localStorage.removeItem("Step1");
    localStorage.removeItem("Step2");
  }, []);

  return (
    <>
      <div className="main_signup bg_img">
        <div className="center-img">
          <div className="container ">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="right_side">
                  <div className="logo_image mb-4">
                    <img src="assets/img/logo-m.png" className="logo_name" />
                  </div>
                  <form className="centerLogin" onSubmit={hendleSubmit}>
                    <div className="forgot-pw-text ">
                      <h3 className="text-left lgtext mb-3">
                        Forgot password?
                      </h3>
                      <p className="para_forget">
                        "It's all good! Enter your email, and we'll send a reset
                        password link your way"
                      </p>
                    </div>

                    <div className="mb-3">
                      <div className="inputWrapper">
                        <input
                          type="email"
                          className="form-control  mb-0 bginput changes"
                          placeholder="Email"
                          value={form.email}
                          required
                          onChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="buttons mt-4">
                      <button
                        type="submit"
                        className="btn dark-btn loginclass mb-1
                          "
                      >
                        Send Recovery Email
                      </button>
                    </div>

                    <p className="accopuntt">
                      {" "}
                      Just Remember?
                      <Link className="sign_up" to="/login">
                        {" "}
                        Sign in
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
