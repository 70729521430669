import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
// import "./style.scss";
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import { useHistory } from 'react-router-dom';
import { IoTrophyOutline } from 'react-icons/io5';
import { FaAward } from 'react-icons/fa6';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TbArrowsExchange } from 'react-icons/tb';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { IoIosArrowRoundForward } from 'react-icons/io';

import { PiSmileySad } from 'react-icons/pi';
import { TbBrandAppgallery } from 'react-icons/tb';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import loader from '../../methods/loader';
import { Tooltip } from 'antd';
import moment from 'moment';
import environment from '../../environment';
import { BsThreeDots } from 'react-icons/bs';
import ImageUpload from '../../components/common/ImageUpload';
import { Link } from 'react-router-dom';
const Html = ({
  accepted,
  rejected,
  ChangeFilter,
  UpdateTransitStatus,
  sorting,
  setFilter,
  pageCompleteBidChange,
  pageRejectBidChange,
  Awarded,
  GetAwarded,
  rejectBid,
  pageChange,
  completeBid,
  filters,
  loaging,
  alldata,
  getData,
  TAB,
  settab,
  Loading,
  url,
  setLoading,
  role,
  total = { total },
}) => {
  const Stopss = ({ stop }) => {
    return (
      <>
        {itm?.stops_details?.length != 0
          ? itm?.stops_details?.map((item, index) => {
            return (
              <Tooltip title={item?.address}>
                <IoArrowBackCircleSharp />
                <span className="destspan">{item?.address}</span>
              </Tooltip>
            );
          })
          : ''}
      </>
    );
  };
  const history = useHistory();

  const [Setting, setSetting] = useState({});
  const [form, setform] = useState({ agreed_rate: '', id: '' });
  const [loadDetails, setLoadDetails] = useState({ document: '' });
  const [files, setFIles] = useState([]);
  const [Notes, SetNotes] = useState({ note: '' });


  const HandleSubmit = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.put('change-rate', form).then((res) => {
      if (res.success) {
        // toast.success(res.message);
        document.getElementById('CloseBidUpdateModel').click();
        getData();
      }
    });
    loader(false);
  };

  const [Document, setdocuments] = useState([]);
  const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);

  const addFileInput = () => {
    setFileInputs([...fileInputs, { id: fileInputs.length + 1, file: null }]);
  };
  const removeFileInput = (id, file) => {
    setFileInputs(fileInputs.filter((input) => input.id !== id));
    setdocuments(Document.filter((itm) => itm != file));
    // if (file) {
    //   ApiClient.delete(`delete/document?fileName=${file}`).then((res) => {});
    // }
    if (id == 1) {
      setFileInputs([{ id: 1, file: null }]);
    }
  };
  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes('_')) {
      return methodModel.capitalizeFirstLetter(str.split('_').join(' '));
    }
    if (str == 'pickedup') {
      return 'Picked Up';
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  const UpdatePickedUpStatus = (e) => {
    e.preventDefault();
    if (Document?.length != 0) {

    } else {
      alert('Please upload document');
    }
    setLoading('d-flex');
    if (!form?.load_id || Document?.length == 0) {
      return;
    }
    ApiClient.put('load-status', {
      load_id: form?.load_id,
      bol_doc: Document,
      shipment_status: 'pickedup',
    }).then((res) => {
      if (res.success) {
        setLoading('d-none');

        loader(false);
        // toast.success(res?.message);
        document.getElementById('CloseBOLDocumentsModal').click();
        getData();
        setLoading('d-none');
      }
      loader(false);
    });
  };

  const HandleUploadDocument = async (e, id) => {
    setLoading('d-flex');
    const token = localStorage.getItem('token');

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    let file = e.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    const UploadData = await fetch(
      `${environment.api}upload/image-document?modelName=users`,
      {
        method: 'post',
        body: formdata,
        headers: headers,
      }
    );
    let res = await UploadData.json();

    if (res.success) {
      files.push(e?.target?.files[0]?.name);
      setLoadDetails({ ...loadDetails, document: res?.data?.fullpath });
      const updatedFileInputs = fileInputs.map((input) =>
        input.id === id ? { ...input, file: res?.data?.fullpath } : input
      );
      Document.push(`${res?.data?.fullpath}`);

      setFileInputs(updatedFileInputs);
      setLoading('d-none');
    } else {
      toast.error(res?.error?.message);

      removeFileInput(id, null);

      Document.pop();
      setLoading('d-none');
    }
  };
  const GetSettings = () => {
    ApiClient.get('settings').then((res) => {
      if (res.success) {
        setSetting(res?.data);
      }
    });
  };
  const CheckIn = (stop_id, load_id, prevoiusStop, index, data) => {
    setLoading('d-flex');
    if (index == data?.length && Document?.length == 0) {
      return setLoadDetails({ stop_id, load_id, prevoiusStop, data });
    }

    if (prevoiusStop) {
      ApiClient.put('load-status', {
        load_id,
        shipment_status: 'in_transit',
        stop_id: prevoiusStop,
        checkout: moment(),
      }).then((res) => { });
    }

    setLoading('d-flex');
    let payload = {
      load_id,
      shipment_status: index == data?.length ? 'delivered' : 'in_transit',
      stop_id,

      checkin: moment(),
    };
    if (index == 0) {
      payload = { ...payload, bol_doc: Document };
    }

    ApiClient.put('load-status', payload).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        getData();
        completeBid();
        rejectBid();
        setdocuments([]);
        setFileInputs([{ id: 1, file: null }]);

        setLoading('d-none');
      }
      setLoading('d-none');
    });
    setTimeout(() => { }, 1000);
  };

  const HandleNoteSubmit = (e) => {
    e.preventDefault();
    setLoading('d-flex');
    ApiClient.post('carrier-note', {
      title: Notes?.title,
      description: Notes?.description,
      load_id: Notes?.load_id,
      stop_id: Notes?.stop_id,
      new_eta: moment(Notes?.new_eta).format('YYYY-MM-DDTHH:mm'),
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        document.getElementById('CloseNotesModal').click();
        setLoading('none');
      }
    });
  };

  const UpdateStopWithDocuments = () => {
    setLoading('d-flex');
    // e.preventDefault()
    if (
      !loadDetails?.load_id ||
      !loadDetails?.stop_id ||
      Document?.length == 0
    ) {
      return false;
    }
    if (loadDetails?.prevoiusStop) {
      ApiClient.put('load-status', {
        load_id: loadDetails?.load_id,
        shipment_status: 'in_transit',
        stop_id: loadDetails?.prevoiusStop,
        checkout: moment(),
      }).then((res) => { });
    }
    let payload = {
      load_id: loadDetails?.load_id,
      shipment_status: 'delivered',
      stop_id: loadDetails?.stop_id,
      checkin: moment(),
      pod_doc: Document,
    };
    if (loadDetails?.prevoiusStop) {
      setTimeout(() => {
        ApiClient.put('load-status', payload).then((res) => {
          if (res.success) {
            // toast.success(res?.message);
            setLoading('d-none');

            document.getElementById('CloseDocumentModal').click();
            getData();
            completeBid();
            setdocuments([]);
            setFileInputs([{ id: 1, file: null }]);
            setLoadDetails({ document: '' });
            setFIles(['']);
            rejectBid();
          }
          setLoading('d-none');
        });
      }, 1000);
    } else {
      ApiClient.put('load-status', payload).then((res) => {
        if (res.success) {
          setLoading('d-none');

          // toast.success(res?.message);
          document.getElementById('CloseDocumentModal').click();
          getData();
          setdocuments([]);
          setFileInputs([{ id: 1, file: null }]);
          setLoadDetails({ document: '' });
          setFIles([]);
          completeBid();
          rejectBid();
        }
        setLoading('d-none');
      });
    }
  };

  useEffect(() => {
    GetSettings();
  }, []);

  const ConvertMarginType = (data) => {
    switch (data?.margin_type) {
      case 'percentage':
        return `${data?.margin_value} %`;
        break;
      case 'amount':
        return `$ ${data?.margin_value}`;
      default:
        return `${data?.margin_value} ${methodModel.capitalizeFirstLetter(
          data?.margin_type
        )}`;
        break;
    }
  };
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };

  const clearUploadForm = () => {
    setdocuments([]);
    setFileInputs([{ id: 1, file: null }]);
    setLoadDetails({ document: '' });
    setFIles([]);
  };

  return (
    <Layout2 title="Bids" title2={TAB}>
      <div className="mainareas">
        <div className="bids-top mb-4">
          <h4>
            Results<span> {total}</span>
          </h4>
          <div className=" searchParent">
            <input
              className="form-control "
              placeholder="Search By Load Id ,City"
              type="search"
              value={filters.search}
              name="search"
              onChange={(e) => Handlefilter(e)}
            />
            <span class="material-icons">search</span>
          </div>
        </div>

        {!loaging &&
          alldata &&
          alldata.map((itm, i) => {
            return (
              <div className="bids-new">
                <div className="bids-data mb-4">
                  <div className="bids-upp-m">
                    <div className="loc-flex">
                      <img src="/assets/img/origin.svg" />
                      <h5 className="text-capitalize">
                        {itm?.load_info?.origin_location_city || '--'}
                        <span>Origin City</span>
                      </h5>
                    </div>
                    <p>
                      <TbArrowsExchange />
                    </p>
                    <div className="loc-flex">
                      <img src="/assets/img/destination.svg" />
                      <h5>
                        {itm?.load_info?.destination_location_city || '--'}
                        <span>Destination City</span>
                      </h5>
                    </div>
                  </div>
                  <div className="bids-upper">
                    <div className="bids-middle">
                      {itm?.shipment_status == "pending" ? "" : <h5>
                        <span>Pickup Date :</span>
                        {/* {datepipeModel.date(itm?.pickedup_at)},{' '}
                        {datepipeModel.isotime(itm?.pickedup_at)} */}
                        {moment(
                          itm?.pickedup_at
                        ).format(
                          'DD-MMM-YYYY, h:mm A'
                        )}

                      </h5>}

                      <h5>
                        <span>Expiration Date :</span>{' '}
                        {/* {datepipeModel.date(itm?.expiration_date)},{' '}
                        {datepipeModel.isotime(itm?.expiration_date)} */}
                        {moment(
                          itm?.expiration_date
                        ).format(
                          'DD-MMM-YYYY, h:mm A'
                        )}

                      </h5>
                      <h5>
                        <span>Bids ETA :</span>{' '}
                        {datepipeModel.date(itm?.bid_time)},{' '}
                        {datepipeModel.isotime(itm?.bid_time)}
                      </h5>
                      {itm?.status == 'rejected' || itm?.shipment_status == "pending" ? (
                        <></>
                      ) : (
                        <h5>
                          <span>Delivery Status:</span>{' '}
                          <p
                            className={`${itm?.shipment_status}_badgeNew mb-0 badge `}
                          >
                            {itm?.shipment_status=="in_transit"?"In Transit":itm?.shipment_status}
                          </p>
                        </h5>
                      )}
                    </div>
                    <div className="bids-bottom">
                      <h3>
                        Stops <span>{itm?.stops_details?.length}</span>
                      </h3>

                      <div className="dotdiv d-flex flex-column">
                        {' '}
                        {/* <Stopss stop={itm?.stops_details}/> */}
                        {itm?.shipment_status == 'pending' &&
                          itm?.status == 'awarded' ? (
                          <>
                            <div className="d-flex  mb-1 justify-content-between flex-column">

                              {itm?.stops_details?.length != 0
                                ? itm?.stops_details?.map((item, index) => {
                                  return (
                                    <Tooltip title={item?.address}>
                                      {index < 3 ? (
                                        <>
                                          <div className="  d-flex align-items-center w-80">
                                            <span className=" mb-0 destspan">
                                              <IoArrowBackCircleSharp />
                                              {item?.address}
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Tooltip>
                                  );
                                })
                                : ''}


                            </div>
                            <div className='d-flex justify-content-end'>
                              <div
                                class="dropdown dotsbtn2 text-center p-0"
                                title="Options"
                              >
                                <button
                                  class="btn dropdown-toggle "
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                 <div>
                                 <BsThreeDots />
                                 </div>
                                </button>
                                <div class="dropdown-menu">
                                  <a
                                    class="dropdown-item"
                                    onClick={() => {
                                      document
                                        .getElementById('OpenBOLDocumentsModal')
                                        .click();

                                      setform({
                                        ...form,
                                        load_id: itm?.load_id,
                                      });
                                    }}
                                  >
                                    Picked Up
                                  </a>
                                </div>
                              </div>
                            </div>
                            {itm?.stops_details?.length > 3 ? (
                              <Tooltip
                                title={
                                  <div className=''>
                                    <ul className='all-stops-tool'>
                                      {itm?.stops_details?.map((item) => (
                                        <li>
                                          {itm?.status == 'awarded' ? (
                                            <>
                                              {item?.checkin ?(
                                                <Tooltip
                                                  title={`Reached ${item?.address
                                                    } at  ${moment(
                                                      item?.checkin
                                                    ).format(
                                                      'DD-MMM-YYYY h:mm A'
                                                    )}`}
                                                >
                                                  <span
                                                    onMouseEnter={(e) => { }}
                                                    className="tableficon"
                                                  >
                                                    <i
                                                      class="fa fa-map-marker text-success"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </Tooltip>
                                              ):<i
                                              class="fa fa-truck me-2"
                                              // title="In-Transit"
                                              aria-hidden="true"
                                            ></i>}
                                            </>
                                          ) : <span className='tableficon'> <i
                                          class="fa fa-truck  text-success"
                                          // title="In-Transit"
                                          aria-hidden="true"
                                        ></i></span>}
                                          {item?.address}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                }
                              >
                                <div className="all-stops-list">
                                  <span className="">All Stops</span>
                                </div>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <>
                            {itm?.stops_details?.length != 0
                              ? itm?.stops_details?.map((item, index) => {
                                return (
                                  <>
                                    <div className='d-flex align-items-center '>
                                      <Tooltip
                                        title={`${item?.checkin ? 'CheckedIn-' : ''
                                          } ${item?.address} ${item?.checkout ? '-CheckedOut' : ''
                                          }`}
                                      >
                                        {index < 3 ? (
                                          <span className=" mb-1 destspan">
                                            {' '}
                                            <IoArrowBackCircleSharp />{' '}
                                            {item?.address}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </Tooltip>

                                      {itm?.status == 'awarded' ? (
                                        <>
                                          {item?.checkin ||
                                            (!itm?.stops_details[index - 1]
                                              ?.checkin &&
                                              index > 0) ? null : (
                                            <Tooltip>
                                              <div
                                                class="dropdown dotsbtn dots-end dots-set"
                                                title="Options"
                                              >
                                                <button
                                                  class="btn dropdown-toggle icon-end"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <BsThreeDots
                                                    onClick={() => {
                                                      console.log(fileInputs);
                                                    }}
                                                  />
                                                </button>
                                                <div class="dropdown-menu">
                                                  <a
                                                    class="dropdown-item"
                                                    data-bs-toggle={
                                                      index + 1 ==
                                                        itm?.stops_details?.length
                                                        ? 'modal'
                                                        : ''
                                                    }
                                                    data-bs-target={
                                                      index + 1 ==
                                                        itm?.stops_details?.length
                                                        ? '#DocumentModal'
                                                        : ''
                                                    }
                                                    title="Checkin"
                                                    onClick={() => {
                                                      clearUploadForm();
                                                      // console.log(
                                                      //   fileInputs,
                                                      //   '==============DATA'
                                                      // );
                                                      // console.log(
                                                      //   fileInputs,
                                                      //   '==============DATA'
                                                      // );
                                                      setFileInputs([{}]);
                                                      if (
                                                        index + 1 ==
                                                        itm?.stops_details
                                                          ?.length
                                                      ) {
                                                        return setLoadDetails({
                                                          stop_id: item?._id,
                                                          load_id: itm?.load_id,
                                                          prevoiusStop:
                                                            itm?.stops_details[
                                                              index - 1
                                                            ]?._id,
                                                          data: itm?.stops_details,
                                                        });
                                                      }
                                                      CheckIn(
                                                        item?._id,
                                                        itm?.load_id,
                                                        itm?.stops_details[
                                                          index - 1
                                                        ]?._id,
                                                        index + 1,
                                                        itm?.stops_details
                                                      );
                                                    }}
                                                  >
                                                    Checkin
                                                  </a>
                                                  <a
                                                    class="dropdown-item"
                                                    title="Shipment Notes"
                                                    onClick={() => {
                                                      document
                                                        .getElementById(
                                                          'OpenNotesModal'
                                                        )
                                                        .click();
                                                      SetNotes({
                                                        ...Notes,
                                                        load_id: itm?.load_id,
                                                        stop_id: item?._id,
                                                      });
                                                    }}
                                                  >
                                                    Add Note
                                                  </a>
                                                </div>
                                              </div>
                                            </Tooltip>
                                          )}
                                          {index < 3 ? (
                                            <>
                                              {' '}
                                              {item?.checkin && (
                                                <Tooltip
                                                  title={`Reached ${item?.address
                                                    } at  ${moment(
                                                      item?.checkin
                                                    ).format(
                                                      'DD-MMM-YYYY h:m A'
                                                    )}`}
                                                >
                                                  <span
                                                    onMouseEnter={(e) => { }}
                                                    className="tableficon"
                                                  >
                                                    <i
                                                      class="fa fa-map-marker text-success"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </Tooltip>
                                              )}
                                            </>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </div>
                                  </>
                                );
                              })
                              : '--'}{' '}
                            {itm?.stops_details?.length > 3 ? (
                              <Tooltip
                                title={
                                  <div>
                                    <ul className="all-stops-tool">
                                      {itm?.stops_details?.map((item) => (
                                        <li>
                                          {itm?.status == 'awarded' ? (
                                            <>
                                              {item?.checkin ? (
                                                <span
                                                  onMouseEnter={(e) => { }}
                                                  className="tableficon"
                                                >
                                                  <i
                                                    class="fa fa-map-marker text-success"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              ):<i
                                              class="fa fa-truck me-2"
                                              // title="In-Transit"
                                              aria-hidden="true"
                                            ></i>}
                                            </>
                                          ) :<span className='tableficon'> <i
                                          class="fa fa-truck  text-success"
                                          // title="In-Transit"
                                          aria-hidden="true"
                                        ></i></span>}
                                          {item?.address}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                }

                              >
                                <div className="all-stops-list">
                                  <span className="">All Stops</span>
                                </div>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </div>

                    </div>
                    <div className="bids-right text-end">
                      <div className="bid-p mb-2">
                        ${itm?.carrier_info?.agreed_rate || '--'}
                      </div>
                      {TAB == 'Bids' ? (
                        <div className="d-flex justify-content-end status-p">
                          <h5
                            className={`${itm?.status}_badgeNew mb-0 badge badge-w`}
                          >
                            {itm?.status}
                          </h5>
                        </div>
                      ) : (
                        <></>
                      )}

                      {itm?.status == 'pending' ? (
                        <>
                          <div className="view-detail">
                            <span
                              className="pointer text-primary"
                              onClick={(e) => {
                                document
                                  .getElementById('OpenBidEditModel')
                                  .click();
                                setform({
                                  ...form,
                                  id: itm?.id,
                                  agreed_rate: itm?.agreed_rate || '',
                                });
                              }}
                            >
                              Update Bid Price
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="view-detail">
                        <Link to={`/bids/Detail/${itm?.id}`}>
                          View Details
                          <IoIosArrowRoundForward />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {!loaging && total > 0 ? (
          <div className="paginationWrapper main-pagination page-new">
            <div className="d-flex align-items-center">
              <div className="me-2 user-name-color">Show</div>
              {total > 0 && (
                <div className="dropdown addDropdown chnagesname ">
                  <select
                    className="form-control"
                    value={filters?.count}
                    onChange={(e) => {
                      setFilter({ ...filters, count: e.target.value });
                      getData({ ...filters, count: e.target.value });
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="30">30</option>
                  </select>
                  <div
                    className="dropdown-menu shadow bg_hover"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 5 });
                      }}
                    >
                      5
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 10 });
                      }}
                    >
                      10
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 20 });
                      }}
                    >
                      20
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 30 });
                      }}
                    >
                      30
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 40 });
                      }}
                    >
                      40
                    </a>
                  </div>
                </div>
              )}{' '}
              <div className="ms-2 user-name-color">from {total} Bids</div>
            </div>

            <div className="">
              <Pagination
                currentPage={filters.page}
                totalSize={total}
                sizePerPage={filters.count}
                changeCurrentPage={pageChange}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {!loaging && total == 0 ? (
          <div className=" no-data">
            <img src="/assets/img/no-data.png" />
            No Data
          </div>
        ) : (
          <></>
        )}
      </div>

      <button
        type="button "
        id="OpenBidEditModel"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Update Bid Price
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                id="CloseBidUpdateModel"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <form onSubmit={HandleSubmit} className="borderFor">
                <div className="mb-3">
                  <div className="d-flex align-items-center checklistFirstFlex ">
                    <div className="paddningMod">
                      <img
                        src="/assets/img/checklist.png"
                        alt=""
                        className="checkListImg"
                      />
                    </div>
                    <label htmlFor="">
                      Agreed Rate
                      <label className="text-danger">*</label>
                    </label>
                  </div>
                </div>
                <div className="mx-3">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="w-100">
                      {' '}
                      <input
                        value={form?.agreed_rate}
                        required
                        min={0}
                        multiple
                        type="number"
                        onChange={(e) => {
                          setform({ ...form, agreed_rate: e.target.value });
                        }}
                        className="form-control"
                        placeholder="Enter Bid Rate"
                        name=""
                        id=""
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        class="btn dark-btn mx-auto whiteit"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mx-4 mt-4">
                  {' '}
                  <div className="row">
                    <div className="col-md-12 ">
                      {/* <div className="mb-4">
                      <div>
                        <img src="/assets/img/checklist.png" alt="" className="checkListImg" />
                      </div>
                    </div> */}
                    </div>
                    <div className="col-md-12 ">
                      <div className="mb-0">
                        {/* <label htmlFor="">
                        Agreed Rate
                        <label className="text-danger">*</label>
                      </label> */}
                        {/* <input
                        value={form?.agreed_rate}
                        required
                        min={0}
                        multiple
                        type="number"
                        onChange={(e) => {
                          setform({ ...form, agreed_rate: e.target.value });
                        }}
                        className="form-control mt-2"
                        placeholder="Enter Bid Rate"
                        name=""
                        id=""
                      /> */}
                      </div>
                    </div>
                    <div class="">
                      {/* <button
                      id="CloseBidUpdateModel"
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button> */}
                      {/* <button type="submit" class="btn btn-primary mx-auto">
                      Save changes
                    </button> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}

      <div
        class="modal fade  doc-modal"
        id="DocumentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Upload POD (Proof of Delivery)
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  clearUploadForm();
                }}
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={UpdateStopWithDocuments}>
              <div class="modal-body documentation_modal position-relative">
                <ImageUpload
                  idd="PODImage"
                  value={Document}
                  multiple={true}
                  result={(e) => {
                    setdocuments(e.value);
                  }}
                />

              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseDocumentModal"
                  onClick={() => {
                    clearUploadForm();
                  }}
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => {
                    if (Document?.length != 0) {
                      UpdateStopWithDocuments();
                    } else {
                      alert('Please upload document');
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade  doc-modal"
        id="BOLDocumentModel"
        tabindex="-1"
        aria-labelledby="exampleBOLDocumentModel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleBOLDocumentModel">
                Upload BOL ( Bill of lading )
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  clearUploadForm();
                }}
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={UpdatePickedUpStatus}>
              <div class="modal-body documentation_modal position-relative">
                <ImageUpload
                  idd="BOLImage"
                  value={Document}
                  multiple={true}
                  result={(e) => {
                    setdocuments(e.value);
                  }}
                />

              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseBOLDocumentsModal"
                  onClick={() => {
                    clearUploadForm();
                  }}
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="OpenBOLDocumentsModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#BOLDocumentModel"
      >
        Launch demo modal
      </button>
      <button
        type="button"
        id="OpenNotesModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#NoteModal"
      >
        Launch demo modal
      </button>
      <div
        class="modal fade"
        id="NoteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Shipment Notes
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                HandleNoteSubmit(e);
              }}
            >
              <div class="modal-body  position-relative">
                <div className="col-md-6 mb-3">
                  <label>
                    Title <label className="text-danger">*</label>
                  </label>
                  <input
                    placeholder="Enter Title"
                    value={Notes?.title}
                    type="text"
                    required
                    onChange={(e) => {
                      SetNotes({ ...Notes, title: e.target.value });
                    }}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-md-6 mb-3 mt-3 ">
                  <label htmlFor="">
                    Description <label className="text-danger">*</label>
                  </label>
                  <textarea
                    value={Notes?.description}
                    cols={10}
                    type="text"
                    placeholder="Enter Description"
                    required
                    onChange={(e) => {
                      SetNotes({ ...Notes, description: e.target.value });
                    }}
                    className="form-control mb-2"
                  />
                </div>
                <div className="col-md-6 mb-3 mt-3 pickdate ">
                  <span className="d-block">
                    {' '}
                    <label htmlFor="" className="d-flex">
                      New ETA <label className="text-danger d-block">*</label>
                    </label>
                  </span>
                  <DatePicker
                    selected={Notes?.new_eta}
                    className="form-control w-100"
                    minDate={Date.now()}
                    onChange={(e) => {
                      SetNotes({ ...Notes, new_eta: e });
                    }}
                    showTimeSelect
                    dateFormat="dd-MM-yyyy h:mm a"
                    required
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseNotesModal"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
