import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";
import { useSelector } from "react-redux";
import addressModel from "../../models/address.model";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function SignUp() {
  const [EmailError, setEmailError] = useState(false);
  const [percent, setPercent] = useState(10);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const Step1 = JSON.parse(localStorage.getItem("Step1"));
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const [form, setForm] = useState({
    address: "",
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    password: "",
    business_name: "",
  });

  useEffect(() => {
    if (Step1) {
      setForm(Step1);
    }
  }, []);

  const Emailvalidation = (email) => {
    const regex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (regex.test(email)) {
      ApiClient.get(`user/company-name?email=${email}`).then((res) => {
        if (res.success) {
          if(res?.data && res?.data?.company_name)
          {
            localStorage.setItem("ComapnyName", JSON.stringify(res?.data?.company_name));
          }
          else
          {
            localStorage.removeItem("ComapnyName")
          }

        }
      });
    }
    setForm({ ...form, email: email });
  }

  const handleSubmit = (e) => {

    e.preventDefault();
    setSubmitted(true);
    if (form?.password != form?.confirmPassword) {
      return toast.error("Password and comfirm password did not match");
    }


    if (
      !emailvalidation() ||
      !form?.firstName ||
      !form?.password ||
      !form?.email ||
      form?.password != form?.confirmPassword ||
      !form?.position
    ) {
      return false;
    }
    loader(true);
    let value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      email: form?.email,
      confirmPassword: form?.confirmPassword,
      password: form?.password,
      position: form?.position,
      role: "carrier",
      // identification_number: form?.identification_number,
      // business_name: form?.business_name,
    };
    localStorage.setItem("Step1", JSON.stringify(value));
    loader(false);
    history.push("signup-step-1");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      _linkedin_partner_id = "6323372";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);

      (function(l) {
        if (!l){
          window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[];
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;
    document.head.appendChild(script);

    // Add the noscript image
    const noscriptImg = document.createElement("img");
    noscriptImg.height = 1;
    noscriptImg.width = 1;
    noscriptImg.style.display = "none";
    noscriptImg.alt = "";
    noscriptImg.src =
      "https://px.ads.linkedin.com/collect/?pid=6323372&fmt=gif";
    document.body.appendChild(noscriptImg);

    // Clean up the script and image when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscriptImg);
    };
  }, []);

  const emailvalidation = () => {
    if (form?.email) {
      let splitEmail = form?.email?.split("@")[1]
      if (splitEmail && (splitEmail.includes("yahoo.com") || splitEmail.includes("gmail.com") || splitEmail.includes("outlook.com") || splitEmail.includes("hotmail.com"))) {
        return false
      }
      else {
        return true
      }
    }

  }
  return (
    <div className="bg_img main_signup signup-page">
      <div className="center-img  ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <div className="right_side ">
                <Link to="/">
                  <div className="logo_image mb-3">
                    <img src="assets/img/logo-m.png" className="logo_name" />
                  </div>
                </Link>

                <form
                  autoComplete="off"
                  className="centerLogin"
                  onSubmit={handleSubmit}
                >
                  <div className="text-center mb-4">
                    <h3 className="text-center lgtext">Create your account.</h3>
                    <p className="accopunt text-center p-0 mb-3">
                      Already have an account?{" "}
                      <Link className="sign_up" to="/login">
                        {" "}
                        Sign In
                      </Link>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="progressbar-num">
                        <ProgressBar
                          percent={percent}
                          filledBackground="linear-gradient(to right,rgb(63 85 158), rgb(150 162 201))"
                        >
                          <Step transition="scale">
                            {({ accomplished }) => (
                              // <img

                              //   style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              //   width=""
                              //   src="https://cdn-icons-png.flaticon.com/512/4335/4335542.png"
                              // />
                              <div className="activebar">1</div>
                            )}
                          </Step>
                          <Step transition="scale">
                            {({ accomplished }) => (
                              // <img

                              //   style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              //   width=""
                              //   src="https://cdn-icons-png.flaticon.com/512/2554/2554978.png"
                              // />
                              <div className="non-activebar">2</div>
                            )}
                          </Step>
                          <Step transition="scale">
                            {({ accomplished }) => (
                              // <img

                              //   style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              //   width=""
                              //   src="https://cdn.icon-icons.com/icons2/2387/PNG/512/card_document_documents_driving_license_car_data_sheet_icon_144605.png"
                              // />
                              <div className="non-activebar">3</div>
                            )}
                          </Step>
                          <Step transition="scale">
                            {({ accomplished }) => (
                              <div className="non-activebar">4</div>
                            )}
                          </Step>
                        </ProgressBar>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content mt-5" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <>
                        <div className="scrollbar-height">
                          <div className="row ">
                            <div className="col-md-6 mb-3">
                              <label className="form-label ml-2">
                                First Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                value={form?.firstName}
                                required
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                name="firstname"
                                placeholder="First Name"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    firstName: e.target.value,
                                  });
                                }}
                                // onBlur={handleBlur}
                                className="form-control bginput chnages"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label ml-2">
                                Last Name
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <input
                                type="text"
                                value={form.lastName}
                                // required
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                name="lastname"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    lastName: e.target.value,
                                  });
                                }}
                                // onBlur={handleBlur}
                                placeholder="Last Name"
                                className="form-control bginput chnages"
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label className="form-label ml-2">
                                Email<span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                value={form.email}
                                // required
                                name="email"
                                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                onChange={(e) => Emailvalidation(e.target.value)}
                                // onBlur={handleBlur}
                                placeholder="Email Address"
                                className="form-control bginput chnages"
                              />
                              {submitted ? (
                                <>{!form.email ? <div className="invalid-feedback d-block">
                                  Email is Required
                                </div> : !emailvalidation() ? <div className="invalid-feedback d-block">
                                  This email domain not allowed. Please use a different email address.
                                </div> : <></>}</>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-md-6 mb-3">
                              <label className="form-label ml-2">
                                Position
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    position: e.target.value,
                                  });
                                }}
                                required
                              >
                                <option value="">Select Option</option>
                                <option value="owner">Owner</option>
                                <option value="manager">Manager</option>
                                <option value="accounting_manager">
                                  Account Manager
                                </option>
                                <option value="dispatcher">Dispatcher</option>
                              </select>
                            </div>

                            <div className="col-md-6 mb-3 ">
                              <label className="form-label ml-2">
                                Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="inputWrapper">
                                <input
                                  className="form-control mb-0 bginput chnages"
                                  type={!eyes.password ? "password" : "text"}
                                  required
                                  value={form.password}
                                  name="password"
                                  minLength="8"
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      password: e.target.value,
                                    });
                                  }}
                                  placeholder="Password"
                                // onBlur={handleBlur}
                                />
                                <i
                                  className={
                                    eyes.password
                                      ? "fa fa-eye eyeicon"
                                      : "fa fa-eye-slash slashicon"
                                  }
                                  onClick={() =>
                                    setEyes({
                                      ...eyes,
                                      password: !eyes.password,
                                    })
                                  }
                                ></i>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3  ">
                              <label className="form-label ml-2">
                                Confirm Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="inputWrapper">
                                <input
                                  autoComplete="new-password"
                                  className="form-control mb-0 bginput chnages"
                                  type={
                                    !eyes.confirmPassword ? "password" : "text"
                                  }
                                  required
                                  value={form.confirmPassword}
                                  name="password"
                                  minLength="8"
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      confirmPassword: e.target.value,
                                    });
                                  }}
                                  placeholder="Confirm Password"
                                // onBlur={handleBlur}
                                />
                                <i
                                  className={
                                    eyes.confirmPassword
                                      ? "fa fa-eye eyeicon"
                                      : "fa fa-eye-slash slashicon"
                                  }
                                  onClick={() =>
                                    setEyes({
                                      ...eyes,
                                      confirmPassword: !eyes.confirmPassword,
                                    })
                                  }
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end gap-2 mt-3">
                          <button
                            type="submit"
                            className="btn dark-btn width-set"
                          >
                            Next
                          </button>
                        </div>
                      </>
                    </div>
                  </div>

                  {/* end tab */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
