
 let apiUrl = "https://api.onebrokerage.org/";
//let apiUrl = "http://74.208.206.18:4020/";


// let host = document.location.host;
// if (host.includes("localhost")) {
//   apiUrl = "http://74.208.206.18:4020/";
// } else if (host.includes("onebrokerage.org")) {
//   apiUrl = "https://api.onebrokerage.org/";
// } else if (host.includes("jcsoftwaresolution.com")) {
//   apiUrl = "http://74.208.206.18:4020/";
// } else if (
//   !host.includes("jcsoftwaresolution.com") &&
//   !host.includes("onebrokerage.org") &&
//   !host.includes("localhost")
// ) {
//   apiUrl = "http://74.208.206.18:4020/";
// } else {
//   apiUrl = "https://api.onebrokerage.org/";
// }

const environment = {
  api: apiUrl,
  imageapi: apiUrl,
  withoutapi: apiUrl,
  contractId:"66bacd551a4df737777ea71e",
  weeklyId:"662b252bc5657082cc45b5bd",
  adminRoleId: "6540cfa35a7513892be61413",
  userRoleId: "64b15102b14de6c28838f7d2",
  map_api_key: "AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs",
  productTypeId: "64a7d198fa039f179c0320ca",
  googleAPIKey: "AIzaSyDhsAnr0k0oWjWn48iqiW4XUX4dvWzvtMA",
  // These are the Zoom Marketplace Secrets thats we need during Zoom Integration
  ZoomClientId: "vaTvhtM1RbewD14ccaBnGw",
  ZoomClientSecret: "t4VRlCiDKCZk03xds38MrMYu3xmYfk1v",
  rfpsId: "6602b2c6867363c3bb83de23",
  uspsId: "65cb18089eaa51c429c42501",
  prebooksId: "662b252bc5657082cc45b5bd",
};
export default environment;
