import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import addressModel from "../../models/address.model";
import * as geolib from "geolib";
import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import datepipeModal from "../../models/datepipemodel";
import moment from "moment";
import Swal from "sweetalert2";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
const ApplyModal = ({ data, result = () => {} }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [Distance, setDistance] = useState(0);
  const [hrs, setHrs] = useState(0);
  const [Drivers, setDrivers] = useState([]);
  const [Trucks, setTruck] = useState([]);
  const [form, setform] = useState({
    eta_value: "",
    eta_type: "hour",
    agreed_rate: "",
    truck_number: "",
    bid_time: "",
  });
  const [AgreedRate, setAgreedRate] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [CanBid, setCanBid] = useState(false);
  const HandleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (parseInt(AgreedRate) <= 0) {
      return;
    }

    if (
      !form?.eta_type ||
      !form.bid_time ||
      !form?.truck_number ||
      !form?.truck_location
      // !form?.agreed_rate
    ) {
      return false;
    }
    ApplyBid({
      load_id: `${data?.id}`,
      carrier_id: `${user?.id}`,
      eta_type: form?.eta_type,
      eta_value: form?.eta_value,
      truck_number: form?.truck_number,
      truck_location: form?.truck_location,
      // load_info: {
      //   id: data?.id,
      //   min_rate: data?.min_rate,
      //   max_rate: data?.max_rate,
      //   total_distance: data?.total_distance,
      //   total_weight: data?.total_weight,
      //   load_start_date: data?.load_start_date,
      //   load_end_date: data?.load_end_date,
      //   total_volume: data?.total_volume,
      //   total_pallets: data?.total_pallets,
      //   total_pieces: data?.total_pieces,
      //   auction_type: data?.auction_type,
      //   location_id: data?.location_id,
      //   origin_location_street: data?.origin_location_street,
      //   origin_location_city: data?.origin_location_city,
      //   origin_location_state: data?.origin_location_state,
      //   origin_location_country: data?.origin_location_country,
      //   origin_location_postal_code: data?.origin_location_postal_code,
      //   destination_location_street: data?.destination_location_street,
      //   destination_location_city: data?.destination_location_city,
      //   destination_location_state: data?.destination_location_state,
      //   destination_location_country: data?.destination_location_country,
      //   destination_location_postal_code:
      //     data?.destination_location_postal_code,
      //   origin_address: data?.origin_address,
      //   tm_equipment_type: "",
      //   addedBy: data?.addedBy,
      //   load_id: data?.load_id,
      // },
      carrier_info: {
        id: user?.id,
        agreed_rate: parseInt(AgreedRate),
        identification_number: user?.identification_number,
      },
    });
  };

  const ApplyBid = (e = {}) => {
    if (
      !form?.eta_type ||
      !form.bid_time ||
      !form?.truck_number ||
      !form?.truck_location ||
      !form?.truck_id
    ) {
      return false;
    }
    loader(true);

    let payload = {
      ...e,
      truck_id: form?.truck_id,
      overtime: showErr(),
      bid_time: form.bid_time,
    };

    ApiClient.post("bid", payload).then((res) => {
      loader(false);
      if (res.success) {
        setAgreedRate("");
        setform({
          eta_value: "",
          eta_type: "",
          agreed_rate: "",
          truck_number: "",
        });
        // toast.success(res.message);
        document.getElementById("closeApplyModel").click();
        result({ event: "submit", value: res });
      }
    });

    loader(false);
  };

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
      // setform({ ...form, truck_location: e.value })
    }

    setform({
      ...form,
      truck_location: e.value,
      lat: address.lat,
      lng: address?.lng,
      // origin_location_country: address.country || '',
      // origin_location_city: address.city || '',
      // origin_location_state: address.state || '',
      // origin_location_postal_code: address.zipcode || '',
      // origin_lat: `${address.lat}` || '',
      // origin_lng: `${address.lng}` || ''
    });
  };

  const GetDrivers = () => {
    ApiClient.get("drivers", { addedBy: user?.id }).then((res) => {
      if (res.success) {
        let filteredData = res?.data?.data?.filter((itm, index, self) => {
          return (
            index === self.findIndex((item) => item.truck_id === itm.truck_id)
          );
        });
        setDrivers(filteredData);
      }
    });
  };
  const GetTrucks = () => {
    ApiClient.get("trucks", { addedBy: user?.id }).then((res) => {
      if (res.success) {
        setTruck(res?.data?.data);
      }
    });
  };

  function convertMinutesToHoursAndMinutes(minutes) {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;
    remainingMinutes = Math.round(remainingMinutes);

    var hoursText = hours > 0 ? hours + " hrs" : "";
    var minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";

    if (hours > 0 && remainingMinutes > 0) {
      return hoursText + " " + minutesText;
    } else {
      return hoursText + minutesText;
    }
  }

  function getHoursDifference(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    // Find the difference in milliseconds
    var difference = end.getTime() - start.getTime();

    // Convert milliseconds to hours
    var hoursDifference = difference / (1000 * 3600); // 1000 milliseconds * 3600 seconds
    hoursDifference = Number(hoursDifference.toFixed(2));
    return hoursDifference;
  }

  function getHoursAndMinutesDifference(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    // Find the difference in milliseconds
    var difference = end.getTime() - start.getTime();

    // Convert milliseconds to hours and remaining minutes
    var hoursDifference = Math.floor(difference / (1000 * 3600)); // 1000 milliseconds * 3600 seconds
    var remainingMinutes = Math.round(
      (difference % (1000 * 3600)) / (1000 * 60)
    ); // Remaining milliseconds converted to minutes

    return { hours: hoursDifference, minutes: remainingMinutes };
  }

  const showCarrierErr = (type = "condition") => {
    let value;
    if (form.bid_time && data?.expiration_date && form.eta_value) {
      let hr = getHoursDifference(form.bid_time, data?.expiration_date);

      let eta_value = Number(form.eta_value) || 0;
      let bhr = eta_value;
      if (form.eta_type == "day") {
        bhr = eta_value * 24;
      }

      if (form.eta_type == "minute") {
        bhr = eta_value / 60;
      }

      let time = bhr - hr;

      function hoursToMinutesAndHours(hours) {
        var totalMinutes = hours * 60;
        var convertedHours = Math.floor(totalMinutes / 60);
        var remainingMinutes = totalMinutes % 60;
        remainingMinutes = Math.round(remainingMinutes);
        return [convertedHours, remainingMinutes];
      }

      let message = "";
      if (hr < bhr) {
        value = true;
        message = `Carriers truck will be ${
          hoursToMinutesAndHours(time)[0]
            ? `${hoursToMinutesAndHours(time)[0]} hours and`
            : ``
        } ${hoursToMinutesAndHours(time)[1]} minutes late. `;
      }

      if (type == "value") {
        value = {
          time: time,
          message: message,
        };
      }
    }
    return value;
  };

  useEffect(() => {
    if (form?.lat && form?.lng && data?.origin_lat && data?.origin_lng) {
      let d = geolib.getDistance(
        {
          latitude: Number(data?.origin_lat),
          longitude: Number(data?.origin_lng),
        },
        {
          latitude: parseInt(form.lat),
          longitude: parseInt(form.lng),
        }
      );

      setDistance(d);
    }
  }, [form.lat, data]);
  useEffect(() => {
    let Speed = 50;
    let Killometer = Math.round(Distance) / 1000;
    let finalvalue = Killometer / Speed;
    finalvalue = Number(finalvalue.toFixed(2));
    setHrs(finalvalue);

    setform({ ...form, eta_type: "hour", eta_value: String(finalvalue) });
  }, [Distance]);

  useEffect(() => {
    if (user && user?.id) {
      GetDrivers();
      GetTrucks();
    }

    document.getElementById("applyModalClick").onclick = () => {
      setform({
        eta_value: "",
        eta_type: "hour",
        agreed_rate: "",
        truck_number: "",
        truck_location: "",
        bid_time: datepipeModal.getinputdatetime(new Date()),
      });
      setAgreedRate("");
      setSubmitted(false);
      setDistance(0);
      setHrs(0);
    };
  }, [user]);

  const showErr = () => {
    let value = false;
    if (
      form?.lat &&
      hrs &&
      form.eta_type &&
      form.eta_value &&
      data.expiration_date
    ) {
      let hr = hrs;
      // let eta_value = Number(form.eta_value) || 0
      // if (form.eta_type == 'day') {
      //   hr = hr / 24
      // }

      // if (form.eta_type == 'minute') {
      //   hr = hr * 60
      // }

      let eta_value = getHoursDifference(form.bid_time, data?.expiration_date);

      if (hr > eta_value) {
        value = true;
      }
    }
    return value;
  };

  useEffect(() => {
    if (showErr()) {
      Swal.fire({
        title: "Its too far!",
        text: "You will not reach destination at time",
        icon: "error",
      });
    }
  }, [showErr()]);

  return (
    <>
      <button
        style={{ display: "none" }}
        type="button"
        id="applyModalClick"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#applyModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade bid-submission"
        id="applyModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5">Bid Submission</h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={HandleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="small load-modal-detail mb-4 mt-2">
                      {data?.load_id ? (
                        <div className="mb-1 d-flex justify-content-between align-items-center">
                          <p className="mb-0">Load ID:</p>{" "}
                          <span>{data?.load_id}</span>
                        </div>
                      ) : null}
                       {data?.lane_id ? (
                        <div className="mb-1 d-flex justify-content-between align-items-center">
                          <p className="mb-0">Lane ID:</p>{" "}
                          <span>{data?.lane_id}</span>
                        </div>
                      ) : null}
                      <div className="mb-1 d-flex justify-content-between align-items-center">
                        <p className="mb-0">Load Pickup Time:</p>{" "}
                        <span>
                          {datepipeModal.datetime(data?.load_start_date)}
                        </span>
                      </div>
                      <div className="mb-0 d-flex justify-content-between  align-items-center">
                        <p className="mb-0">Load Expiration Time:</p>
                        <span>
                          {" "}
                          {window.location.pathname == "/jobs1"
                            ? datepipeModal.datetime(data?.expiration_date)
                            : datepipeModal.datetime(data?.expiration_date)}
                        </span>
                      </div>
                    </div>
                    <label htmlFor="">
                      What will be your Bid Rate
                      <label className="text-danger">*</label>
                    </label>
                    <input
                      value={AgreedRate}
                      required
                      min={0}
                      type="number"
                      onChange={(e) => {
                        setAgreedRate(e.target.value);
                        setform({ ...form, agreed_rate: e.target.value });
                      }}
                      className="form-control mt-2"
                      placeholder="Enter Bid Rate"
                      name=""
                      id=""
                    />
                    {submitted && !AgreedRate ? (
                      <div className="invalid-feedback d-block">
                        Agreed Rate cannot be 0 or less
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="mb-2">
                      Truck Origin Location{" "}
                      <span className="text-danger">*</span>{" "}
                      {form?.lat && form?.lng && (
                        <span className="small">
                          {convertMinutesToHoursAndMinutes(hrs * 60)}
                        </span>
                      )}
                    </label>
                    <GooglePlaceAutoComplete
                      value={form?.truck_location}
                      result={addressResult}
                      id="address"
                      placeholder="Address"
                    />
                    {submitted && !form?.truck_location ? (
                      <div className="invalid-feedback d-block">
                        Origin Location is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="col-md-12 mb-3">
                    <label className="mb-2">
                      Bid ETA
                      <label className="text-danger">*</label>

                    </label>
                    <input
                      value={form.bid_time}
                      type="datetime-local"
                      className="form-control"
                      name="bid_time"
                      min={datepipeModal.getinputdatetime(new Date())}

                      onChange={(e) => {
                        setform({ ...form, bid_time: e.target.value });

                        if (
                          datepipeModal.datetime(e.target.value) >
                          datepipeModal.datetime(
                            data?.board_id?.expiration_date
                          )
                        ) {
                          setCanBid(false);
                          toast.error("You can't able to apply for this Bid");
                        } else {
                          setCanBid(true);
                        }
                      }}
                    />
                    {submitted && !form?.bid_time ? (
                      <div className="invalid-feedback d-block">
                        Bid ETA is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div> */}
                  <div className="col-md-6 mb-3">
                    <label className={`mb-2 ${showErr() ? "err" : ""} `}>
                      ETA Type
                      <label className="text-danger">*</label>
                    </label>
                    <select
                      className="form-control"
                      value={form.eta_type}
                      onChange={(e) =>
                        setform({ ...form, eta_type: e.target.value })
                      }
                    >
                      <option value="">Select Option</option>
                      <option value="day">Day</option>
                      <option value="hour">Hour</option>
                      <option value="minute">Minute</option>
                    </select>
                    {submitted && !form?.eta_type ? (
                      <div className="invalid-feedback d-block">
                        ETA Type is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className={`mb-2 ${showErr() ? "err" : ""} `}>
                      ETA Value
                      <label className="text-danger">*</label>
                    </label>
                    <input
                      value={form.eta_value}
                      type="text"
                      className="form-control"
                      name="eta_value"
                      maxLength={10}
                      onChange={(e) =>
                        setform({ ...form, eta_value: methodModel.isNumber(e) })
                      }
                    />
                    {submitted && !form?.eta_value ? (
                      <div className="invalid-feedback d-block mt-0">
                        ETA Value is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {showCarrierErr() ? (
                    <>
                      <div className=" mb-3 mt-0">
                        <div className="invalid-feedback d-block">
                          {showCarrierErr("value")?.message}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-md-12 mb-3 ">
                    <label className="mb-2">
                      Truck
                      <label className="text-danger">*</label>
                    </label>
                    <div className="row">
                      <div
                        className={`${
                          !Trucks?.length ||
                          (Drivers?.length == 0 && Trucks?.length != 0)
                            ? "col-md-9"
                            : "col-md-12"
                        }`}
                      >
                        <select
                          value={form?.truck_number}
                          onChange={(e) => {
                            setform({
                              ...form,
                              truck_number: e.target.value,
                              truck_id: Drivers?.find(
                                (itm) => itm?.truck_number == e.target.value
                              )?.truck_id,
                            });
                          }}
                          required
                          className="form-control"
                        >
                          <option>Select Truck</option>

                          {Drivers?.map((itm) => {
                            return (
                              <>
                                {itm?.truck_id && (
                                  <option value={itm?.truck_number}>
                                    {itm?.truck_number}
                                  </option>
                                )}
                              </>
                            );
                          })}
                        </select>
                        {submitted && !form?.truck_number ? (
                          <div className="invalid-feedback d-block">
                            Truck is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {!Trucks?.length && (
                        <div className="col-md-3">
                          <button
                            onClick={() => {
                              document
                                .getElementById("closeApplyModel")
                                .click();
                              history.push("/trucks1/add");
                            }}
                            class="btn dark-btn"
                          >
                            Add truck
                          </button>
                        </div>
                      )}
                      {Drivers?.length == 0 && Trucks?.length != 0 && (
                        <div className="col-md-3">
                          <button
                            onClick={() => {
                              document
                                .getElementById("closeApplyModel")
                                .click();
                              history.push("/drivers1/add");
                            }}
                            class="btn dark-btn"
                          >
                            Add Driver
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-end gap-2">
                      {" "}
                      <button
                        type="button"
                        class="btn btn-primary width-set mr-2"
                        id="closeApplyModel"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        onClick={() => {}}
                        type="submit"
                        class="btn dark-btn width-set"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    {showErr() ? (
                      <>
                        <div className="mt-3 text-danger mt-3">
                          You will not reach destination at time
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div> */}
                </div>
              </form>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyModal;
