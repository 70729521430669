import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { login_success, logout } from "../../actions/user";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/Footer/footer";
import moment from "moment";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import PhoneInput from "react-phone-input-2";
import addressModel from "../../models/address.model";

const Profile = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user);
  const [data, setData] = useState({});
  const history = useHistory();
  const [nextForm, SetNextForm] = useState(false);
  const [edit, SetEdit] = useState(false);
  const [addressSelected, setAddressSellected] = useState(false);
  const [form, setForm] = useState({
    trailers_number: "",
    confirmPassword: "",
    currentPassword: "",
    newPassword: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const formValidation = [
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "newPassword"],
    },
    { key: "currentPassword", minLength: 8 },
    { key: "newPassword", minLength: 8 },
  ];
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const getError = (key) => {
    return methodModel.getError(key, form, formValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;

    loader(true);
    ApiClient.put("change/password", {
      currentPassword: form?.currentPassword,
      newPassword: form?.newPassword,
    }).then((res) => {
      if (res.success) {
        dispatch(logout());
        localStorage.removeItem("token");
        localStorage.removeItem("persist:admin-app");
        // toast.success("Password Reset Successfully ");
        history.push("/login?message=" + res.message);
      }
      loader(false);
    });
  };

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`user/detail`, { id: user.id }).then((res) => {
      if (res.success) {
        setData(res.data);
        setForm(res?.data);
        const data = res.data;
        const newdata = { ...user, ...data };
        dispatch(login_success(newdata));
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user && user.id) {
      gallaryData();
    }
  }, [user.id]);

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = addressModel.getAddress(e.place);
      setAddressSellected(true);
    } else {
      setAddressSellected(false);
    }
    setForm({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      zipcode: address.zipcode || "",
      lat: address.lat || "",
      lng: address.lng || "",
    });
    if (e.place) {
      // setTimezoneLoader(true)
      // const apires = await addressModel.gettimeZone(e.place);
      // setTimezoneLoader(false)
      setForm({
        ...form,
        address: e.value,
        country: address.country || "",
        city: address.city || "",
        state: address.state || "",
        pincode: address.zipcode || "",
        lat: address.lat || "",
        lng: address.lng || "",
      });
    }
  };

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.success) {
          let image = res?.data?.fullpath;
          setForm({ ...form, image: image, baseImg: "" });
        } else {
          setForm({ ...form, baseImg: "" });
        }
        loader(false);
      }
    );
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();

    let value = {
      id: user?.id,
      email: form?.email,
      fullName: form?.fullName,
      address: form?.address,
      city: form?.city,
      state: form?.state,
      pincode: form?.pincode,
      country: form?.country,
      telephoneNo: form?.telephoneNo,
      telephoneExt: form?.telephoneExt,
      fax_number: form?.fax_number,
      tax_number: form?.tax_number,
      trailers_number: form?.trailers_number,
      truck_number: form?.truck_number,
      team_truck: form?.team_truck,
      solo_truck: form?.solo_truck,
      image: form?.image,
      trailer_type: form?.trailer_type,
    };
    ApiClient.put("user/profile", value).then((res) => {
      if (res.success) {
        dispatch(login_success(res?.data));
        // toast.success(res.message);
        SetEdit(false);
        gallaryData();
        SetNextForm(false);
      }
    });

  };

  return (
    <>
      <WebsiteHeader />
      <div className="portal-page profile-tabs">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 col-md-8 mx-auto">
              <div className="box-shadow-prop">
                <div class="row">
                  <div className="col-lg-3 p-0">
                    <div className=" profile-tabs-left">
                      <div
                        class="nav flex-column nav-pills "
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          class="nav-link active"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          <i
                            class="fa fa-user-circle me-2"
                            aria-hidden="true"
                          ></i>
                          Profile
                        </button>
                        <button
                          class="nav-link"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                        >
                          <i class="fa fa-lock me-2" aria-hidden="true"></i>
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 p-0">
                    <div className="pprofile1">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          {!edit ? (
                            <>
                              <div className="d-flex justify-content-between  mb-3">
                                <div className=" mb-4">
                                  <h5>Basic Information</h5>
                                </div>

                                <Link
                                  to="/profile1"
                                  title="Edit Profile"
                                  className="btn btn-primary edit-profiles"
                                >
                                  <i
                                    className="fa fa-edit"
                                    title="Edit Profile"
                                    onClick={() => {
                                      SetEdit(true);
                                    }}
                                  />
                                </Link>
                              </div>
                              <div className="row">
                                <div className="col-md-12 ">
                                  <div className="form-group mb-3">
                                    <label className="label_profile">
                                      Image:
                                    </label>
                                    <div className="width200">
                                      <img
                                        src={
                                          methodModel.userImg(
                                            data && data.image
                                          ) || "--"
                                        }
                                        className="profileImage-main"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Name:
                                    </label>
                                    <div className="width200">
                                      <p className=" mb-0">
                                        {(data &&
                                          methodModel.capitalizeFirstLetter(
                                            data.fullName
                                          )) ||
                                          "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Identification Number:
                                    </label>
                                    <div className="width200">
                                      <p className=" mb-0">
                                        {(data && data?.identification_number) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Email:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data && data.email) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {data.companyName && (
                                  <div className="col-md-12 mb-3">
                                    <label className="label_profile">
                                      Business Name:{" "}
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data && data.companyName) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {data?.telephoneNo && (
                                  <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                      <label className="label_profile">
                                        Telephone:
                                      </label>
                                      <div className="width200">
                                        <p className="mb-0">
                                          {data && data.telephoneExt != ""
                                            ? `(${data.telephoneExt || ""})`
                                            : null}
                                          {(data && data.telephoneNo) || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Address:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data && data.address) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      FAX Number:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data &&
                                          methodModel.capitalizeFirstLetter(
                                            data.fax_number
                                          )) ||
                                          "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      TAX Number:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data &&
                                          methodModel.capitalizeFirstLetter(
                                            data.tax_number
                                          )) ||
                                          "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Total Truck:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data && data?.total_trucks) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Team Trucks:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data && data?.team_truck) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Solo Trucks:
                                    </label>
                                    <div className="width200">
                                      <p className="mb-0">
                                        {(data && data?.solo_truck) || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label className="label_profile">
                                      Trailer Type:
                                    </label>
                                    <div className="width200">
                                      {data?.trailer_type?.map((itm) => {
                                        return <p className="mb-0 me-2">{itm}</p>;
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="edit-section-img">
                              <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5>Basic Information</h5>
                              </div>

                              <form
                                className="form-row"
                                onSubmit={handleSubmit2}
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="profile_img_side">
                                      <img
                                        src={methodModel.userImg(
                                          form && form.image
                                        )}
                                        className="profileImage"
                                      />

                                      <div>
                                        <label className="new_images edit">
                                          <input
                                            id="bannerImage"
                                            type="file"
                                            className="d-none "
                                            accept="image/*"
                                            value={
                                              form.baseImg ? form.baseImg : ""
                                            }
                                            onChange={(e) => {
                                              uploadImage(e);
                                            }}
                                          />
                                          <span className="uploader">
                                            <i className="material-icons">
                                              camera_alt
                                            </i>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="changes_image">
                                        <div>
                                          {form.image ? (
                                            <label
                                              className="deleticon  delete "
                                              onClick={(e) =>
                                                setForm({ ...form, image: "" })
                                              }
                                            ></label>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-4">
                                    {!nextForm && (
                                      <div className="row">
                                        <div className="col-md-6 mb-3">
                                          <label className="label_profile">
                                            Name
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              value={
                                                form.fullName
                                                  ? form.fullName
                                                  : ""
                                              }
                                              onChange={(e) =>
                                                setForm({
                                                  ...form,
                                                  fullName: e.target.value,
                                                })
                                              }
                                              required
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label className="label_profile">
                                            Email
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div>
                                            <input
                                              type="email"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              value={
                                                form.email ? form.email : ""
                                              }
                                              disabled
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label className="label_profile">
                                            Position
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div>
                                            <input
                                              type="email"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              value={
                                                form.email ? form.email : ""
                                              }
                                              disabled
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <label className="label_profile">
                                            Address
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div>
                                            <GooglePlaceAutoComplete
                                              value={form.address}
                                              result={addressResult}
                                              id="address"
                                              placeholder=""
                                            />
                                            {form.address == "" && submitted ? (
                                              <div className="invalid-feedback d-block">
                                                Please Select Location
                                                Suggestion
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label className="label_profile">
                                            Telephone{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="phoneInput_cls d-flex form-control p-0">
                                            <PhoneInput
                                              country={"us"}
                                              value={form.telephoneExt}
                                              countryCodeEditable={false}
                                              enableSearch={true}
                                              placeholder=""
                                              onChange={(phone, country) => {
                                                setForm({
                                                  ...form,
                                                  telephoneExt:
                                                    country.dialCode,
                                                });
                                              }}
                                              required
                                            />

                                            <input
                                              type="text"
                                              className="form-control phph"
                                              placeholder="Mobile No."
                                              value={
                                                (form && form.telephoneNo) || ""
                                              }
                                              maxLength={12}
                                              onChange={(e) =>
                                                setForm({
                                                  ...form,
                                                  telephoneNo:
                                                    methodModel.isNumber(e),
                                                })
                                              }
                                              required
                                            />
                                          </div>
                                        </div>


                                        <div className="col-md-6 mb-3">
                                          <label className="label_profile">
                                            Fax
                                          </label>
                                          <div>
                                            <input
                                              type="text"
                                              pattern="^[a-zA-Z0-9]+$"
                                              onKeyPress={(e) => {
                                                var regex = new RegExp(
                                                  "^[a-zA-Z0-9]+$"
                                                );
                                                var key = String.fromCharCode(
                                                  !e.charCode
                                                    ? e.which
                                                    : e.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  e.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              className="form-control"
                                              placeholder="Enter Fax"
                                              value={form.fax_number}
                                              onChange={(e) =>
                                                setForm({
                                                  ...form,
                                                  fax_number: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <label className="label_profile">
                                            Tax ID
                                          </label>
                                          <div>
                                            <input
                                              type="text"
                                              pattern="^[a-zA-Z0-9]+$"
                                              onKeyPress={(e) => {
                                                var regex = new RegExp(
                                                  "^[a-zA-Z0-9]+$"
                                                );
                                                var key = String.fromCharCode(
                                                  !e.charCode
                                                    ? e.which
                                                    : e.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  e.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              className="form-control"
                                              placeholder="Enter Tax ID"
                                              value={
                                                form.tax_number
                                                  ? form.tax_number
                                                  : ""
                                              }
                                              onChange={(e) =>
                                                setForm({
                                                  ...form,
                                                  tax_number: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-12 d-flex justify-content-end gap-2">
                                          <a
                                            onClick={() => SetEdit(false)}
                                            className=" btn-save width-set"
                                          >
                                            Back
                                          </a>
                                          <a
                                            onClick={() => SetNextForm(true)}
                                            className=" btn dark-btn width-set"
                                          >
                                            Next
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    {nextForm && (
                                      <>
                                        <div className="row ">

                                          <div className="col-md-6 mb-3">
                                            <label className="label_profile">
                                              Trailer Number
                                            </label>
                                            <div>
                                              <input
                                                type="text"
                                                pattern="^[a-zA-Z0-9]+$"
                                                onKeyPress={(e) => {
                                                  var regex = new RegExp(
                                                    "^[a-zA-Z0-9]+$"
                                                  );
                                                  var key = String.fromCharCode(
                                                    !e.charCode
                                                      ? e.which
                                                      : e.charCode
                                                  );
                                                  if (!regex.test(key)) {
                                                    e.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                className="form-control"
                                                placeholder="Enter Number"
                                                value={form?.trailers_number}
                                                onChange={(e) =>
                                                  setForm({
                                                    ...form,
                                                    trailers_number:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6 mb-3">
                                            <label className="label_profile">
                                              Team Trucks
                                            </label>
                                            <div>
                                              <input
                                                min={0}
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                value={form?.team_truck}
                                                onChange={(e) =>
                                                  setForm({
                                                    ...form,
                                                    team_truck: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6 mb-3">
                                            <label className="label_profile">
                                              Solo Truck
                                            </label>
                                            <div>
                                              <input
                                                min={0}
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                value={form?.solo_truck}
                                                onChange={(e) =>
                                                  setForm({
                                                    ...form,
                                                    solo_truck: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-12 mb-3">
                                            <label className="form-label ml-2">
                                              Trailer Type
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="row">
                                              <div className="col-md-6 ">
                                                <div className="d-flex ">
                                                  <input
                                                    id="dry_van"
                                                    type="checkbox"
                                                    name="type"
                                                    value="dry_van"
                                                    checked={form?.trailer_type?.includes(
                                                      "dry_van"
                                                    )}
                                                    onChange={(e) => {
                                                      const isChecked =
                                                        e.target.checked;
                                                      let updatedTypes = [
                                                        ...form?.trailer_type,
                                                      ];
                                                      if (isChecked) {
                                                        updatedTypes.push(
                                                          "dry_van"
                                                        );
                                                      } else {
                                                        <div className="col-md-12 mb-3">
                                                          <label className="form-label ml-2">
                                                            Trailer Type
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                          <div className="row">
                                                            <div className="col-md-6 ">
                                                              <input
                                                                id="dry_van"
                                                                type="checkbox"
                                                                name="type"
                                                                value="dry_van"
                                                                checked={form?.trailer_type?.includes(
                                                                  "dry_van"
                                                                )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const isChecked =
                                                                    e.target
                                                                      .checked;
                                                                  let updatedTypes =
                                                                    [
                                                                      ...form?.trailer_type,
                                                                    ];
                                                                  if (
                                                                    isChecked
                                                                  ) {
                                                                    updatedTypes.push(
                                                                      "dry_van"
                                                                    );
                                                                  } else {
                                                                    updatedTypes =
                                                                      updatedTypes.filter(
                                                                        (
                                                                          type
                                                                        ) =>
                                                                          type !==
                                                                          "dry_van"
                                                                      );
                                                                  }
                                                                  setForm({
                                                                    ...form,
                                                                    trailer_type:
                                                                      updatedTypes,
                                                                  });
                                                                }}
                                                              />
                                                              <label
                                                                for="dry_van"
                                                                className="ms-2"
                                                              >
                                                                Dry-Van
                                                              </label>
                                                            </div>
                                                            <div className="col-md-6">
                                                              <input
                                                                id="dry_van"
                                                                type="checkbox"
                                                                name="type"
                                                                value="reefer"
                                                                checked={form?.trailer_type?.includes(
                                                                  "reefer"
                                                                )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const isChecked =
                                                                    e.target
                                                                      .checked;
                                                                  let updatedTypes =
                                                                    [
                                                                      ...form?.trailer_type,
                                                                    ];
                                                                  if (
                                                                    isChecked
                                                                  ) {
                                                                    updatedTypes.push(
                                                                      "reefer"
                                                                    );
                                                                  } else {
                                                                    updatedTypes =
                                                                      updatedTypes.filter(
                                                                        (
                                                                          type
                                                                        ) =>
                                                                          type !==
                                                                          "reefer"
                                                                      );
                                                                  }
                                                                  setForm({
                                                                    ...form,
                                                                    trailer_type:
                                                                      updatedTypes,
                                                                  });
                                                                }}
                                                              />
                                                              <label
                                                                for="reefer"
                                                                className="ms-2"
                                                              >
                                                                Reefer
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>;
                                                        updatedTypes =
                                                          updatedTypes.filter(
                                                            (type) =>
                                                              type !== "dry_van"
                                                          );
                                                      }
                                                      setForm({
                                                        ...form,
                                                        trailer_type:
                                                          updatedTypes,
                                                      });
                                                    }}
                                                  />
                                                  <label
                                                    for="dry_van"
                                                    className="ms-2"
                                                  >
                                                    Dry Van
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="d-flex">
                                                  <input
                                                    id="reefer"
                                                    type="checkbox"
                                                    name="type"
                                                    value="reefer"
                                                    checked={form?.trailer_type?.includes(
                                                      "reefer"
                                                    )}
                                                    onChange={(e) => {
                                                      const isChecked =
                                                        e.target.checked;
                                                      let updatedTypes = [
                                                        ...form?.trailer_type,
                                                      ];
                                                      if (isChecked) {
                                                        updatedTypes.push(
                                                          "reefer"
                                                        );
                                                      } else {
                                                        updatedTypes =
                                                          updatedTypes.filter(
                                                            (type) =>
                                                              type !== "reefer"
                                                          );
                                                      }
                                                      setForm({
                                                        ...form,
                                                        trailer_type:
                                                          updatedTypes,
                                                      });
                                                    }}
                                                  />
                                                  <label
                                                    for="reefer"
                                                    className="ms-2"
                                                  >
                                                    Reefer
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12 d-flex justify-content-end gap-2">
                                            <button
                                              onClick={() => {
                                                // SetEdit(false)
                                                SetNextForm(false);
                                              }}
                                              className=" btn-save width-set"
                                            >
                                              Discard
                                            </button>

                                            <button
                                              type="submit"
                                              className="btn dark-btn width-set"
                                            >
                                              Update
                                            </button>
                                          </div>
                                        </div>{" "}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                        <div
                          class="tab-pane fade"
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                        >
                          <form className="pprofile" onSubmit={handleSubmit}>
                            <div className="col-md-12 mb-3">
                              <h5 className="lgtext mb-4">Change Password</h5>
                              <label className="mb-2">
                                Current Password<span className="start">*</span>
                              </label>
                              <div className="inputWrapper">
                                <input
                                  type={
                                    eyes.currentPassword ? "text" : "password"
                                  }
                                  className="form-control"
                                  value={form.currentPassword}
                                  maxLength="20"
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      currentPassword: e.target.value,
                                    })
                                  }
                                  required
                                />
                                <i
                                  className={
                                    eyes.currentPassword
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }
                                  onClick={() =>
                                    setEyes({
                                      ...eyes,
                                      currentPassword: !eyes.currentPassword,
                                    })
                                  }
                                ></i>
                              </div>
                              {submitted &&
                              getError("currentPassword").invalid ? (
                                <div className="invalid-feedback d-block">
                                  Min Length must be 8 characters long
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="mb-2">
                                New Password<span className="start">*</span>
                              </label>

                              <div className="inputWrapper">
                                <input
                                  type={eyes.password ? "text" : "password"}
                                  className="form-control"
                                  value={form.newPassword}
                                  maxLength="20"
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      newPassword: e.target.value,
                                    })
                                  }
                                  required
                                />
                                <i
                                  className={
                                    eyes.password
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }
                                  onClick={() =>
                                    setEyes({
                                      ...eyes,
                                      password: !eyes.password,
                                    })
                                  }
                                ></i>
                              </div>
                              {submitted && getError("newPassword").invalid ? (
                                <div className="invalid-feedback d-block">
                                  Min Length must be 8 characters long
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="mb-2">
                                Confirm Password<span className="start">*</span>
                              </label>

                              <div className="inputWrapper">
                                <input
                                  type={
                                    eyes.confirmPassword ? "text" : "password"
                                  }
                                  className="form-control"
                                  value={form.confirmPassword}
                                  maxLength="20"
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      confirmPassword: e.target.value,
                                    })
                                  }
                                  required
                                />
                                <i
                                  className={
                                    eyes.confirmPassword
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }
                                  onClick={() =>
                                    setEyes({
                                      ...eyes,
                                      confirmPassword: !eyes.confirmPassword,
                                    })
                                  }
                                ></i>
                              </div>
                              {submitted &&
                              getError("confirmPassword").invalid ? (
                                <>
                                  {/* {getError('confirmPassword').err.minLength ? <div>Min Length must be 8 characters long</div> : <></>} */}
                                  {getError("confirmPassword").err
                                    .confirmMatch ? (
                                    <div className="invalid-feedback d-block">
                                      Confirm Password is not matched with New
                                      Password
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-md-12 text-right mt-3">
                              <button
                                type="submit"
                                className="btn dark-btn mt-4"
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
