import React, { useEffect, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import Pagination from 'react-js-pagination';
import methodModel from '../../methods/methods';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout2 from '../../components/global/Layout2';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import 'react-range-slider-input/dist/style.css';
import MultiSelectDropdown from '../../components/common/MultiSelectDropdown2';
import datepipeModel from '../../models/datepipemodel';
import { Tooltip } from 'antd';
import moment from 'moment';
import { FiFilter } from 'react-icons/fi';
import ApplyModal from '../JobSearch2/ApplyModal';
import CountdownTimer from '../JobSearch2/countdoenTimer';
import { TbArrowsExchange, TbBrandAppgallery } from 'react-icons/tb';
import { IoArrowBackCircleSharp, IoTrophyOutline } from 'react-icons/io5';
import { IoIosArrowRoundForward } from 'react-icons/io';
import environment from '../../environment';
import pipeModel from '../../models/pipeModel';

export default function JobSearch2() {
  const Navigate = useHistory();
  const [Filtername, setFIltername] = useState('');
  const [OriginDestFilters, setOriginDestFilters] = useState({});
  const location = useLocation();
  const [PreviewsFIlter, setPreviewsFilter] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [loaging, setLoader] = useState(true);
  const [Min_rate, setMin_rate] = useState('50');
  const [Max_rate, setMax_rate] = useState('5000');
  const Searchdata = params.get('search');
  const history = useHistory();
  const [Board, setAllBoards] = useState([]);
  const [boardFilter, setboardFilter] = useState({
    board_id: '',
  });
  const Columns = JSON.parse(localStorage.getItem("LoadColumn")) || [];
  const user = useSelector((state) => state.user);
  // console.log(user,"user")
  const [selectedDistance, setSelectedDistance] = useState(null);
  const [ProjectsData, setProjectsData] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get('search');
  const [Tab, setTab] = useState('Card');
  const lat = searchParams.get('lat');
  const lng = searchParams.get('lng');
  const { id } = useParams();
  const [board, setBoards] = useState([]);
  const [userBoards, setUserBoards] = useState([]);
  const [setboard, setNewBoard] = useState('Loads');
  const [LiveFilter, setLiveFilter] = useState([]);
  const [QaryName, setQuaryName] = useState([]);
  const [TimerClass, setTimerClass] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [showStop, setShowStop] = useState('');
  const [filters, setFilter] = useState({
    page: 1,
    count: 20,
    search: Searchdata || search,
    isDeleted: false,
    sorder: '',
  });
  useEffect(() => {
    // console.log(LiveFilter, "============UPDATE");
  }, [filterName]);

  useEffect(() => {
    if (id == environment?.prebooksId) {
      setNewBoard('Prebook');
    } else if (id == environment?.rfpsId) {
      setNewBoard('Rfps');
    } else if (id == environment?.uspsId) {
      setNewBoard('Usps');
    } else {
      setNewBoard('Loads');
    }
  }, [id]);
  const StartdateTime=(load_start_date) =>{return load_start_date?.split("T").join().split(".")?.[0]}
  const EnddateTime=(load_end_date) =>{return load_end_date?.split("T").join().split(".")?.[0]}
  const ExpirationTime =(expirationDate) =>{return expirationDate?.split("T").join().split(".")?.[0]}
  const getBaords = () => {
    ApiClient.get('boards?status=active').then((res) => {
      if (res.success) {
        setAllBoards(res?.data?.data);

        const filteredBoards = res?.data?.data.filter((item) =>
          user?.board_id?.includes(item.id)
        );
        setUserBoards(filteredBoards);
      }
    });
  };
  const url = window.location.pathname;
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState();
  const latestSliderValue = React.useRef([0, 0]);
  const [Id, setId] = useState('');
  const [QueryIndex, setQueryIndex] = useState(0);

  const calculateTimeRemaining = (expirationDate) => {
    const now = new Date();
    const expiration = new Date(expirationDate);
    const difference = expiration - now;

    if (difference <= 0) {
      return { expired: true };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const columns = [
    // "distance",
    "origin_city",
    "origin_state",
    "origin_zip",
    "destination_city",
    "destination_state",
    "destination_zip",
    "load_start_date",
    "load_end_date",
    "avg_rating",
    "total_reviews",
    "avg_mph",
    "miles",
    "arrive_destinationDayOffset",
    "arrive_destination",
    "travel_time",
    "service_type",
    "annual_miles"
    // "Posted At",
    // "min_rate",
    // "max_rate",
  ];

  const [visibleColumns, setVisibleColumns] = useState(Columns);

  useEffect(() => {
    localStorage.setItem("LoadColumn", JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }
  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }

  const ConvertToSortingKeys = (str) => {
    switch (str) {
      case "origin_city":
        return "origin_location_city";
        break;

      case "origin_state":
        return "origin_location_state";
        break;

      case "origin_zip":
        return "origin_location_postal_code";
        break;

      case "destination_city":
        return "destination_location_city";
        break;

      case "destination_state":
        return "destination_location_state";
        break;

      case "destination_zip":
        return "destination_location_postal_code";
        break;
      case "avg_mph":
        return "avg_mph";
        break;
      case "miles":
        return "miles";
        break;
      case "arrive_destination":
        return "arrive_destination";
        break;
      case "arrive_destinationDayOffset":
        return "arrive_destinationDayOffset";
        break;
      case "travel_time":
        return "travel_time";
        break;
      case "service_type":
        return "service_type";
        break;
      case "annual_miles":
        return "annual_miles";
        break;

      case "Posted At":
        return "createdAt";
        break;

      default:
        return str;
    }
  };
  const ConvertStringToAbbrivate = (str) => {
    let key = str?.includes(" ");
    if (key) {
      let extractletter = str
        ?.split(" ")
        ?.map((word) => methodModel.capitalizeFirstLetter(word[0]));
      return extractletter?.join("");
    } else {
      return str || "--";
    }
  };

  const ColumnReturner = (data, value, itm) => {

    switch (data) {
      case "origin_city":
        return itm?.origin_location_city || "--";
        break;
      case "origin_state":
        return itm?.origin_location_state || "--";
        break;
      case "origin_zip":
        return itm?.origin_location_postal_code || "--";
        break;
      case "destination_city":
        return (
          ConvertStringToAbbrivate(itm?.destination_location_city).replace(
            ",",
            " "
          ) || "--"
        );
        break;
        case "destination_state":
          return itm?.destination_location_state || "--";
          break;
        case "destination_zip":
          return itm?.destination_location_postal_code || "--";
          break;
      case "avg_mph":
        return itm?.avg_mph || "--"
        break;
      case "miles":
        return itm?.miles|| "--"

        break;
      case "arrive_destination":
        return itm?.arrive_destination|| "--"

        break;
      case "arrive_destinationDayOffset":
        return itm?.arrive_destinationDayOffset || "--"

        break;
      case "travel_time":
        return itm?.travel_time || "--"

        break;

      case "service_type":
        return itm?.service_type || "--"
        break;

      case "annual_miles":
        return itm?.annual_miles || "--"

        break;
      case "destination_zip":
        return itm?.destination_location_postal_code || "--";
        break;
      case "load_start_date":
        return  `${moment(StartdateTime(itm?.load_start_date)).format(
          'DD-MMM-YYYY'
        )},
        ${datepipeModel.isotime(
          itm?.load_start_date
        )}`
        break;
      case "load_end_date":
        return  `${moment(StartdateTime(itm?.load_end_date)).format(
          'DD-MMM-YYYY'
        )},
        ${datepipeModel.isotime(
          itm?.load_end_date
        )}`
        break;
      case "Posted At":
        return datepipeModel.date(itm?.createdAt);
        break;

      default:
        return pipeModel.number(value) == NaN
          ? value || ""
          : pipeModel.number(value);
        break;
    }
  };



  const sorting = (key, order) => {
    setFilter({ ...filters, sortBy: key, sorder: order });
    GetAllprojects({ sortBy: key, sorder: order });
  };

  const GetAllprojects = (p = {}) => {
    let newfilters = {
      ...filters,
      ...OriginDestFilters,
      ...p,
    };
    newfilters = {
      ...newfilters,
      destination_location_state:
        newfilters?.destination_location_state?.toString(),
      destination_location_city:
        newfilters?.destination_location_city?.toString(),
      origin_location_city: newfilters?.origin_location_city?.toString(),
      origin_location_state: newfilters?.origin_location_state?.toString(),
    };

    if (Searchdata) {
      newfilters = {
        ...newfilters,
        ...OriginDestFilters,
        search: Searchdata,
      };
      newfilters = {
        ...newfilters,
        destination_location_state:
          newfilters?.destination_location_state?.toString(),
        destination_location_city:
          newfilters?.destination_location_city?.toString(),
        origin_location_city: newfilters?.origin_location_city?.toString(),
        origin_location_state: newfilters?.origin_location_state?.toString(),
      };
    }

    if (user && user?.id) {
      newfilters.user_id = user?.id
    }

    if (id) {
      newfilters = {
        ...newfilters,
        board_id: id,
      };
    }
    let URL = 'filter-loads';
    if (url == '/delivered-jobs') {
      URL = 'frontend/filter-loads';
      newfilters = {
        ...newfilters,
        shipment_status: 'delivered',
      };
    }
    if (url == '/intransit-jobs') {
      URL = 'frontend/filter-loads';
      newfilters = {
        ...newfilters,
        shipment_status: 'in_transit',
      };
    }
    if (url == '/pendingPickup-jobs') {
      URL = 'frontend/filter-loads';
      newfilters = {
        ...newfilters,
        shipment_status: 'awarded',
      };
      // if(url)
    }

    if (newfilters?.min_distance == 2000) {
      delete newfilters.max_distance;
    }
    setLoader(true);

    ApiClient.get(URL, newfilters).then((result) => {
      if (result.success) {
        setTotal(result.data?.total);
        setProjectsData(result.data?.data);
      }
      // if (!Searchdata) {
      //   history.replace("/jobs");
      // }
      setLoader(false);
    });
  };
  const clearFilter = () => {
    const p = {
      destination_location_city: '',
      destination_location_state: '',
      origin_location_city: '',
      origin_location_state: '',
    };
    setOriginDestFilters(p);
    GetAllprojects({ ...p });
  };


  const GetFilter = () => {
    ApiClient.get('get-filters').then((res) => {
      if (res.success) {
        if (res?.data?.length != 0) {
          setPreviewsFilter(true);
        }
        setQuaryName(res?.data?.data);
        setFilterName(res?.data?.data?.[0]?.name);
        setId(res?.data?.data?.[0]?.id);
        setQueryIndex(0);
        const updatedFilters = { ...filters };

        res?.data?.data?.[0]?.key_value?.forEach((itm) => {


          if (itm?.key === 'min_distance') {
            updatedFilters.min_distance = itm?.value;
          }

          if (itm?.key === 'max_distance') {
            updatedFilters.max_distance = itm?.value;
          }

          if (itm?.key === 'min_rate') {
            updatedFilters.min_rate = itm?.value;
          }

          if (itm?.key === 'max_rate') {
            updatedFilters.max_rate = itm?.value;
          }
          const maxWeight = parseInt(updatedFilters.max_weight);
          const minWeight = parseInt(updatedFilters.min_weight);

          const maxDistance = parseInt(updatedFilters.max_distance);
          const minDistance = parseInt(updatedFilters.min_distance);

          const formattedWeightRangeString = `${minWeight}-${maxWeight}`;
          const formattedDistanceRangeString = `${minDistance}-${maxDistance}`;
          setSelectedWeight(formattedWeightRangeString);
          setSelectedDistance(formattedDistanceRangeString);
          // if (updatedFilters?.min_rate || updatedFilters?.max_rate) {
          if (updatedFilters?.min_rate) {
            setMin_rate(updatedFilters?.min_rate);
          } else {
            setMin_rate('50');
          }
          if (updatedFilters?.max_rate) {
            setMax_rate(updatedFilters?.max_rate);
          } else {
            setMax_rate('4000');
          }
          // }
          setFilter({ ...filters, updatedFilters });
          setTimeout(() => {
            setFilter({ ...filters, updatedFilters });
          }, 2000);
        });
      }
    });
  };

  const SetFilter = (index) => {

    const firstItem = QaryName?.[index];

    setFilterName(firstItem?.name);
    setId(firstItem?.id);
    setQueryIndex(index);

    const updatedFilters = { ...filters };

    firstItem?.key_value.forEach((itm) => {
      switch (itm.key) {

        case 'min_distance':
          updatedFilters.min_distance = itm.value;
          break;
        case 'max_distance':
          updatedFilters.max_distance = itm.value;
          break;
        case 'min_rate':
          updatedFilters.min_rate = itm.value;
          break;
        case 'max_rate':
          updatedFilters.max_rate = itm.value;
          break;
        default:
          break;
      }
    });

    const maxWeight = parseInt(updatedFilters.max_weight);
    const minWeight = parseInt(updatedFilters.min_weight);
    const maxDistance = parseInt(updatedFilters.max_distance);
    const minDistance = parseInt(updatedFilters.min_distance);

    const formattedWeightRangeString = `${minWeight}-${maxWeight}`;
    const formattedDistanceRangeString = `${minDistance}-${maxDistance}`;
    setSelectedWeight(formattedWeightRangeString);
    setSelectedDistance(formattedDistanceRangeString);
    setMin_rate(updatedFilters.min_rate);
    setMax_rate(updatedFilters.max_rate);

    // Update the filter state outside the loop
    setFilter({ ...filters, ...updatedFilters });
    GetAllprojects({
      ...filters,
      // max_weight: maxWeight,
      // min_weight: minWeight,
      min_distance: minDistance,
      max_distance: maxDistance,
    });
    document.getElementById('closeButtonId').click();
  };
  // }
  //   });
  // };

  const resetFilter = () => {
    setFilter({
      ...filters,
      max_distance: '',
      min_distance: '',
      search: '',
      origin_lat: '',
      origin_lng: '',
    });
    GetAllprojects({
      ...filters,
      max_distance: '',
      min_distance: '',
      search: '',
      origin_lat: '',
      origin_lng: '',
    });
    setQueryIndex(null);
    setSelectedDistance(null);
    if (search || lat || lng) {
      history.push('/jobs');
    }
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    GetAllprojects({ page: e });
  };



  const GetProfile = () => {
    ApiClient.get('user/detail', { id: user?.id }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.board_id);
        let IDArray = [];
        if (res?.data?.boardDetails) {
          let fltr = res?.data?.boardDetails?.filter(
            (itm) => itm?.board_status == 'active'
          );

          for (let i = 0; i < fltr.length; i++) {
            IDArray.push(fltr[i].id);
          }

          const JoinedIDS = IDArray?.join(',');
          if (fltr?.length > 0) {
            setFilter({ ...filters, board_id: JoinedIDS });
            GetAllprojects({
              ...filters,
              board_id: JoinedIDS,
              search: Searchdata,
            });
          } else {
            setFilter({ ...filters, isLogin: true });
            GetAllprojects({ ...filters, isLogin: true });
          }
        }
      }
    });
  };

  const ChangeStatus = (p) => {
    GetAllprojects(p);
  };

  useEffect(() => {
    getBaords();
    if (user && user?.id) {
      GetFilter();
      GetProfile();
    }

    setFilter({
      ...filters,
      search: Searchdata || search,
      origin_lat: lat,
      origin_lng: lng,
    });
    GetAllprojects({
      search: Searchdata || search,
      origin_lat: lat,
      origin_lng: lng,
    });
  }, [user, id]);

  useEffect(() => {
    calculateTimeRemaining(1712896200);
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const SaveFilter = () => {
    // if (LiveFilter?.length == 0 || !filterName) {
    //   return false;
    // }
    if (!filterName || LiveFilter?.length == 0) {
      return false;
    }

    if (QaryName?.some((itm) => itm?.name == filterName)) {
      if (Id) {
        ApiClient.put('edit-Filter', {
          id: Id,
          name: filterName,
          key_value: LiveFilter,
        }).then((res) => {
          if (res.success) {
            // setFilterName("");
            // resetFilter();
            // toast.success(res.message);
            document.getElementById('closeButtonId').click();
            GetFilter();
          }
        });
      }
    } else {
      ApiClient.post('saved-filter', {
        name: filterName,
        key_value: LiveFilter,
      }).then((res) => {
        if (res.success) {
          setFilterName('');
          resetFilter();
          // toast.success(res.message);
          document.getElementById('closeButtonId').click();
          GetFilter();
        }
      });
    }
  };

  const HandleFilter = (data = {}) => {
    GetAllprojects({ ...filters, ...data });
  };

  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, search: value });
    GetAllprojects({ search: value });
  };

  const [DesitinationStates, setrDestinationStates] = useState([]);
  const [OriginStates, setOriginStates] = useState([]);
  const [DestinationCities, setDestinationCities] = useState([]);
  const [OriginCities, setOriginCities] = useState([]);
  const StateGetter = (type = '') => {
    if (type == 'origin') {
      ApiClient.get('load/origin/state?page=1&count=100').then((res) => {
        if (res.success) {
          setOriginStates(
            res?.data?.data?.map((item) => ({
              id: item?.state,
              name: item?.state,
            }))
          );
        }
      });
    } else {
      ApiClient.get('load/destination/state?page=1&count=100').then((res) => {
        if (res.success) {
          setrDestinationStates(
            res?.data?.data?.map((item) => ({
              id: item?.state,
              name: item?.state,
            }))
          );
        }
      });
    }
  };

  const CitiesGetter = (type = '', filters = {}) => {
    if (type == 'origin') {
      let newfilters = {
        ...filters,
        ...OriginDestFilters,
        page: 1,
        count: 300,
      };
      let NewFilters = {
        ...newfilters,
        state: newfilters?.state?.toString(),
        origin_location_city: newfilters?.origin_location_city?.toString(),
        origin_location_state: newfilters?.origin_location_state?.toString(),
        destination_location_city:
          newfilters?.destination_location_city?.toString(),
        destination_location_state:
          newfilters?.destination_location_state?.toString(),
      };

      ApiClient.get('load/origin/city-state', NewFilters).then((res) => {
        if (res.success) {
          setOriginCities(
            res?.data?.data?.map((item) => ({
              id: item?.city,
              name: item?.city,
            }))
          );
        }
      });
    } else {
      let newfilters = {
        ...filters,
        ...OriginDestFilters,
        page: 1,
        count: 300,
      };
      let NewFilters = {
        ...newfilters,
        state: newfilters?.state?.toString(),
        origin_location_city: newfilters?.origin_location_city?.toString(),
        origin_location_state: newfilters?.origin_location_state?.toString(),
        destination_location_city:
          newfilters?.destination_location_city?.toString(),
        destination_location_state:
          newfilters?.destination_location_state?.toString(),
      };
      ApiClient.get('load/destination/city-state', NewFilters).then((res) => {
        if (res.success) {
          setDestinationCities(
            res?.data?.data?.map((item) => ({
              id: item?.city,
              name: item?.city,
            }))
          );
        }
      });
    }
  };

  useEffect(() => {
    StateGetter();
    StateGetter('origin');
    CitiesGetter();
    CitiesGetter('origin');
  }, []);

  return (
    <>
      {/* <WebsiteHeader /> */}
      <Layout2 title="Loads" title2={setboard}>
        <div
          className={`${user?.loggedIn ? 'mainareas' : 'mainareas load-main'} `}
        >
          <div className={`${user?.loggedIn ? '' : ''} `}>
            <div className="row ">
              <div className="col-md-12">
                <div className="jobs-listing ">
                  <div className="d-flex justify-content-between align-items-center  wrapflex flex-col-c">
                    <div className="bids-top">
                      <h5 className="mb-0">
                        Results <span> {total}</span>
                      </h5>
                    </div>
                    <div className=" d-flex align-items-center flex-wrap ">
                      <div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex jobs_child-flex position-relative loadCross ">
                              <div className=" searchParent">
                                <input
                                  className="form-control "
                                  placeholder="Search By Load Id ,City ,State"
                                  type="search"
                                  value={filters.search}
                                  name="search"
                                  onChange={(e) => Handlefilter(e)}
                                />
                                <span class="material-icons">search</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <div className="">
                          <div className=" tabs-view tabs-width">
                            <ul class="nav nav-tabs">
                              <li class="nav-item">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Grid"
                                  class={`nav-link ${Tab == 'Card' ? 'active' : null
                                    } `}
                                  onClick={() => {
                                    setTimerClass(false);
                                    setTab('Card');
                                  }}
                                >
                                  <i className="fa fa-th"></i>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="List"
                                  class={`nav-link ${Tab == 'Table' ? 'active' : null
                                    } `}
                                  onClick={() => {
                                    setTimerClass(true);
                                    setTab('Table');
                                  }}
                                >
                                  <i className="fa fa-list "></i>
                                </a>
                              </li>
                              <Tooltip id="my-tooltip" />
                            </ul>
                          </div>
                        </div>
                        {Tab == "Card" ?
                          <></> : <div className="new-drop dropdown addDropdown   chnagesg  d-flex align-items-center equal-width-50 w-100 ">
                            <button
                              className="btn blck-border-btn dropdown-toggle mb-0 "
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Add Columns
                            </button>
                            <div
                              className="dropdown-menu w-100"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              {findUniqueElements(visibleColumns, columns).map(
                                (itm) => {
                                  return (
                                    <a
                                      className={"dropdown-item"}
                                      onClick={() => handleColumnToggle(itm)}
                                    >
                                      {replaceUnderscoresWithSpace(itm)}
                                    </a>
                                  );
                                }
                              )}
                            </div>
                          </div>}
                        <div className="col-lg-4 col-md-5 ">
                          <div className="row">
                            {!user?.id ? null : (
                              <div className="col-md-6 col-6">
                                <div
                                  className="modal fade jobs-modal right"
                                  id="exampleModal"
                                  tabindex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">Filters</h5>
                                        <button
                                          type="button"
                                          id="closeButtonId"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>

                                      <div class="modal-body">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="w-100 wraperpadding mb-3">
                                              <label className="mb-1">
                                                Select Destination State
                                              </label>
                                              <div className="multiselect-custom">
                                                <MultiSelectDropdown
                                                  id="statusDropdown"
                                                  className="role-color"
                                                  displayValue="name"
                                                  placeholder="Select Load Type"
                                                  intialValue={
                                                    OriginDestFilters?.destination_location_state
                                                  }
                                                  result={(e) => {
                                                    HandleFilter({
                                                      destination_location_state:
                                                        e.value,
                                                      destination_location_city:
                                                        '',
                                                    });
                                                    CitiesGetter('', {
                                                      state: e?.value,
                                                    });
                                                    setOriginDestFilters({
                                                      destination_location_state:
                                                        e.value,
                                                      destination_location_city:
                                                        '',
                                                    });
                                                  }}
                                                  options={DesitinationStates}
                                                  required={true}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-100 wraperpadding mb-3">
                                              <label className="mb-1">
                                                Select Destination City
                                              </label>
                                              <div className="multiselect-custom">
                                                <MultiSelectDropdown
                                                  id="statusDropdown"
                                                  className="role-color"
                                                  displayValue="name"
                                                  placeholder="Select Load Type"
                                                  intialValue={
                                                    OriginDestFilters?.destination_location_city
                                                  }
                                                  result={(e) => {
                                                    HandleFilter({
                                                      destination_location_city:
                                                        e.value,
                                                    });
                                                    setOriginDestFilters({
                                                      destination_location_city:
                                                        e.value,
                                                      destination_location_state:
                                                        OriginDestFilters?.destination_location_state,
                                                    });
                                                  }}
                                                  key={'Hello'}
                                                  options={DestinationCities}
                                                  required={true}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-100 wraperpadding mb-3">
                                              <label className="mb-1">
                                                Select Origin State
                                              </label>
                                              <div className="multiselect-custom">
                                                <MultiSelectDropdown
                                                  id="statusDropdown"
                                                  className="role-color"
                                                  displayValue="name"
                                                  placeholder="Select Load Type"
                                                  intialValue={
                                                    OriginDestFilters?.origin_location_state
                                                  }
                                                  result={(e) => {
                                                    HandleFilter({
                                                      origin_location_state:
                                                        e.value,
                                                      origin_location_city: '',
                                                    });
                                                    CitiesGetter('origin', {
                                                      state: e?.value,
                                                    });
                                                    setOriginDestFilters({
                                                      origin_location_state:
                                                        e.value,
                                                      origin_location_city: '',
                                                      destination_location_city:
                                                        OriginDestFilters?.destination_location_city,
                                                      destination_location_state:
                                                        OriginDestFilters?.destination_location_state,
                                                    });
                                                  }}
                                                  options={OriginStates}
                                                  required={true}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-100 wraperpadding mb-3">
                                              <label className="mb-1">
                                                Select Origin City
                                              </label>
                                              <div className="multiselect-custom">
                                                <MultiSelectDropdown
                                                  id="statusDropdown"
                                                  className="role-color"
                                                  displayValue="name"
                                                  placeholder="Select Load Type"
                                                  intialValue={
                                                    OriginDestFilters?.origin_location_city
                                                  }
                                                  result={(e) => {
                                                    HandleFilter({
                                                      origin_location_city:
                                                        e.value,
                                                    });
                                                    setOriginDestFilters({
                                                      origin_location_city:
                                                        e.value,
                                                      origin_location_state:
                                                        OriginDestFilters?.origin_location_state,
                                                      destination_location_city:
                                                        OriginDestFilters?.destination_location_city,
                                                      destination_location_state:
                                                        OriginDestFilters?.destination_location_state,
                                                    });
                                                  }}
                                                  key={'OriginCIty'}
                                                  options={OriginCities}
                                                  required={true}
                                                />
                                              </div>
                                            </div>
                                            <div className="mb-3">
                                              <label className="mb-1">
                                                Save Filters
                                              </label>
                                              <div className="d-flex gap-1">
                                                {' '}
                                                <textarea
                                                  value={filterName}
                                                  placeholder="Enter Filter Name"
                                                  onChange={(e) => {
                                                    setFilterName(
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="form-control"
                                                ></textarea>
                                                <div>
                                                  {filterName != '' ? (
                                                    <i
                                                      onClick={() => {
                                                        setFilterName('');
                                                        resetFilter();
                                                        document
                                                          .getElementById(
                                                            'closeButtonId'
                                                          )
                                                          .click();
                                                      }}
                                                      class="fa fa-trash clico"
                                                      aria-hidden="true"
                                                    ></i>
                                                  ) : null}
                                                </div>{' '}
                                              </div>
                                            </div>

                                            {QaryName?.map((itm, index) => {
                                              return (
                                                <>
                                                  <div>
                                                    <div class="badges-main">
                                                      <ul className="badgeflex">
                                                        {itm?.name == '\n' ||
                                                          itm?.name ==
                                                          '' ? null : (
                                                          <li
                                                            onClick={() =>
                                                              SetFilter(index)
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                index ==
                                                                  QueryIndex
                                                                  ? 'badge badge-success'
                                                                  : ''
                                                              }
                                                            >
                                                              {itm?.name}
                                                            </div>
                                                          </li>
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                          </div>
                                          <div className="col-md-12">
                                            <div className=" w-100 drop-c">
                                              <label className="mb-1">
                                                Select Board
                                              </label>
                                              <div className="dropdown addDropdown w-100 ms-0 p-0 pb-3">
                                                <button
                                                  className="btn btn-primary dropdown-toggle filterbt w-100"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-bs-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {Filtername
                                                    ? Filtername == ''
                                                      ? 'All'
                                                      : methodModel.capitalizeFirstLetter(
                                                        Filtername
                                                      )
                                                    : 'All'}
                                                </button>
                                                <div
                                                  className="dropdown-menu shadow bg_hover w-100"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  <a
                                                    className={
                                                      boardFilter?.board_id ==
                                                        ''
                                                        ? 'dropdown-item active'
                                                        : 'dropdown-item'
                                                    }
                                                    onClick={() => {
                                                      setboardFilter({
                                                        ...boardFilter,
                                                        board_id: '',
                                                      });
                                                      setFIltername('All');
                                                      ChangeStatus({});
                                                    }}
                                                  >
                                                    All
                                                  </a>
                                                  {!user?.id ? (
                                                    <>
                                                      {Board &&
                                                        Board?.map((itm) => {
                                                          return (
                                                            <a
                                                              className={
                                                                boardFilter.board_id ==
                                                                  itm?.id
                                                                  ? 'dropdown-item active'
                                                                  : 'dropdown-item'
                                                              }
                                                              onClick={() => {
                                                                setFIltername(
                                                                  itm?.name
                                                                );
                                                                setboardFilter({
                                                                  ...boardFilter,
                                                                  board_id:
                                                                    itm?.id,
                                                                });
                                                                ChangeStatus({
                                                                  board_id:
                                                                    itm?.id,
                                                                });
                                                              }}
                                                            >
                                                              {methodModel.capitalizeFirstLetter(
                                                                itm?.name
                                                              )}
                                                            </a>
                                                          );
                                                        })}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {userBoards &&
                                                        userBoards?.map(
                                                          (itm) => {
                                                            return (
                                                              <a
                                                                className={
                                                                  boardFilter.board_id ==
                                                                    itm?.id
                                                                    ? 'dropdown-item active'
                                                                    : 'dropdown-item'
                                                                }
                                                                onClick={() => {
                                                                  setFIltername(
                                                                    itm?.name
                                                                  );
                                                                  setboardFilter(
                                                                    {
                                                                      ...boardFilter,
                                                                      board_id:
                                                                        itm?.id,
                                                                    }
                                                                  );
                                                                  ChangeStatus({
                                                                    board_id:
                                                                      itm?.id,
                                                                  });
                                                                }}
                                                              >
                                                                {methodModel.capitalizeFirstLetter(
                                                                  itm?.name
                                                                )}
                                                              </a>
                                                            );
                                                          }
                                                        )}
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="categories-sidebar ">
                                              <div>
                                                <h4>Distance</h4>
                                                <ul className="mb-0">
                                                  <li>
                                                    <div className="form-check">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="200"
                                                        id="under200"
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setSelectedDistance(
                                                              '0-200'
                                                            );
                                                            var found = false;

                                                            for (
                                                              var i = 0;
                                                              i <
                                                              LiveFilter.length;
                                                              i++
                                                            ) {
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'max_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value = '200';
                                                                found = true;
                                                              }
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'min_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value = '0';
                                                                found = true;
                                                              }
                                                            }

                                                            if (!found) {
                                                              LiveFilter?.push(
                                                                {
                                                                  key: 'max_distance',
                                                                  value: '200',
                                                                },
                                                                {
                                                                  key: 'min_distance',
                                                                  value: '0',
                                                                }
                                                              );
                                                            }

                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '0',
                                                              max_distance:
                                                                '200',
                                                            });
                                                          } else {
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '',
                                                              max_distance: '',
                                                            });
                                                            LiveFilter.pop();

                                                            setSelectedDistance(
                                                              ''
                                                            );
                                                          }
                                                        }}
                                                        checked={
                                                          selectedDistance ===
                                                          '0-200' ||
                                                          selectedDistance ==
                                                          'NaN-200'
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="under200"
                                                      >
                                                        Under 200Mi
                                                      </label>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="form-check">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="500"
                                                        id="above500"
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setSelectedDistance(
                                                              '0-500'
                                                            );
                                                            var found = false;

                                                            for (
                                                              var i = 0;
                                                              i <
                                                              LiveFilter.length;
                                                              i++
                                                            ) {
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'max_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value = '500';
                                                                found = true;
                                                              }
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'min_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value = '0';
                                                                found = true;
                                                              }
                                                            }

                                                            if (!found) {
                                                              LiveFilter?.push(
                                                                {
                                                                  key: 'max_distance',
                                                                  value: '500',
                                                                },
                                                                {
                                                                  key: 'min_distance',
                                                                  value: '0',
                                                                }
                                                              );
                                                            }
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '0',
                                                              max_distance:
                                                                '500',
                                                            });
                                                          } else {
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '',
                                                              max_distance: '',
                                                            });
                                                            LiveFilter.pop();
                                                            setSelectedDistance(
                                                              ''
                                                            );
                                                          }
                                                        }}
                                                        checked={
                                                          selectedDistance ===
                                                          '0-500' ||
                                                          selectedDistance ==
                                                          'NaN-500'
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="above500"
                                                      >
                                                        Under 500Mi
                                                      </label>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="form-check">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="1500"
                                                        id="under1500"
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setSelectedDistance(
                                                              '0-1500'
                                                            );
                                                            var found = false;

                                                            for (
                                                              var i = 0;
                                                              i <
                                                              LiveFilter.length;
                                                              i++
                                                            ) {
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'max_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value =
                                                                  '1500';
                                                                found = true;
                                                              }
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'min_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value = '0';
                                                                found = true;
                                                              }
                                                            }

                                                            if (!found) {
                                                              LiveFilter?.push(
                                                                {
                                                                  key: 'max_distance',
                                                                  value: '1500',
                                                                },
                                                                {
                                                                  key: 'min_distance',
                                                                  value: '0',
                                                                }
                                                              );
                                                            }
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '0',
                                                              max_distance:
                                                                '1500',
                                                            });
                                                          } else {
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '',
                                                              max_distance: '',
                                                            });
                                                            LiveFilter.pop();
                                                            setSelectedDistance(
                                                              ''
                                                            );
                                                          }
                                                        }}
                                                        checked={
                                                          selectedDistance ===
                                                          '0-1500' ||
                                                          selectedDistance ==
                                                          'NaN-1500'
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="under1500"
                                                      >
                                                        Under 1500Mi
                                                      </label>
                                                    </div>
                                                  </li>

                                                  <li>
                                                    <div className="form-check">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="2000"
                                                        id="above2000"
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            setSelectedDistance(
                                                              '2000+'
                                                            );
                                                            var found = false;

                                                            for (
                                                              var i = 0;
                                                              i <
                                                              LiveFilter.length;
                                                              i++
                                                            ) {
                                                              if (
                                                                LiveFilter[i]
                                                                  .key ===
                                                                'min_distance'
                                                              ) {
                                                                LiveFilter[
                                                                  i
                                                                ].value =
                                                                  '2000';
                                                                found = true;
                                                              }
                                                            }

                                                            if (!found) {
                                                              LiveFilter?.push({
                                                                key: 'min_distance',
                                                                value: '2000',
                                                              });
                                                            }
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance:
                                                                '2000',
                                                            });
                                                          } else {
                                                            GetAllprojects({
                                                              ...filters,
                                                              min_distance: '',
                                                              max_distance: '',
                                                            });
                                                            LiveFilter.pop();
                                                            setSelectedDistance(
                                                              ''
                                                            );
                                                          }
                                                        }}
                                                        checked={
                                                          selectedDistance ===
                                                          '0-2000' ||
                                                          selectedDistance ===
                                                          '2000+' ||
                                                          selectedDistance ==
                                                          'NaN-2000' ||
                                                          selectedDistance ===
                                                          '2000-200'
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="above1500"
                                                      >
                                                        Above 2000Mi
                                                      </label>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>


                                            <div className='row mt-4'>
                                              <div className='col-lg-6'>
                                                <button
                                                  onClick={() => {
                                                    SaveFilter();
                                                  }}
                                                  className="btn dark-btn w-100 "
                                                >
                                                  Save Changes
                                                </button>
                                              </div>
                                              <div className='col-lg-6'>
                                                <button
                                                  className="btn btn-secondary w-100 mb-4"
                                                  onClick={() => {
                                                    clearFilter();
                                                  }}
                                                >
                                                  Clear
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {!user?.id ? null : (
                        <div className="">
                          <button
                            onClick={() => {
                              setMax_rate();
                              setFilter({ ...filters });
                            }}
                            type="button"
                            class="btn btn-primary w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <FiFilter className="me-2" />
                            Filters
                          </button>

                          <div
                            className="modal fade jobs-modal right"
                            id="exampleModal"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">Filters</h5>
                                  <button
                                    type="button"
                                    id="closeButtonId"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div>
                                        <label className="mb-1">
                                          Save Filters
                                        </label>
                                        <div className="d-flex gap-1">
                                          {' '}
                                          <textarea
                                            value={filterName}
                                            placeholder="Enter Filter Name"
                                            onChange={(e) => {
                                              setFilterName(e.target.value);
                                            }}
                                            className="form-control"
                                          ></textarea>
                                          <div>
                                            {filterName != '' ? (
                                              <i
                                                onClick={() => {
                                                  setFilterName('');
                                                  resetFilter();
                                                  document
                                                    .getElementById(
                                                      'closeButtonId'
                                                    )
                                                    .click();
                                                }}
                                                class="fa fa-trash clico"
                                                aria-hidden="true"
                                              ></i>
                                            ) : null}
                                          </div>{' '}
                                        </div>
                                      </div>

                                      <div>
                                        <div class="badges-main">
                                          <ul className="badgeflex">
                                            {QaryName?.map((itm, index) => {
                                              return (
                                                <>
                                                  {itm?.name == '\n' ||
                                                    itm?.name == '' ? null : (
                                                    <li
                                                      onClick={() =>
                                                        SetFilter(index)
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          index == QueryIndex
                                                            ? 'badge badge-success'
                                                            : ''
                                                        }
                                                      >
                                                        {itm?.name}
                                                      </div>
                                                    </li>
                                                  )}
                                                </>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className=" w-100 drop-c">
                                        <label className="mb-1">
                                          Select Board
                                        </label>
                                        <div className="dropdown addDropdown w-100 ms-0 p-0 pb-3">
                                          <button
                                            className="btn btn-primary dropdown-toggle filterbt"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            {Filtername
                                              ? Filtername == ''
                                                ? 'All'
                                                : methodModel.capitalizeFirstLetter(
                                                  Filtername
                                                )
                                              : 'All'}
                                          </button>
                                          <div
                                            className="dropdown-menu shadow bg_hover"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <a
                                              className={
                                                boardFilter?.board_id == ''
                                                  ? 'dropdown-item active'
                                                  : 'dropdown-item'
                                              }
                                              onClick={() => {
                                                setboardFilter({
                                                  ...boardFilter,
                                                  board_id: '',
                                                });
                                                setFIltername('All');
                                                ChangeStatus({});
                                              }}
                                            >
                                              All
                                            </a>
                                            {!user?.id ? (
                                              <>
                                                {Board &&
                                                  Board?.map((itm) => {
                                                    return (
                                                      <a
                                                        className={
                                                          boardFilter.board_id ==
                                                            itm?.id
                                                            ? 'dropdown-item active'
                                                            : 'dropdown-item'
                                                        }
                                                        onClick={() => {
                                                          setFIltername(
                                                            itm?.name
                                                          );
                                                          setboardFilter({
                                                            ...boardFilter,
                                                            board_id: itm?.id,
                                                          });
                                                          ChangeStatus({
                                                            board_id: itm?.id,
                                                          });
                                                        }}
                                                      >
                                                        {methodModel.capitalizeFirstLetter(
                                                          itm?.name
                                                        )}
                                                      </a>
                                                    );
                                                  })}
                                              </>
                                            ) : (
                                              <>
                                                {userBoards &&
                                                  userBoards?.map((itm) => {
                                                    return (
                                                      <a
                                                        className={
                                                          boardFilter.board_id ==
                                                            itm?.id
                                                            ? 'dropdown-item active'
                                                            : 'dropdown-item'
                                                        }
                                                        onClick={() => {
                                                          setFIltername(
                                                            itm?.name
                                                          );
                                                          setboardFilter({
                                                            ...boardFilter,
                                                            board_id: itm?.id,
                                                          });
                                                          ChangeStatus({
                                                            board_id: itm?.id,
                                                          });
                                                        }}
                                                      >
                                                        {methodModel.capitalizeFirstLetter(
                                                          itm?.name
                                                        )}
                                                      </a>
                                                    );
                                                  })}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="categories-sidebar ">
                                        <div>
                                          <h4>Distance</h4>
                                          <ul className="mb-0">
                                            <li>
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value="200"
                                                  id="under200"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedDistance(
                                                        '0-200'
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'max_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '200';
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'min_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '0';
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: 'max_distance',
                                                            value: '200',
                                                          },
                                                          {
                                                            key: 'min_distance',
                                                            value: '0',
                                                          }
                                                        );
                                                      }

                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '0',
                                                        max_distance: '200',
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '',
                                                        max_distance: '',
                                                      });
                                                      LiveFilter.pop();

                                                      setSelectedDistance('');
                                                    }
                                                  }}
                                                  checked={
                                                    selectedDistance ===
                                                    '0-200' ||
                                                    selectedDistance ==
                                                    'NaN-200'
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="under200"
                                                >
                                                  Under 200Mi
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value="500"
                                                  id="above500"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedDistance(
                                                        '0-500'
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'max_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '500';
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'min_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '0';
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: 'max_distance',
                                                            value: '500',
                                                          },
                                                          {
                                                            key: 'min_distance',
                                                            value: '0',
                                                          }
                                                        );
                                                      }
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '0',
                                                        max_distance: '500',
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '',
                                                        max_distance: '',
                                                      });
                                                      LiveFilter.pop();
                                                      setSelectedDistance('');
                                                    }
                                                  }}
                                                  checked={
                                                    selectedDistance ===
                                                    '0-500' ||
                                                    selectedDistance ==
                                                    'NaN-500'
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="above500"
                                                >
                                                  Under 500Mi
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value="1500"
                                                  id="under1500"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedDistance(
                                                        '0-1500'
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'max_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '1500';
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'min_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '0';
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: 'max_distance',
                                                            value: '1500',
                                                          },
                                                          {
                                                            key: 'min_distance',
                                                            value: '0',
                                                          }
                                                        );
                                                      }
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '0',
                                                        max_distance: '1500',
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '',
                                                        max_distance: '',
                                                      });
                                                      LiveFilter.pop();
                                                      setSelectedDistance('');
                                                    }
                                                  }}
                                                  checked={
                                                    selectedDistance ===
                                                    '0-1500' ||
                                                    selectedDistance ==
                                                    'NaN-1500'
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="under1500"
                                                >
                                                  Under 1500Mi
                                                </label>
                                              </div>
                                            </li>

                                            <li>
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value="2000"
                                                  id="above2000"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedDistance(
                                                        '2000+'
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          'min_distance'
                                                        ) {
                                                          LiveFilter[i].value =
                                                            '2000';
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push({
                                                          key: 'min_distance',
                                                          value: '2000',
                                                        });
                                                      }
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '2000',
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_distance: '',
                                                        max_distance: '',
                                                      });
                                                      LiveFilter.pop();
                                                      setSelectedDistance('');
                                                    }
                                                  }}
                                                  checked={
                                                    selectedDistance ===
                                                    '0-2000' ||
                                                    selectedDistance ===
                                                    '2000+' ||
                                                    selectedDistance ==
                                                    'NaN-2000' ||
                                                    selectedDistance ===
                                                    '2000-200'
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="above1500"
                                                >
                                                  Above 2000Mi
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      {/* <div className="col-12 mt-md-3">
                                        <div class="dropdown jobsdropdown range_distance">
                                          <div className="">
                                            <label className="mb-3">
                                              Price Range
                                            </label>
                                            <RangeSlider
                                              min={0}
                                              step={5}
                                              value={[Min_rate, Max_rate]}
                                              max={10000}
                                              onInput={handleSliderChange}
                                            />
                                            <p className="d-flex justify-content-between align-items-center range_numbers ">
                                              <p className="numbers">
                                                {" "}
                                                ${Min_rate}
                                              </p>
                                              <p className="numbers">
                                                ${Max_rate}


                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div> */}

                                      <br></br>
                                      <div>
                                        <button
                                          onClick={() => {
                                            SaveFilter();
                                          }}
                                          className="btn dark-btn w-100 mb-4"
                                        >
                                          Save Changes
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Featured  Jobs */}
                  <div className="jobs-section ">
                    {Tab == 'Card' ? (
                      <>
                        {ProjectsData?.length != 0 &&
                          ProjectsData.map((itm, i) => {
                            return (
                              <div className="bids-new">
                                <div className="bids-data mb-4">
                                  <div className="bids-upp-m">
                                    <div className="loc-flex">
                                      <img src="/assets/img/origin.svg" />
                                      <h5 className="text-capitalize">
                                        {itm?.origin_location_city || '--'}
                                        <span>
                                          {itm?.origin_location_state}{' '}
                                          {itm.origin_location_country
                                            ? ', '
                                            : ''}
                                          {itm.origin_location_country}
                                        </span>
                                        <span className="mt-1">
                                          Origin Address
                                        </span>
                                      </h5>
                                    </div>
                                    <p>
                                      <TbArrowsExchange />
                                    </p>
                                    <div className="loc-flex">
                                      <img src="/assets/img/destination.svg" />
                                      <h5>
                                        {itm?.destination_location_city || '--'}
                                        <span>
                                          {' '}
                                          {itm.destination_location_state},{' '}
                                          {itm.destination_location_country}
                                        </span>
                                        <span className="mt-1">
                                          Destination Address
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="bids-upper">
                                    <div className="bids-middle">
                                      <h5>
                                        <span>Load Id/Lane Id :</span>
                                        <span
                                          className="pointer width-f"
                                          onClick={() => {
                                            if (
                                              !localStorage.getItem('token')
                                            ) {
                                              history.push(`/login`);
                                            } else {
                                              history.push(
                                                `/jobs/view/${itm?.id}`
                                              );
                                            }
                                          }}
                                        >
                                          {itm.load_id || itm.lane_id || '--'}
                                        </span>
                                      </h5>
                                      <h5 className="">
                                        <span>Time Left(min) :</span>

                                        <CountdownTimer
                                          TimerClass={TimerClass}
                                          expirationDate={itm?.expiration_date}
                                        />
                                      </h5>

                                      {itm?.shipment_status == 'pending' ? (
                                        ''
                                      ) : (
                                        <h5>
                                          <span>Pickup Date :</span>
                                          {moment(itm?.pickedup_at).format(
                                            'DD-MMM-YYYY, h:mm A'
                                          )}
                                        </h5>
                                      )}

                                      <h5>
                                        <span>Expiration Date :</span>{' '}
                                        {/* {datepipeModel.date(
                                          itm?.expiration_date
                                        )}
                                        ,{' '}
                                        {datepipeModel.isotime(
                                          itm?.expiration_date
                                        )} */}
                                        {moment(ExpirationTime(itm?.expiration_date)).format(
                                          "DD-MMM-YYYY"
                                        )}, {''}
                                        {datepipeModel.isotime(
                                          itm?.expiration_date
                                        )}
                                      </h5>
                                      {itm?.load_start_date ? (
                                        <h5>
                                          <span>Load Start Date :</span>
                                          {/* {datepipeModel.date(
                                            itm?.load_start_date_str || itm?.load_start_date
                                          )}
                                          ,{' '}
                                          {datepipeModel.isotime(
                                            itm?.load_start_date
                                          )} */}
                                          {moment(StartdateTime(itm?.load_start_date)).format(
                                          'DD-MMM-YYYY'
                                        )}, {''}
                                        {datepipeModel.isotime(
                                          itm?.load_start_date
                                        )}
                                        </h5>
                                      ) : (
                                        ''
                                      )}

                                      {itm?.load_end_date ? (
                                        <h5>
                                          <span>Load End Date :</span>{' '}
                                          {/* {datepipeModel.date(
                                            itm?.load_end_date_str || itm?.load_end_date
                                          )}
                                          ,{' '}
                                          {datepipeModel.isotime(
                                            itm?.load_end_date
                                          )} */}
                                          {moment(EnddateTime(itm?.load_end_date)).format(
                                          'DD-MMM-YYYY '
                                        )}, {''}
                                          {datepipeModel.isotime(
                                            itm?.load_end_date
                                          )}
                                        </h5>
                                      ) : (
                                        ''
                                      )}

                                      {itm?.shipment_status == 'pending' ? (
                                        ''
                                      ) : (
                                        <h5>
                                          <span>Delivery Status :</span>{' '}
                                          <p
                                            className={`${itm?.shipment_status}_badgeNew mb-0 badge`}
                                          >
                                            {itm?.shipment_status == "in_transit" ? "In Transit" : itm?.shipment_status}
                                          </p>
                                        </h5>
                                      )}
                                    </div>
                                    <div className="bids-bottom">
                                      <h3>
                                        Stops <span>{itm?.stops?.length}</span>
                                      </h3>
                                      <ul>
                                        {itm?.stops?.length != 0
                                          ? itm?.stops?.map((item, index) => {
                                            return (
                                              <Tooltip title={item?.address}>
                                                <>
                                                  {index < 3 ? (
                                                    <li>
                                                      <IoArrowBackCircleSharp />{' '}
                                                      <span className=" mb-1 destspan">
                                                        {' '}
                                                        {item?.address}
                                                      </span>
                                                    </li>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              </Tooltip>
                                            );
                                          })
                                          : ''}
                                      </ul>
                                      {itm?.stops?.length > 3 ? (
                                        <Tooltip
                                          title={
                                            <div className="">
                                              <ul className="">
                                                {itm?.stops?.map((item) => (
                                                  <li>{item?.address}</li>
                                                ))}
                                              </ul>
                                            </div>
                                          }
                                        >
                                          <div className="all-stops-list">
                                            <span className="">All Stops</span>
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                    </div>

                                    <div className="bids-right text-end">
                                      {itm?.total_distance ? (
                                        <div className="bid-p">
                                          {itm?.total_distance} Mi
                                          <span className="d-block miles-color">
                                            (Distance in Miles)
                                          </span>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="d-flex justify-content-end mt-3 ">
                                        <div className="ms-3">
                                          {moment(itm?.expiration_date) >
                                            moment() ? (
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                if (user.loggedIn) {
                                                  setData(itm);
                                                  // setTimerClass(true)
                                                  document
                                                    .getElementById(
                                                      'applyModalClick'
                                                    )
                                                    .click();
                                                } else {
                                                  history.push('/login');
                                                }
                                              }}
                                              disabled={itm?.isBidSubmitted}
                                            >
                                              {itm?.isBidSubmitted
                                                ? 'Applied'
                                                : 'Apply'}
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-danger"
                                              disabled={itm.isBidSubmitted}
                                            >
                                              Expired
                                            </button>
                                          )}
                                        </div>
                                      </div>

                                      <div className="view-detail">
                                        {/* <Link to={`/job/view2/${itm?.id}`}> */}
                                        <span
                                          className="edit_icon"
                                          title="View"
                                          onClick={() => {
                                            if (
                                              !localStorage.getItem('token')
                                            ) {
                                              history.push('/login');
                                            } else {
                                              history.push(
                                                `/jobs/view/${itm.id}`
                                              );
                                            }
                                          }}
                                        >
                                          View Details
                                          <IoIosArrowRoundForward />
                                        </span>
                                        {/* </Link> */}
                                      </div>

                                      {itm?.status == 'pending' ? (
                                        <>
                                          <div className="view-detail">
                                            <span
                                              className="pointer text-primary"
                                              onClick={(e) => {
                                                document
                                                  .getElementById(
                                                    'OpenBidEditModel'
                                                  )
                                                  .click();
                                                setform({
                                                  ...form,
                                                  id: itm?.id,
                                                  agreed_rate:
                                                    itm?.agreed_rate || '',
                                                });
                                              }}
                                            >
                                              Update Bid Price
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}{' '}
                      </>
                    ) : (
                      <>
                        {ProjectsData?.length != 0 && (
                          <div
                            className={`${total > 1 ? 'table-responsive' : ''
                              } respTable table_section respTable job-sect-table new-table`}
                          >
                            <table class="table ">
                              <thead className="table_head">
                                <tr className="heading_row">
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == 'expiration_date asc'
                                        ? sorting(
                                          'expiration_date desc',
                                          'desc'
                                        )
                                        : sorting('expiration_date asc', 'asc')
                                    }
                                  >
                                    Time Left(min){' '}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == 'load_id asc'
                                        ? sorting('load_id desc', 'desc')
                                        : sorting('load_id asc', 'asc')
                                    }
                                  >
                                    Load ID / Lane ID
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == 'stops asc'
                                        ? sorting('stops desc', 'desc')
                                        : sorting('stops asc', 'asc')
                                    }
                                  >
                                    Stops
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>

                                  {/* <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                        'origin_location_city asc'
                                        ? sorting(
                                          'origin_location_city desc',
                                          'desc'
                                        )
                                        : sorting(
                                          'origin_location_city asc',
                                          'asc'
                                        )
                                    }
                                  >

                                    <span className="d-flex align-items-center">
                                      {' '}
                                      Origin City{' '}
                                      {filters?.sortBy ===
                                        'origin_location_city asc' ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{' '}
                                            <span className="afont">Z</span>
                                          </div>{' '}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{' '}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                        'origin_location_state asc'
                                        ? sorting(
                                          'origin_location_state desc',
                                          'desc'
                                        )
                                        : sorting(
                                          'origin_location_state asc',
                                          'asc'
                                        )
                                    }
                                  >

                                    <span className="d-flex align-items-center">
                                      {' '}
                                      Origin State{' '}
                                      {filters?.sortBy ===
                                        'origin_location_state asc' ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{' '}
                                            <span className="afont">Z</span>
                                          </div>{' '}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{' '}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                        'origin_location_postal_code asc'
                                        ? sorting(
                                          'origin_location_postal_code desc',
                                          'desc'
                                        )
                                        : sorting(
                                          'origin_location_postal_code asc',
                                          'asc'
                                        )
                                    }
                                  >
                                    Origin Zipcode
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                        'destination_location_city asc'
                                        ? sorting(
                                          'destination_location_city desc',
                                          'desc'
                                        )
                                        : sorting(
                                          'destination_location_city asc',
                                          'asc'
                                        )
                                    }
                                  >

                                    <span className="d-flex align-items-center">
                                      {' '}
                                      Destination City{' '}
                                      {filters?.sortBy ===
                                        'destination_location_city asc' ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{' '}
                                            <span className="afont">Z</span>
                                          </div>{' '}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{' '}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                        'destination_location_state asc'
                                        ? sorting(
                                          'destination_location_state desc',
                                          'desc'
                                        )
                                        : sorting(
                                          'destination_location_state asc',
                                          'asc'
                                        )
                                    }
                                  >

                                    <span className="d-flex align-items-center">
                                      {' '}
                                      Destination State{' '}
                                      {filters?.sortBy ===
                                        'destination_location_state asc' ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{' '}
                                            <span className="afont">Z</span>
                                          </div>{' '}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{' '}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                        'destination_location_postal_code asc'
                                        ? sorting(
                                          'destination_location_postal_code desc',
                                          'desc'
                                        )
                                        : sorting(
                                          'destination_location_postal_code asc',
                                          'asc'
                                        )
                                    }
                                  >
                                    Destination Zipcode
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == 'load_start_date asc'
                                        ? sorting(
                                          'load_start_date desc',
                                          'desc'
                                        )
                                        : sorting('load_start_date asc', 'asc')
                                    }
                                  >
                                    Pick Up Date{' '}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"

                                    ></i>
                                  </th> */}
                                  {/* <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == 'expiration_date asc'
                                        ? sorting(
                                          'expiration_date desc',
                                          'desc'
                                        )
                                        : sorting('expiration_date asc', 'asc')
                                    }
                                  >
                                    Expiration Date{' '}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th> */}

                                  {visibleColumns.map((item) => (
                                    <th
                                      onClick={() => {
                                        sorting(
                                          ConvertToSortingKeys(item),
                                          filters?.sorder == "asc" ? "desc" : "asc"
                                        );
                                      }}
                                      className="text-capitalize table_data "
                                    >
                                      <span className="d-flex align-items-center">
                                        {replaceUnderscoresWithSpace(item)}
                                        {item == "origin_zip" ||
                                          item == "destination_zip" ||
                                          item == "load_start_date" ||
                                          item == "load_end_date" ? (
                                          <i className="fa fa-sort"></i>
                                        ) : (
                                          <i aria-hidden="true">
                                            {filters?.sorder === "asc" ? (
                                              <div class="d-flex  zfonts align-items-center">
                                                <div className="d-flex flex-column">
                                                  <span className="zfont">A </span>{" "}
                                                  <span className="afont">Z</span>
                                                </div>{" "}
                                                <span>
                                                  <span class="material-icons arrfont">
                                                    north
                                                  </span>
                                                </span>
                                              </div>
                                            ) : (
                                              <div class="d-flex zfonts align-items-center">
                                                <div className="d-flex flex-column">
                                                  <span className="zfont">Z </span>{" "}
                                                  <span className="afont">A</span>
                                                </div>
                                                <span>
                                                  <span class="material-icons arrfont">
                                                    south
                                                  </span>
                                                </span>
                                              </div>
                                            )}
                                          </i>
                                        )}{" "}
                                        <i
                                          className="fa fa-times ms-1 header-cross"
                                          onClick={(e) => handleColumnToggle(item)}
                                        ></i>{" "}
                                      </span>
                                    </th>
                                  ))}

                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == 'createdAt asc'
                                        ? sorting('createdAt desc', 'desc')
                                        : sorting('createdAt asc', 'asc')
                                    }
                                  >
                                    Date Created{' '}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>

                                  <th scope="col" className="table_data ">
                                    Action
                                  </th>
                                  <th>Bid Apply</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!loaging &&
                                  ProjectsData &&
                                  ProjectsData.map((itm, i) => {
                                    return (
                                      <>
                                        <tr className="data_row">
                                          <td>
                                            <div className=" d-flex align-items-center">
                                              <CountdownTimer
                                                TimerClass={TimerClass}
                                                expirationDate={
                                                  itm?.expiration_date
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            className="table_dats"
                                            onClick={(e) => {
                                              // view(itm.id);
                                            }}
                                          >
                                            <p
                                              className="mb-0 load-id  "
                                              onClick={() => {
                                                if (
                                                  !localStorage.getItem('token')
                                                ) {
                                                  history.push(`/login`);
                                                } else {
                                                  history.push(
                                                    `/jobs/view/${itm?.id}`
                                                  );
                                                }
                                              }}
                                            >
                                              {itm.load_id ||
                                                itm.lane_id ||
                                                '--'}
                                            </p>
                                          </td>
                                          <td className="table_dats">
                                            <div
                                              className="stops-down-arrow"
                                              onClick={() => {
                                                setShowStop(
                                                  showStop == itm.id
                                                    ? ''
                                                    : itm.id
                                                );
                                              }}
                                            >
                                              All Stops
                                              <i class="fas fa-arrow-circle-down ms-2"></i>
                                            </div>
                                          </td>



                                          {visibleColumns.map((item, index) => (
                                            <td className="text-capitalize">
                                              {ColumnReturner(item, itm[[item]], itm)}
                                            </td>
                                          ))}

                                          <td className="table_dats">
                                            {datepipeModel.date(
                                              itm.createdAt
                                            ) || '--'}
                                          </td>

                                          <td className="table_dats">
                                            <div className="action_icons">
                                              <></>

                                              <span
                                                className="edit_icon"
                                                title="View"
                                                onClick={() => {
                                                  if (
                                                    !localStorage.getItem(
                                                      'token'
                                                    )
                                                  ) {
                                                    history.push('/login');
                                                  } else {
                                                    history.push(
                                                      `/jobs/view/${itm.id}`
                                                    );
                                                  }
                                                }}
                                              >
                                                <i className="fa fa-eye"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            {moment(itm?.expiration_date) >
                                              moment() ? (
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  if (user?.loggedIn) {
                                                    setData(itm);
                                                    // setTimerClass(true)
                                                    document
                                                      .getElementById(
                                                        'applyModalClick'
                                                      )
                                                      .click();
                                                  } else {
                                                    history.push('/login');
                                                  }
                                                }}
                                                disabled={itm.isBidSubmitted}
                                              >
                                                {itm.isBidSubmitted
                                                  ? 'Applied'
                                                  : 'Apply'}
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-danger"
                                                disabled={itm.isBidSubmitted}
                                              >
                                                Expired
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                        {showStop == itm.id ? (
                                          <>
                                            <tr>
                                              <td colSpan={14}>
                                                <table className="table ">
                                                  <thead>
                                                    <tr>
                                                      <th>Stop No.</th>
                                                      {itm?.board_id == environment?.contractId ?<><th>Stop NASS</th><th>Stop Name</th></>:<></>}
                                                      {itm?.stops?.find((item, index) => item?.location_id) ? <th>Location Id</th> : <></>}
                                                      <th>Address</th>
                                                      {itm?.board_id == environment?.contractId ?<><th>Stop City</th><th>Stop State</th></>:<></>}
                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                    {itm?.stops?.length > 0 ? itm?.stops.map(
                                                      (sitm, i) => {
                                                        return (
                                                          <tr key={sitm.id}>
                                                            {/* <td>{sitm.stop_no}</td> */}
                                                            <td>{i + 1}</td>
                                                            {itm?.board_id == environment?.contractId ?<><td>{sitm?.stop_nass}</td><td>{sitm?.stop_name}</td></>:<></>}
                                                            {sitm?.location_id ? <td>{sitm?.location_id}</td> : <></>}
                                                            <td>
                                                              {sitm.address}
                                                            </td>
                                                            {itm?.board_id == environment?.contractId ?<><td>{sitm?.stop_city}</td><td>{sitm?.stop_name}</td></>:<></>}
                                                          </tr>
                                                        );
                                                      }
                                                    ) : <tr>
                                                      <td colspan="3"><span className="text-center mx-auto d-block py-4">No Data</span></td></tr>}
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        )}{' '}
                      </>
                    )}
                  </div>
                </div>

                {!loaging && total == 0 ? (
                  <div className=" no-data">
                    <img src="/assets/img/no-data.png" />
                    No Data
                  </div>
                ) : (
                  <></>
                )}
                {!loaging && total > 0 ? (
                  <div className="paginationWrapper main-pagination mb-5">
                    <div className="d-flex align-items-center">
                      <div className="me-2 user-name-color">Show</div>
                      {total > 0 && (
                        <select
                          value={filters?.count}
                          onChange={(e) => {
                            setFilter({ ...filters, count: e.target.value });
                            GetAllprojects({ count: e.target.value });
                          }}
                          className="form-control"
                        >
                          {/* <option value=""> All</option> */}
                          <option value={'10'}>10</option>

                          <option value={'20'}> 20</option>
                          <option value={'30'}> 30</option>
                          <option value={'40'}>40</option>
                          <option value={'80'}>80</option>
                        </select>
                      )}{' '}
                      <div className="ms-2 user-name-color">
                        from {total} Loads
                      </div>
                    </div>

                    <Pagination
                      activePage={filters.page}
                      itemClassPrev="back_page"
                      itemClassNext="next_page"
                      itemsCountPerPage={filters.count}
                      totalItemsCount={total}
                      pageRangeDisplayed={3}
                      onChange={pageChange}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {loaging ? (
                  <div className="text-center py-4">
                    <img src="/assets/img/loader1.gif" className="pageLoader" />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <ApplyModal
          data={data}
          result={(e) => {
            if (e.event == 'submit') {
              GetAllprojects();
            }
          }}
        />
      </Layout2>
    </>
  );
}
