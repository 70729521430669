import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-js-pagination";
import methodModel from "../../methods/methods";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import debounce from "lodash.debounce";
import datepipeModel from "../../models/datepipemodel";
import { Tooltip } from "react-tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from "moment";
import { FiFilter } from "react-icons/fi";
import { toast } from "react-toastify";
import CountdownTimer from "./countdoenTimer";
import ApplyModal from "./ApplyModal";

export default function JobSearch() {
  const Navigate = useHistory();
  const [Filtername, setFIltername] = useState("");

  const location = useLocation();
  const [PreviewsFIlter, setPreviewsFilter] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [loaging, setLoader] = useState(true);
  const [Min_rate, setMin_rate] = useState("50");
  const [Max_rate, setMax_rate] = useState("5000");
  const Searchdata = params.get("search");

  const history = useHistory();
  const [Board, setAllBoards] = useState([]);
  const [boardFilter, setboardFilter] = useState({
    board_id: "",
  });
  const user = useSelector((state) => state.user);
  const [selectedDistance, setSelectedDistance] = useState(null);
  const [ProjectsData, setProjectsData] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("search");
  const [Tab, setTab] = useState("Table");
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");
  const { id } = useParams();
  const [board, setBoards] = useState([]);
  const [userBoards, setUserBoards] = useState([]);

  const [LiveFilter, setLiveFilter] = useState([]);
  const [QaryName, setQuaryName] = useState([]);
  const [TimerClass, setTimerClass] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filters, setFilter] = useState({
    page: 1,
    count: 20,
    search: "",
    isDeleted: false,
    sorder: "",
  });
  useEffect(() => {
    // console.log(LiveFilter, "============UPDATE");
  }, [filterName]);
  const getBaords = () => {
    ApiClient.get("boards?status=active").then((res) => {
      if (res.success) {
        setAllBoards(res?.data?.data);
        const filteredBoards = res?.data?.data.filter((item) =>
          user?.board_id?.includes(item.id)
        );
        setUserBoards(filteredBoards);
      }
    });
  };
  const url = window.location.pathname;
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState();
  const latestSliderValue = React.useRef([0, 0]);
  const [Id, setId] = useState("");
  const [QueryIndex, setQueryIndex] = useState(0);

  const calculateTimeRemaining = (time) => {
    const now = moment().format("DD-MM-YYYY HH:MM A");
    const pastTime = moment(time).format("DD-MM-YYYY HH:MM A");
    const difference = now - pastTime;

    if (difference <= 0) {
      return false;
    }
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (minutes <= 15) {
      return minutes;
    }
  };
  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    let indexToUpdate = -1;

    for (let i = 0; i < LiveFilter.length; i++) {
      if (
        LiveFilter[i].key === "min_rate" ||
        LiveFilter[i].key === "max_rate"
      ) {
        indexToUpdate = i;
        break;
      }
    }

    if (indexToUpdate !== -1) {
      if (min !== undefined) {
        LiveFilter[indexToUpdate].value = min;
      }
      if (max !== undefined) {
        LiveFilter[indexToUpdate + 1].value = max;
      }
    } else {
      if (min !== undefined) {
        LiveFilter.push({
          key: "min_rate",
          value: min?.toString(),
        });
      }
      if (max !== undefined) {
        LiveFilter.push({
          key: "max_rate",
          value: max?.toString(),
        });
      }
    }
    GetAllprojects({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const sorting = (key, order) => {
    setFilter({ ...filters, sortBy: key, sorder: order });
    GetAllprojects({ sortBy: key, sorder: order });
  };
  const GetAllprojects = (p = {}) => {
    let newfilters = {
      ...filters,
      ...p,

      // sortBy: "expiration_date desc",
    };

    if (Searchdata) {
      newfilters = {
        ...newfilters,
        search: Searchdata,
      };
    }
    if (user && user?.id) {
      newfilters = {
        ...filters,
        ...p,
        user_id: user.id,
        // sortBy: "expiration_date desc",
      };
    }

    if (id) {
      newfilters = {
        ...newfilters,
        board_id: id,
      };
    }
    let URL = "filter-loads";
    if (url == "/delivered-jobs") {
      URL = "frontend/filter-loads";
      newfilters = {
        ...newfilters,
        shipment_status: "delivered",
      };
    }
    if (url == "/intransit-jobs") {
      URL = "frontend/filter-loads";
      newfilters = {
        ...newfilters,
        shipment_status: "in_transit",
      };
    }
    if (url == "/pendingPickup-jobs") {
      URL = "frontend/filter-loads";
      newfilters = {
        ...newfilters,
        shipment_status: "awarded",
      };
    }

    if (newfilters?.min_distance == 2000) {
      delete newfilters.max_distance;
    }
    setLoader(true);

    ApiClient.get(URL, newfilters).then((result) => {
      if (result.success) {
        setTotal(result.data?.total);
        setProjectsData(result.data?.data);
      }
      if (Searchdata) {
        history.replace("/jobs1");
      }
      setLoader(false);
    });
  };

  //   useEffect(()=>{

  // GetAllprojects({...filters,search:SearchCity||Searchdata})
  //   },[Searchdata,SearchCity])

  const GetFilter = () => {
    ApiClient.get("get-filters").then((res) => {
      if (res.success) {
        if (res?.data?.length != 0) {
          setPreviewsFilter(true);
        }
        setQuaryName(res?.data?.data);
        setFilterName(res?.data?.data?.[0]?.name);
        setId(res?.data?.data?.[0]?.id);
        setQueryIndex(0);
        const updatedFilters = { ...filters };

        res?.data?.data?.[0]?.key_value?.forEach((itm) => {
          // if (itm?.key === "max_weight") {
          //   updatedFilters.max_weight = itm?.value;
          // }

          // if (itm?.key === "min_weight") {
          //   updatedFilters.min_weight = itm?.value;
          // }

          if (itm?.key === "min_distance") {
            updatedFilters.min_distance = itm?.value;
          }

          if (itm?.key === "max_distance") {
            updatedFilters.max_distance = itm?.value;
          }

          if (itm?.key === "min_rate") {
            updatedFilters.min_rate = itm?.value;
          }

          if (itm?.key === "max_rate") {
            updatedFilters.max_rate = itm?.value;
          }
          const maxWeight = parseInt(updatedFilters.max_weight);
          const minWeight = parseInt(updatedFilters.min_weight);

          const maxDistance = parseInt(updatedFilters.max_distance);
          const minDistance = parseInt(updatedFilters.min_distance);

          const formattedWeightRangeString = `${minWeight}-${maxWeight}`;
          const formattedDistanceRangeString = `${minDistance}-${maxDistance}`;
          setSelectedWeight(formattedWeightRangeString);
          setSelectedDistance(formattedDistanceRangeString);
          // if (updatedFilters?.min_rate || updatedFilters?.max_rate) {
          if (updatedFilters?.min_rate) {
            setMin_rate(updatedFilters?.min_rate);
          } else {
            setMin_rate("50");
          }
          if (updatedFilters?.max_rate) {
            setMax_rate(updatedFilters?.max_rate);
          } else {
            setMax_rate("4000");
          }
          // }
          setFilter({ ...filters, updatedFilters });
          setTimeout(() => {
            setFilter({ ...filters, updatedFilters });
          }, 2000);
        });
      }
    });
  };
  const SetFilter = (index) => {
    // ApiClient.get("get-filters").then((res) => {
    // if (res.success) {
    //   if (res?.data?.length !== 0) {
    // setPreviewsFilter(true);
    const firstItem = QaryName?.[index];

    setFilterName(firstItem?.name);
    setId(firstItem?.id);
    setQueryIndex(index);

    const updatedFilters = { ...filters };

    firstItem?.key_value.forEach((itm) => {
      switch (itm.key) {
        // case "max_weight":
        //   updatedFilters.max_weight = itm.value;
        //   break;
        // case "min_weight":
        //   updatedFilters.min_weight = itm.value;
        //   break;
        case "min_distance":
          updatedFilters.min_distance = itm.value;
          break;
        case "max_distance":
          updatedFilters.max_distance = itm.value;
          break;
        case "min_rate":
          updatedFilters.min_rate = itm.value;
          break;
        case "max_rate":
          updatedFilters.max_rate = itm.value;
          break;
        default:
          break;
      }
    });

    const maxWeight = parseInt(updatedFilters.max_weight);
    const minWeight = parseInt(updatedFilters.min_weight);
    const maxDistance = parseInt(updatedFilters.max_distance);
    const minDistance = parseInt(updatedFilters.min_distance);

    const formattedWeightRangeString = `${minWeight}-${maxWeight}`;
    const formattedDistanceRangeString = `${minDistance}-${maxDistance}`;
    setSelectedWeight(formattedWeightRangeString);
    setSelectedDistance(formattedDistanceRangeString);
    setMin_rate(updatedFilters.min_rate);
    setMax_rate(updatedFilters.max_rate);

    // Update the filter state outside the loop
    setFilter({ ...filters, ...updatedFilters });
    GetAllprojects({
      ...filters,
      // max_weight: maxWeight,
      // min_weight: minWeight,
      min_distance: minDistance,
      max_distance: maxDistance,
    });
    document.getElementById("closeButtonId").click();
  };
  // }
  //   });
  // };

  const resetFilter = () => {
    setFilter({
      ...filters,
      max_distance: "",
      min_distance: "",
      search: "",
      origin_lat: "",
      origin_lng: "",
    });
    GetAllprojects({
      ...filters,
      max_distance: "",
      min_distance: "",
      search: "",
      origin_lat: "",
      origin_lng: "",
    });
    setQueryIndex(null);
    setSelectedDistance(null);
    if (search || lat || lng) {
      history.push("/jobs1");
    }
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    GetAllprojects({ page: e });
  };

  const handleCheckboxChange = (event) => {
    const newValue = event.target.value;
    const isCurrentlySelected = selectedDistance === newValue;
    setSelectedDistance(isCurrentlySelected ? null : newValue);
    setFilter({ ...filters, weight: isCurrentlySelected ? null : newValue });
    GetAllprojects({ weight: isCurrentlySelected ? null : newValue });
  };

  const GetProfile = () => {
    ApiClient.get("user/detail", { id: user?.id }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.board_id);
        let IDArray = [];
        if (res?.data?.boardDetails) {
          let fltr = res?.data?.boardDetails?.filter(
            (itm) => itm?.board_status == "active"
          );

          for (let i = 0; i < fltr.length; i++) {
            IDArray.push(fltr[i].id);
          }

          const JoinedIDS = IDArray?.join(",");
          if (fltr?.length > 0) {
            setFilter({ ...filters, board_id: JoinedIDS });
            GetAllprojects({
              ...filters,
              board_id: JoinedIDS,
              search: Searchdata,
            });
          } else {
            setFilter({ ...filters, isLogin: true });
            GetAllprojects({ ...filters, isLogin: true });
          }
        }
      }
    });
  };

  const ChangeStatus = (p) => {
    GetAllprojects(p);
  };

  useEffect(() => {
    getBaords();
    if (user && user?.id) {
      GetFilter();
      GetProfile();
    }

    setFilter({
      ...filters,
      search: Searchdata || search,
      origin_lat: lat,
      origin_lng: lng,
    });
    GetAllprojects({
      search: Searchdata || search,
      origin_lat: lat,
      origin_lng: lng,
    });
  }, [user]);

  useEffect(() => {
    calculateTimeRemaining(1712896200);
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const SaveFilter = () => {
    // if (LiveFilter?.length == 0 || !filterName) {
    //   return false;
    // }
    if (!filterName || LiveFilter?.length == 0) {
      return false;
    }

    if (QaryName?.some((itm) => itm?.name == filterName)) {
      if (Id) {
        ApiClient.put("edit-Filter", {
          id: Id,
          name: filterName,
          key_value: LiveFilter,
        }).then((res) => {
          if (res.success) {
            setFilterName("");
            // toast.success(res.message);
            document.getElementById("closeButtonId").click();
            GetFilter();
          }
        });
      }
    } else {
      ApiClient.post("saved-filter", {
        name: filterName,
        key_value: LiveFilter,
      }).then((res) => {
        if (res.success) {
          // toast.success(res.message);
          document.getElementById("closeButtonId").click();
          GetFilter();
        }
      });
    }
  };

  return (
    <>
      <WebsiteHeader />
      <div className="wrapper" style={{ marginTop: "16vh" }}>
        <div className="container">
          <div className="header_nav">
            <span className="headerNav_child" onClick={() => history.push("/")}>
              Home
            </span>
            <span
              className="headerNav_child headerNav_child_active"
              onClick={() => history.push("/jobs1")}
            >
              Loads
            </span>
          </div>
          <div className="mt-5">
            <div className="row ">
              <div className="col-md-12">
                <div className="jobs-listing">
                  <div className="row align-items-center">
                    <div className="col-lg-8 col-md-7 ">
                      <div className="job-searchbar">
                        <h4 className="mb-0">
                          {ProjectsData?.length} Loads Found
                        </h4>
                        <div className="row fullit ">
                          <div className="col-12">
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (filters?.search) {
                                  setFilter({
                                    ...filters,
                                    search: filters?.search,
                                  });
                                  GetAllprojects({ search: filters?.search });
                                }
                              }}
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="d-flex jobs_child-flex">
                                    <input
                                     className="form-control "
                                     type="text"
                                      value={filters?.search}
                                      onChange={(e) => {
                                        if (e?.target?.value == "") {
                                          GetAllprojects({ search: "" });
                                        }
                                        setFilter({
                                          ...filters,
                                          search: e.target.value,
                                        });
                                      }}
                                      name=""
                                      id=""
                                      placeholder="Search Here"
                                    />
                                    {filters?.search && (
                                      <i
                                        class="fa fa-times search_cross"
                                        onClick={() => resetFilter()}
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                    <button className="btn-cancel">
                                      {" "}
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-5 ">
                      <div className="row">
                        {!user?.id ? null : (
                          <div className="col-md-6 col-6">
                            <button
                              onClick={() => {
                                setMax_rate();
                                setFilter({ ...filters });
                              }}
                              type="button"
                              class="btn btn-primary w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <FiFilter className="me-2" />
                              Filters
                            </button>

                            <div
                              className="modal fade jobs-modal right"
                              id="exampleModal"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Filters</h5>
                                    <button
                                      type="button"
                                      id="closeButtonId"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div class="modal-body">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div>
                                          <label className="mb-1">
                                            Save Filters
                                          </label>
                                          <div className="d-flex gap-1">
                                            {" "}
                                            <textarea
                                              value={filterName}
                                              placeholder="Enter Filter Name"
                                              onChange={(e) => {
                                                setFilterName(e.target.value);
                                              }}
                                              className="form-control"
                                            ></textarea>
                                            <div>
                                              {filterName != "" ? (
                                                <i
                                                  onClick={() => {
                                                    setFilterName("");
                                                    resetFilter();
                                                    document
                                                      .getElementById(
                                                        "closeButtonId"
                                                      )
                                                      .click();
                                                  }}
                                                  class="fa fa-trash clico"
                                                  aria-hidden="true"
                                                ></i>
                                              ) : null}
                                            </div>{" "}
                                          </div>
                                        </div>

                                        <div>
                                          <div class="badges-main">
                                            <ul className="badgeflex">
                                              {QaryName?.map((itm, index) => {
                                                return (
                                                  <>
                                                    {itm?.name == "\n" ||
                                                    itm?.name == "" ? null : (
                                                      <li
                                                        onClick={() =>
                                                          SetFilter(index)
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            index == QueryIndex
                                                              ? "badge badge-success"
                                                              : ""
                                                          }
                                                        >
                                                          {itm?.name}
                                                        </div>
                                                      </li>
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{ width: "100%" }}
                                        className="d-flex align-items-end justify-content-end "
                                      >
                                        <div className="dropdown addDropdown">
                                          <button
                                            className="btn btn-primary dropdown-toggle filterbt"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            {Filtername
                                              ? Filtername == ""
                                                ? "All"
                                                : methodModel.capitalizeFirstLetter(
                                                    Filtername
                                                  )
                                              : "All"}
                                          </button>
                                          <div
                                            className="dropdown-menu shadow bg_hover"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <a
                                              className={
                                                boardFilter?.board_id == ""
                                                  ? "dropdown-item active"
                                                  : "dropdown-item"
                                              }
                                              onClick={() => {
                                                setboardFilter({
                                                  ...boardFilter,
                                                  board_id: "",
                                                });
                                                setFIltername("All");
                                                ChangeStatus({});
                                              }}
                                            >
                                              All
                                            </a>
                                            {!user?.id ? (
                                              <>
                                                {Board &&
                                                  Board?.map((itm) => {
                                                    return (
                                                      <a
                                                        className={
                                                          boardFilter.board_id ==
                                                          itm?.id
                                                            ? "dropdown-item active"
                                                            : "dropdown-item"
                                                        }
                                                        onClick={() => {
                                                          setFIltername(
                                                            itm?.name
                                                          );
                                                          setboardFilter({
                                                            ...boardFilter,
                                                            board_id: itm?.id,
                                                          });
                                                          ChangeStatus({
                                                            board_id: itm?.id,
                                                          });
                                                        }}
                                                      >
                                                        {methodModel.capitalizeFirstLetter(
                                                          itm?.name
                                                        )}
                                                      </a>
                                                    );
                                                  })}
                                              </>
                                            ) : (
                                              <>
                                                {userBoards &&
                                                  userBoards?.map((itm) => {
                                                    return (
                                                      <a
                                                        className={
                                                          boardFilter.board_id ==
                                                          itm?.id
                                                            ? "dropdown-item active"
                                                            : "dropdown-item"
                                                        }
                                                        onClick={() => {
                                                          setFIltername(
                                                            itm?.name
                                                          );
                                                          setboardFilter({
                                                            ...boardFilter,
                                                            board_id: itm?.id,
                                                          });
                                                          ChangeStatus({
                                                            board_id: itm?.id,
                                                          });
                                                        }}
                                                      >
                                                        {methodModel.capitalizeFirstLetter(
                                                          itm?.name
                                                        )}
                                                      </a>
                                                    );
                                                  })}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="categories-sidebar ">
                                          {/* <div>
                                          <h4>Weight</h4>
                                          <ul>
                                            <li>
                                              <div class="form-check">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  value="100"
                                                  id="0-100"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedWeight(
                                                        "0-100"
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "max_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "100";
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "min_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "0";
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: "max_weight",
                                                            value: "100",
                                                          },
                                                          {
                                                            key: "min_weight",
                                                            value: "0",
                                                          }
                                                        );
                                                      }

                                                      // setFilter({ ...filters, max_weight: 100, min_weight: 0 })
                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "100",
                                                        min_weight: "0",
                                                      });
                                                    } else {
                                                      // setFilter({ ...filters, max_weight: '', min_weight: '' })
                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "",
                                                        min_weight: "",
                                                      });

                                                      setSelectedWeight("");
                                                    }
                                                  }}
                                                  checked={
                                                    selectedWeight === "0-100"
                                                  }
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="0-100"
                                                >
                                                  Under 100 kg
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="form-check">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  value="200"
                                                  id="0-200"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedWeight(
                                                        "0-200"
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "max_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "200";
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "min_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "0";
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: "max_weight",
                                                            value: "200",
                                                          },
                                                          {
                                                            key: "min_weight",
                                                            value: "0",
                                                          }
                                                        );
                                                      }

                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "200",
                                                        min_weight: "0",
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "",
                                                        min_weight: "",
                                                      });

                                                      setSelectedWeight("");
                                                    }
                                                  }}
                                                  checked={
                                                    selectedWeight === "0-200"
                                                  }
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="0-200"
                                                >
                                                  Under 200 kg
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="form-check">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  value="200-400"
                                                  id="200-400"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedWeight(
                                                        "200-400"
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "max_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "400";
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "min_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "0";
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: "max_weight",
                                                            value: "400",
                                                          },
                                                          {
                                                            key: "min_weight",
                                                            value: "0",
                                                          }
                                                        );
                                                      }

                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "400",
                                                        min_weight: "0",
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "",
                                                        min_weight: "",
                                                      });

                                                      setSelectedWeight("");
                                                    }
                                                  }}
                                                  checked={
                                                    selectedWeight === "200-400"
                                                  }
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="200-400"
                                                >
                                                  Under 400 kg
                                                </label>
                                              </div>
                                            </li>

                                            <li>
                                              <div class="form-check">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  value="0-1000"
                                                  id="1000+"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedWeight(
                                                        "0-1000"
                                                      );
                                                      var found = false;

                                                      for (
                                                        var i = 0;
                                                        i < LiveFilter.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "max_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "1000";
                                                          found = true;
                                                        }
                                                        if (
                                                          LiveFilter[i].key ===
                                                          "min_weight"
                                                        ) {
                                                          LiveFilter[i].value =
                                                            "0";
                                                          found = true;
                                                        }
                                                      }

                                                      if (!found) {
                                                        LiveFilter?.push(
                                                          {
                                                            key: "max_weight",
                                                            value: "1000",
                                                          },
                                                          {
                                                            key: "min_weight",
                                                            value: "0",
                                                          }
                                                        );
                                                      }
                                                      GetAllprojects({
                                                        ...filters,
                                                        min_weight: "1000",
                                                        max_weight: "",
                                                      });
                                                    } else {
                                                      GetAllprojects({
                                                        ...filters,
                                                        max_weight: "",
                                                        min_weight: "",
                                                      });

                                                      setSelectedWeight("");
                                                    }
                                                  }}
                                                  checked={
                                                    selectedWeight === "0-1000"
                                                  }
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="1000+"
                                                >
                                                  Above 1000 kg
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </div> */}

                                          <div>
                                            <h4>Distance</h4>
                                            <ul className="mb-0">
                                              <li>
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="200"
                                                    id="under200"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setSelectedDistance(
                                                          "0-200"
                                                        );
                                                        var found = false;

                                                        for (
                                                          var i = 0;
                                                          i < LiveFilter.length;
                                                          i++
                                                        ) {
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "max_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "200";
                                                            found = true;
                                                          }
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "min_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "0";
                                                            found = true;
                                                          }
                                                        }

                                                        if (!found) {
                                                          LiveFilter?.push(
                                                            {
                                                              key: "max_distance",
                                                              value: "200",
                                                            },
                                                            {
                                                              key: "min_distance",
                                                              value: "0",
                                                            }
                                                          );
                                                        }

                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "0",
                                                          max_distance: "200",
                                                        });
                                                      } else {
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "",
                                                          max_distance: "",
                                                        });
                                                        LiveFilter.pop();

                                                        setSelectedDistance("");
                                                      }
                                                    }}
                                                    checked={
                                                      selectedDistance ===
                                                        "0-200" ||
                                                      selectedDistance ==
                                                        "NaN-200"
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="under200"
                                                  >
                                                    Under 200Mi
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="500"
                                                    id="above500"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setSelectedDistance(
                                                          "0-500"
                                                        );
                                                        var found = false;

                                                        for (
                                                          var i = 0;
                                                          i < LiveFilter.length;
                                                          i++
                                                        ) {
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "max_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "500";
                                                            found = true;
                                                          }
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "min_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "0";
                                                            found = true;
                                                          }
                                                        }

                                                        if (!found) {
                                                          LiveFilter?.push(
                                                            {
                                                              key: "max_distance",
                                                              value: "500",
                                                            },
                                                            {
                                                              key: "min_distance",
                                                              value: "0",
                                                            }
                                                          );
                                                        }
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "0",
                                                          max_distance: "500",
                                                        });
                                                      } else {
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "",
                                                          max_distance: "",
                                                        });
                                                        LiveFilter.pop();
                                                        setSelectedDistance("");
                                                      }
                                                    }}
                                                    checked={
                                                      selectedDistance ===
                                                        "0-500" ||
                                                      selectedDistance ==
                                                        "NaN-500"
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="above500"
                                                  >
                                                    Under 500Mi
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="1500"
                                                    id="under1500"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setSelectedDistance(
                                                          "0-1500"
                                                        );
                                                        var found = false;

                                                        for (
                                                          var i = 0;
                                                          i < LiveFilter.length;
                                                          i++
                                                        ) {
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "max_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "1500";
                                                            found = true;
                                                          }
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "min_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "0";
                                                            found = true;
                                                          }
                                                        }

                                                        if (!found) {
                                                          LiveFilter?.push(
                                                            {
                                                              key: "max_distance",
                                                              value: "1500",
                                                            },
                                                            {
                                                              key: "min_distance",
                                                              value: "0",
                                                            }
                                                          );
                                                        }
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "0",
                                                          max_distance: "1500",
                                                        });
                                                      } else {
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "",
                                                          max_distance: "",
                                                        });
                                                        LiveFilter.pop();
                                                        setSelectedDistance("");
                                                      }
                                                    }}
                                                    checked={
                                                      selectedDistance ===
                                                        "0-1500" ||
                                                      selectedDistance ==
                                                        "NaN-1500"
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="under1500"
                                                  >
                                                    Under 1500Mi
                                                  </label>
                                                </div>
                                              </li>

                                              <li>
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="2000"
                                                    id="above2000"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setSelectedDistance(
                                                          "2000+"
                                                        );
                                                        var found = false;

                                                        for (
                                                          var i = 0;
                                                          i < LiveFilter.length;
                                                          i++
                                                        ) {
                                                          if (
                                                            LiveFilter[i]
                                                              .key ===
                                                            "min_distance"
                                                          ) {
                                                            LiveFilter[
                                                              i
                                                            ].value = "2000";
                                                            found = true;
                                                          }
                                                        }

                                                        if (!found) {
                                                          LiveFilter?.push({
                                                            key: "min_distance",
                                                            value: "2000",
                                                          });
                                                        }
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "2000",
                                                        });
                                                      } else {
                                                        GetAllprojects({
                                                          ...filters,
                                                          min_distance: "",
                                                          max_distance: "",
                                                        });
                                                        LiveFilter.pop();
                                                        setSelectedDistance("");
                                                      }
                                                    }}
                                                    checked={
                                                      selectedDistance ===
                                                        "0-2000" ||
                                                      selectedDistance ===
                                                        "2000+" ||
                                                      selectedDistance ==
                                                        "NaN-2000" ||
                                                      selectedDistance ===
                                                        "2000-200"
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="above1500"
                                                  >
                                                    Above 2000Mi
                                                  </label>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        {/* <div className="col-12 mt-md-3">
                                        <div class="dropdown jobsdropdown range_distance">
                                          <div className="">
                                            <label className="mb-3">
                                              Price Range
                                            </label>
                                            <RangeSlider
                                              min={0}
                                              step={5}
                                              value={[Min_rate, Max_rate]}
                                              max={10000}
                                              onInput={handleSliderChange}
                                            />
                                            <p className="d-flex justify-content-between align-items-center range_numbers ">
                                              <p className="numbers">
                                                {" "}
                                                ${Min_rate}
                                              </p>
                                              <p className="numbers">
                                                ${Max_rate}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div> */}

                                        <br></br>
                                        <div>
                                          <button
                                            onClick={() => {
                                              SaveFilter();
                                            }}
                                            className="btn dark-btn w-100 mb-4"
                                          >
                                            Save Changes
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-6 col-6">
                          <div className=" tabs-view">
                            <ul class="nav nav-tabs">
                              <li class="nav-item">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Grid"
                                  class={`nav-link ${
                                    Tab == "Card" ? "active" : null
                                  } `}
                                  onClick={() => {
                                    setTimerClass(false);
                                    setTab("Card");
                                  }}
                                >
                                  <i className="fa fa-th"></i>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="List"
                                  class={`nav-link ${
                                    Tab == "Table" ? "active" : null
                                  } `}
                                  onClick={() => {
                                    setTimerClass(true);
                                    setTab("Table");
                                  }}
                                >
                                  <i className="fa fa-list "></i>
                                </a>
                              </li>
                              <Tooltip id="my-tooltip" />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Featured  Jobs */}
                  <div className="jobs-section ">
                    {Tab == "Table" ? (
                      <>
                        {ProjectsData?.length != 0 && (
                          <div className={`${total>1?"table-responsive":""} respTable table_section respTable job-sect-table`}>
                            <table class="table ">
                              <thead className="table_head">
                                <tr className="heading_row">
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == "expiration_date asc"
                                        ? sorting(
                                            "expiration_date desc",
                                            "desc"
                                          )
                                        : sorting("expiration_date asc", "asc")
                                    }
                                  >
                                    Time Left(min){" "}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == "load_id asc"
                                        ? sorting("load_id desc", "desc")
                                        : sorting("load_id asc", "asc")
                                    }
                                  >
                                    Load ID / Lane ID
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == "stops asc"
                                        ? sorting("stops desc", "desc")
                                        : sorting("stops asc", "asc")
                                    }
                                  >
                                    Stops
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  {/* <th
                                    scope="col"
                                    className="table_data pointer"
                                  >
                                    Min. Rate
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                  >
                                    Max. Rate
                                  </th> */}
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                      "origin_location_city asc"
                                        ? sorting(
                                            "origin_location_city desc",
                                            "desc"
                                          )
                                        : sorting(
                                            "origin_location_city asc",
                                            "asc"
                                          )
                                    }
                                  >
                                    {/* Origin City
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    >{filters?.sortBy ==
                                      "origin_location_city asc" ? "A-Z" : "Z-A"}</i> */}
                                    <span className="d-flex align-items-center">
                                      {" "}
                                      Origin City{" "}
                                      {filters?.sortBy ===
                                      "origin_location_city asc" ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{" "}
                                            <span className="afont">Z</span>
                                          </div>{" "}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{" "}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                      "origin_location_state asc"
                                        ? sorting(
                                            "origin_location_state desc",
                                            "desc"
                                          )
                                        : sorting(
                                            "origin_location_state asc",
                                            "asc"
                                          )
                                    }
                                  >
                                    {/* Origin State
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    >{filters?.sortBy ==
                                      "origin_location_state asc" ? "A-Z" : "Z-A"}</i> */}
                                    <span className="d-flex align-items-center">
                                      {" "}
                                      Origin State{" "}
                                      {filters?.sortBy ===
                                      "origin_location_state asc" ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{" "}
                                            <span className="afont">Z</span>
                                          </div>{" "}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{" "}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                      "origin_location_postal_code asc"
                                        ? sorting(
                                            "origin_location_postal_code desc",
                                            "desc"
                                          )
                                        : sorting(
                                            "origin_location_postal_code asc",
                                            "asc"
                                          )
                                    }
                                  >
                                    Origin Zipcode
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                      "destination_location_city asc"
                                        ? sorting(
                                            "destination_location_city desc",
                                            "desc"
                                          )
                                        : sorting(
                                            "destination_location_city asc",
                                            "asc"
                                          )
                                    }
                                  >
                                    {/* Destination City
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    >{filters?.sortBy ==
                                      "destination_location_city asc" ? "A-Z" : "Z-A"}</i> */}
                                    <span className="d-flex align-items-center">
                                      {" "}
                                      Destination City{" "}
                                      {filters?.sortBy ===
                                      "destination_location_city asc" ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{" "}
                                            <span className="afont">Z</span>
                                          </div>{" "}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{" "}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                      "destination_location_state asc"
                                        ? sorting(
                                            "destination_location_state desc",
                                            "desc"
                                          )
                                        : sorting(
                                            "destination_location_state asc",
                                            "asc"
                                          )
                                    }
                                  >
                                    {/* Destination State
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    >{filters?.sortBy ==
                                      "destination_location_state asc" ? "A-Z" : "Z-A"}</i> */}
                                    <span className="d-flex align-items-center">
                                      {" "}
                                      Destination State{" "}
                                      {filters?.sortBy ===
                                      "destination_location_state asc" ? (
                                        <div class="d-flex  zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">A </span>{" "}
                                            <span className="afont">Z</span>
                                          </div>{" "}
                                          <span>
                                            <span class="material-icons arrfont">
                                              north
                                            </span>
                                          </span>
                                        </div>
                                      ) : (
                                        <div class="d-flex zfonts align-items-center">
                                          <div className="d-flex flex-column">
                                            <span className="zfont">Z </span>{" "}
                                            <span className="afont">A</span>
                                          </div>
                                          <span>
                                            <span class="material-icons arrfont">
                                              south
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy ==
                                      "destination_location_postal_code asc"
                                        ? sorting(
                                            "destination_location_postal_code desc",
                                            "desc"
                                          )
                                        : sorting(
                                            "destination_location_postal_code asc",
                                            "asc"
                                          )
                                    }
                                  >
                                    Destination Zipcode
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == "load_start_date asc"
                                        ? sorting(
                                            "load_start_date desc",
                                            "desc"
                                          )
                                        : sorting("load_start_date asc", "asc")
                                    }
                                  >
                                    Pick Up Date{" "}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                      // onClick={(e) =>
                                      //   filters?.sortBy == "load_start_date asc"
                                      //     ? sorting(
                                      //         "load_start_date desc",
                                      //         "desc"
                                      //       )
                                      //     : sorting(
                                      //         "load_start_date asc",
                                      //         "asc"
                                      //       )
                                      // }
                                    ></i>
                                  </th>
                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == "expiration_date asc"
                                        ? sorting(
                                            "expiration_date desc",
                                            "desc"
                                          )
                                        : sorting("expiration_date asc", "asc")
                                    }
                                  >
                                    Expiration Date{" "}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>

                                  <th
                                    scope="col"
                                    className="table_data pointer"
                                    onClick={(e) =>
                                      filters?.sortBy == "createdAt asc"
                                        ? sorting("createdAt desc", "desc")
                                        : sorting("createdAt asc", "asc")
                                    }
                                  >
                                    Date Created{" "}
                                    <i
                                      class="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </th>

                                  <th scope="col" className="table_data ">
                                    Action
                                  </th>
                                  <th>Bid Apply</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!loaging &&
                                  ProjectsData &&
                                  ProjectsData.map((itm, i) => {
                                    return (
                                      <tr className="data_row">
                                        <td>
                                          <div className=" d-flex align-items-center">
                                            <CountdownTimer
                                              TimerClass={TimerClass}
                                              expirationDate={
                                                itm?.expiration_date
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td
                                          className="table_dats"
                                          onClick={(e) => {
                                            // view(itm.id);
                                          }}
                                        >
                                          <p
                                            className="mb-0 load-id  "
                                            onClick={() => {
                                              if (
                                                !localStorage.getItem("token")
                                              ) {
                                                history.push(`/login`);
                                              } else {
                                                history.push(
                                                  `/job/view/${itm?.id}`
                                                );
                                              }
                                            }}
                                          >
                                            {itm.load_id || itm.lane_id ||"--"}
                                          </p>
                                        </td>
                                        <td className="table_dats">
                                          <div
                                            className="pointer badge badge-success common-badge"
                                            onClick={() => {
                                              if (user && user?.id) {
                                                history.push(
                                                  `/job/view/${itm?.id}`
                                                );
                                              } else {
                                                history.push("/login");
                                              }
                                            }}
                                          >
                                            All Stops
                                          </div>

                                          <div className="ellipses">
                                            {itm?.stops[0]?.address || "--"}
                                          </div>
                                          <div className="ellipses">
                                            {itm?.stops[1]?.address || "--"}
                                          </div>
                                        </td>
                                        {/* <td className="table_dats">
                                          ${itm?.min_rate || "--"}
                                        </td>
                                        <td className="table_dats">
                                          ${itm?.max_rate || "--"}
                                        </td> */}
                                        <td className="table_dats">
                                          {itm?.origin_location_city || "--"}
                                        </td>
                                        <td className="table_dats">
                                          {itm?.origin_location_state || "--"}
                                        </td>
                                        <td className="table_dats">
                                          {itm?.origin_location_postal_code ||
                                            "--"}
                                        </td>
                                        <td className="table_dats">
                                          {itm?.destination_location_city ||
                                            "--"}
                                        </td>
                                        <td className="table_dats">
                                          {itm?.destination_location_state ||
                                            "--"}
                                        </td>
                                        <td className="table_dats">
                                          {itm?.destination_location_postal_code ||
                                            "--"}
                                        </td>
                                        <td className="table_dats">
                                          {datepipeModel.date(
                                            itm.load_start_date
                                          ) || "--"}
                                        </td>
                                        <td className="table_dats">
                                          {datepipeModel.date(
                                            itm.expiration_date
                                          ) || "--"}
                                        </td>
                                        <td className="table_dats">
                                          {datepipeModel.date(itm.createdAt) ||
                                            "--"}
                                        </td>

                                        <td className="table_dats">
                                          <div className="action_icons">
                                            <></>

                                            <span
                                              className="edit_icon"
                                              title="View"
                                              onClick={() => {
                                                if (
                                                  !localStorage.getItem("token")
                                                ) {
                                                  history.push("/login");
                                                } else {
                                                  history.push(
                                                    `/job/view/${itm.id}`
                                                  );
                                                }
                                              }}
                                            >
                                              <i className="fa fa-eye"></i>
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          {moment(itm?.expiration_date) >
                                          moment() ? (
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                if (user?.loggedIn) {
                                                  setData(itm);
                                                  // setTimerClass(true)
                                                  document
                                                    .getElementById(
                                                      "applyModalClick"
                                                    )
                                                    .click();
                                                } else {
                                                  history.push("/login");
                                                }
                                              }}
                                              disabled={itm.isBidSubmitted}
                                            >
                                              {itm.isBidSubmitted
                                                ? "Applied"
                                                : "Apply"}
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-danger"
                                              disabled={itm.isBidSubmitted}
                                            >
                                              Expired
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        )}{" "}
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-md-12">
                          {!loaging && (
                            <div className="row">
                              {ProjectsData ? (
                                ProjectsData?.map((itm) => {
                                  return (
                                    <div className="  col-xl-6 col-md-10">
                                      <div className="jobs-inner">
                                        <div
                                          className="job-detail position-relative"
                                          onClick={() => {
                                            if (user?.loggedIn) {
                                              history.push(
                                                "/job/view/" + itm?.id
                                              );
                                            } else {
                                              history.push("/login");
                                            }
                                          }}
                                        >
                                          <div className="job-load job-time-absolute">
                                            <p className="badge">
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 512 512"
                                                class="me-1"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                                              </svg>
                                              Expires :
                                            </p>
                                            <p class="job-price ms-1">
                                              <span>
                                                {" "}
                                                <CountdownTimer
                                                  TimerClass={TimerClass}
                                                  expirationDate={
                                                    itm?.expiration_date
                                                  }
                                                />
                                              </span>
                                            </p>
                                          </div>
                                          <h4
                                            className="pt-md-4 pt-5"
                                            onClick={() => {
                                              history.push(
                                                "/job/view/" + itm?.id
                                              );
                                            }}
                                          >
                                            {itm?.origin_location_city} To{" "}
                                            {itm?.destination_location_city}
                                          </h4>
                                          <div className="d-flex justify-content-between">
                                            <div className="job-load">
                                              <p className="badge">
                                                {itm?.total_distance} Mi
                                              </p>
                                              {/* <p class="job-price">
                                                Range:
                                                <span>
                                                  {" "}
                                                  ${itm?.min_rate} - $
                                                  {itm?.max_rate}
                                                </span>
                                              </p> */}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="job-load">
                                              <p className="badge">
                                                Pickup Date:
                                              </p>
                                              <p class="job-price">
                                                <span>
                                                  {" "}
                                                  {datepipeModel.date(
                                                    itm?.load_start_date
                                                  )}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="job-load ms-4">
                                              <p className="badge">
                                                Expiration Date:
                                              </p>
                                              <p class="job-price">
                                                <span>
                                                  {" "}
                                                  {moment(
                                                    itm?.expiration_date
                                                  ).format("DD-MM-YYYY")}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="jobs-location-main pt-2">
                                          <div
                                            className="google-search"
                                            onClick={() => {
                                              history.push(
                                                "/job/view/" + itm?.id
                                              );
                                            }}
                                          >
                                            {/* <div className="google-icon">
                                              <img
                                                src={methodModel.userImg("")}
                                                className=""
                                              />
                                            </div> */}
                                            <div className="job-location">
                                              <h6>
                                                {methodModel.capitalizeFirstLetter(
                                                  itm?.customer_name
                                                )}
                                              </h6>
                                              <p className="job-loc-text">
                                                {" "}
                                                <img
                                                  src="assets/img/loc.svg"
                                                  className="me-1"
                                                />
                                                {itm?.origin_location_street}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            {/* <img
                                              src="assets/img/booMiarkSimple.svg"
                                              className="me-1"
                                            /> */}
                                            <div className="ms-3">
                                              {moment(itm?.expiration_date) >
                                              moment() ? (
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={() => {
                                                    if (user.loggedIn) {
                                                      setData(itm);
                                                      // setTimerClass(true)
                                                      document
                                                        .getElementById(
                                                          "applyModalClick"
                                                        )
                                                        .click();
                                                    } else {
                                                      history.push("/login");
                                                    }
                                                  }}
                                                  disabled={itm.isBidSubmitted}
                                                >
                                                  {itm.isBidSubmitted
                                                    ? "Applied"
                                                    : "Apply"}
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn btn-danger"
                                                  disabled={itm.isBidSubmitted}
                                                >
                                                  Expired
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <>No Data</>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!loaging && total == 0 ? (
                  <div className=" no-data">
                    <img src="/assets/img/no-data.png" />
                    No Data
                  </div>
                ) : (
                  <></>
                )}
                {!loaging && total > filters.count ? (
                  <div className="paginationWrapper main-pagination mb-5">
                    <div className="d-flex align-items-center">
                      <div className="me-2 user-name-color">Show</div>
                      {total > 0 && (
                        <select
                          value={filters?.count}
                          onChange={(e) => {
                            setFilter({ ...filters, count: e.target.value });
                            GetAllprojects({ count: e.target.value });
                          }}
                          className="form-control"
                        >
                          {/* <option value=""> All</option> */}
                          <option value={"10"}>10</option>

                          <option value={"20"}> 20</option>
                          <option value={"30"}> 30</option>
                          <option value={"40"}>40</option>
                          <option value={"80"}>80</option>
                        </select>
                      )}{" "}
                      <div className="ms-2 user-name-color">
                        from {total} Loads
                      </div>
                    </div>

                    <Pagination
                      activePage={filters.page}
                      itemClassPrev="back_page"
                      itemClassNext="next_page"
                      itemsCountPerPage={filters.count}
                      totalItemsCount={total}
                      pageRangeDisplayed={3}
                      onChange={pageChange}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {loaging ? (
                  <div className="text-center py-4">
                    <img src="/assets/img/loader1.gif" className="pageLoader" />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyModal
        data={data}
        result={(e) => {
          if (e.event == "submit") {
            GetAllprojects();
          }
        }}
      />
    </>
  );
}
