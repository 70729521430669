import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import datepipeModel from '../../models/datepipemodel';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaCircleExclamation } from 'react-icons/fa6';
import { FaRegClock } from 'react-icons/fa';
import ApplyModal from './ApplyModal';
import loader from '../../methods/loader';
import ReadMore from '../../components/common/ReadMore';
import environment from '../../environment';
import { env } from 'echarts';

function JobView2() {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [originaddress, setoriginaddress] = useState("")
  const [destinationaddress, setdestinationaddress] = useState("")
  const [createdDate, setCreated] = useState(0);
  const [ExpiredDate, setExpiredDate] = useState(0);
  const [FinalDate, setDate] = useState(0);
  const [data, setData] = useState({});
  const { id } = useParams();
  const [BidStatus, setBidStatus] = useState(false);
  const [LeftDays, setLeftDays] = useState(0);
  const [Trucks, setTruck] = useState([]);
  const [Hour, setHour] = useState(0);
  const [showItem, setShowItem] = useState({});


  let isBig =
    moment().format('DD-MM-YYYY HH:MM') >
    moment(data?.expiration_date).format('DD-MM-YYYY HH:MM');

  const GetLoad = () => {
    loader(true);
    ApiClient.get(`load`, { id, user_id: user?.id }).then((res) => {
      if (res.success) {
        const originaddress = res?.data?.origin_address
        const destinationaddress = res?.data?.destination_address
        setData(res?.data);
        setCreated(res?.data?.createdAt);
        setExpiredDate(res?.data?.board_id?.expiration_date);
        ApiClient.get(`address/detail`, { address: originaddress }).then((res) => {
          if (res.success) {
            setoriginaddress(res?.data)
            ApiClient.get(`address/detail`, { address: destinationaddress }).then((res) => {
              if (res.success) {
                setdestinationaddress(res?.data)

              }
            })
          }
        })
      }
      setTimeout(() => {
        loader(false);
      }, 2000);
    });
  };



  const Timeleft = () => {
    let Created = new Date(ExpiredDate);

    let expired = new Date();

    let Total = Created.getTime() - expired.getTime();
    let LeftDay = Math.round(Total / (1000 * 3600 * 24));
    setLeftDays(LeftDay);
    // Timeleft()
  };

  const StartTimer = () => {
    let Created = new Date(ExpiredDate);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();

    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);

    const hours = Math.floor(Total / (1000 * 60 * 60));
    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    setHour(hours);
  };
  const StartdateTime=(load_start_date) =>{return load_start_date?.split("T").join().split(".")?.[0]}
  const EnddateTime=(load_end_date) =>{return load_end_date?.split("T").join().split(".")?.[0]}
  const ExpirationTime=(expiration_date) =>{return expiration_date?.split("T").join().split(".")?.[0]}
  const DepartureTime = data?.departure_time?.split('T').join().split('.')?.[0];


  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      StartTimer();
      Timeleft();
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  useEffect(() => {
    Timeleft();

    let UpdatedDate = new Date(ExpiredDate);
    setDate(UpdatedDate);
  }, [data]);

  useEffect(() => {
    if (id && user?.id) {
      GetLoad();
    }
  }, [user]);

  return (
    <>
      <Layout2 title2="Loads">
        {BidStatus ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <img
              style={{ borderRadius: '50%' }}
              src="https://cdn.dribbble.com/users/253392/screenshots/6906291/check.gif"
              alt=""
            />
          </div>
        ) : (
          <div className="mainareas">
            <div className="pprofile1 edit-page-common edit-page-commons ">
              <div className="row">
                <div className="col-lg-12">
                  <div className=" title-head mb-0">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h4 className="viewUsers mb-0 user-back">
                        {' '}
                        <Link to="/jobs">
                          {' '}
                          <i
                            className="fa fa-arrow-left me-2  "
                            title="Back"
                            aria-hidden="true"
                          ></i>
                        </Link>
                        Load Detail
                      </h4>
                      <div className="">
                        <div className="warehouse_buttons d-flex gap-3 ">
                          {new Date().getTime >
                            new Date(data?.expiration_date).getTime() ? (
                            <>
                              <button
                                className="btn btn-danger"
                                disabled={data.isBidSubmitted}
                              >
                                Expired
                              </button>
                            </>
                          ) : (
                            <>
                              {!data?.isBidSubmitted ? (
                                <button
                                  disabled={
                                    timeRemaining == '0h 0m 0s' || Hour < 0
                                  }
                                  type="button"
                                  className="btn dark-btn dark-btn-width mt-0"
                                  onClick={() => {
                                    if (user.loggedIn) {
                                      document
                                        .getElementById('applyModalClick')
                                        .click();
                                    } else {
                                      history.push('/login');
                                    }
                                  }}
                                >
                                  Apply Now
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary disabled dark-btn-width mt-0"
                                >
                                  Applied
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="common-padding">
                    {!loader && (
                      <div className="d-flex justify-content-between ">
                        {LeftDays < 1 && Hour >= 0 ? (
                          <div className="d-flex gap-4 align-items-center hours-left">
                            {timeRemaining && (
                              <span className="d-flex align-items-center">
                                <FaRegClock className="me-2" />
                                Hours Left:
                              </span>
                            )}

                            {timeRemaining && (
                              <span className="">{timeRemaining}</span>
                            )}
                          </div>
                        ) : null}
                        {timeRemaining == '0h 0m 0s' ||
                          (Hour < 0 && (
                            <div className="expired-job">
                              <FaCircleExclamation />

                              <span>Expired</span>
                            </div>
                          ))}
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row mb-3">
                          <div className="col-xl-12 col-lg-12 height-set">
                            <div className=" white-bg-main mb-4 ">
                              <div className=" white-head-inner ">
                                <div className="bids-upp-m">
                                  <div className="loc-flex">
                                    <img src="/assets/img/origin.svg" />
                                    <h5 className="">
                                      {originaddress?.city || data?.origin_location_city}
                                      <span>Origin City</span>
                                    </h5>
                                  </div>

                                  <div className="loc-flex">
                                    <img src="/assets/img/destination.svg" />
                                    <h5>
                                      {destinationaddress?.city || data?.destination_location_city}
                                      <span>Destination City</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">

                                    {data?.origin_nass && <div className="col-md-12 mb-3 view-flex">

                                      <label className="profileheddingcls width-set">
                                        Origin NASS :
                                      </label>
                                      <div className="profiledetailscls address-w">
                                        {methodModel.capitalizeFirstLetter(
                                          data?.origin_nass
                                        )}
                                      </div>
                                    </div>}

                                    {data?.origin_name && <div className="col-md-12 mb-3 view-flex">

                                      <label className="profileheddingcls width-set">
                                        Origin Name :
                                      </label>
                                      <div className="profiledetailscls address-w">
                                        {methodModel.capitalizeFirstLetter(
                                          data?.origin_name
                                        )}
                                      </div>
                                    </div>}


                                    <div className="col-md-12 mb-3 view-flex">
                                      <label className="profileheddingcls width-set">
                                        State :
                                      </label>
                                      <div className="profiledetailscls">
                                        {methodModel.capitalizeFirstLetter(
                                          originaddress?.state || data?.origin_location_state
                                        ) || '--'}
                                      </div>
                                    </div>
                                    {data?.origin_location_postal_code ? (
                                      <>
                                        <div className="col-md-12 mb-3 view-flex">
                                          <label className="profileheddingcls width-set">
                                            Postal Code :
                                          </label>
                                          <div className="profiledetailscls">
                                            {methodModel.capitalizeFirstLetter(
                                              data?.origin_location_postal_code
                                            ) || '--'}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {originaddress?.country || data?.origin_location_country ? (
                                      <>
                                        <div className="col-md-12 mb-3 view-flex">
                                          <label className="profileheddingcls width-set">
                                            Country :
                                          </label>
                                          <div className="profiledetailscls">
                                            {methodModel.capitalizeFirstLetter(
                                              originaddress?.country || data?.origin_location_country
                                            ) || '--'}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                        <div className="col-md-12 mb-3 view-flex">
                                      <label className="profileheddingcls width-set">
                                        Address :
                                      </label>
                                      <div className="profiledetailscls address-w">
                                        {methodModel.capitalizeFirstLetter(
                                          data && data?.origin_address
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">

                                    {data?.destination_nass && <div className="col-md-12 mb-3 view-flex">

                                      <label className="profileheddingcls width-set">
                                        Destination NASS :
                                      </label>
                                      <div className="profiledetailscls address-w">
                                        {methodModel.capitalizeFirstLetter(
                                          data?.destination_nass
                                        )}
                                      </div>
                                    </div>}

                                    {data?.destination_name && <div className="col-md-12 mb-3 view-flex">

                                      <label className="profileheddingcls width-set">
                                        Destination Name :
                                      </label>
                                      <div className="profiledetailscls address-w">
                                        {methodModel.capitalizeFirstLetter(
                                          data?.destination_name
                                        )}
                                      </div>
                                    </div>}


                                    <div className="col-md-12 mb-3 view-flex">
                                      <label className="profileheddingcls width-set">
                                        State :
                                      </label>
                                      <div className="profiledetailscls">
                                        {methodModel.capitalizeFirstLetter(
                                          destinationaddress?.state || data?.destination_location_state
                                        ) || '--'}
                                      </div>
                                    </div>
                                    {data?.destination_location_postal_code ? (
                                      <>
                                        <div className="col-md-12 mb-3 view-flex">
                                          <label className="profileheddingcls width-set">
                                            Postal Code :
                                          </label>
                                          <div className="profiledetailscls">
                                            {methodModel.capitalizeFirstLetter(
                                              data?.destination_location_postal_code
                                            ) || '--'}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {destinationaddress?.country || data?.destination_location_country ? (
                                      <>
                                        <div className="col-md-12 mb-3 view-flex">
                                          <label className="profileheddingcls width-set">
                                            Country :
                                          </label>
                                          <div className="profiledetailscls">
                                            {methodModel.capitalizeFirstLetter(
                                              destinationaddress?.country || data?.destination_location_country
                                            ) || '--'}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                     <div className="col-md-12 mb-3 view-flex">

<label className="profileheddingcls width-set">
  Address :
</label>
<div className="profiledetailscls address-w">
  {methodModel.capitalizeFirstLetter(
    data?.destination_address
  )}
</div>
</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12  height-set">
                            <div className=" white-bg-main mb-4">
                              <div className=" white-head mb-3">
                                <h5 class="profilelist">Basic Information</h5>
                              </div>
                              <div className="row">
                                {
                                  data?.lineTreavel &&
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Line Treavel:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.lineTreavel}
                                    </div>
                                  </div>
                                }
                                {data?.total_distance ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Distance (in Mi):
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.total_distance}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {data?.load_id ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Load ID:
                                    </label>
                                    <div className="profiledetailscls">
                                      <div className="badge badge-primary">
                                        {data?.load_id}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {data?.lane_id ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Lane ID:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.lane_id}
                                    </div>
                                  </div>
                                ) : null}

                                {data?.all_in_rate ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      All in Rate:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.all_in_rate}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {data?.capacity ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Capacity:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.capacity}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {data?.annual_miles ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Annual Miles:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.annual_miles}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {data?.miles ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Miles:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.miles}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {data?.avg_mph ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Avg MPH:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.avg_mph}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {data?.arrive_destination ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Arrive Destination:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.arrive_destination}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {data?.arrive_destinationDayOffset ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Arrive Destination DayOffset:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.arrive_destinationDayOffset}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}


                                {data?.dispatch_days ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Dispatch Days:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.dispatch_days}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {data?.est_volume ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      EST Volume:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.est_volume}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {data?.frequency ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Frequency:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.frequency}
                                    </div>
                                  </div>
                                ) : null}

                                {data?.perpetual_frequency ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Perpetual Frequency:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.perpetual_frequency}
                                    </div>
                                  </div>
                                ) : null}
                                {data?.travel_time ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Travel Time Allotted:
                                    </label>
                                    <div className="profiledetailscls">
                                      {data?.travel_time}
                                    </div>
                                  </div>
                                ) : null}


                                {LeftDays > 1 && (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Day Left:
                                    </label>
                                    <div className="profiledetailscls">
                                      {LeftDays}
                                    </div>
                                  </div>
                                )}
                                {data?.load_start_date != null ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Load Start Date:
                                    </label>
                                    <div className="profiledetailscls">
                                      {moment(StartdateTime(data?.load_start_date)).format(
                                        'DD-MMM-YYYY'
                                      )}, {''}
                                      {datepipeModel.isotime(
                                        data?.load_start_date
                                      )}
                                    </div>
                                  </div>
                                ) : null}

                                {data?.load_end_date ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Load End Date:
                                    </label>
                                    <div className="profiledetailscls">
                                      {moment(EnddateTime(data?.load_end_date)).format(
                                        'DD-MMM-YYYY '
                                      )}, {''}
                                      {datepipeModel.isotime(
                                        data?.load_end_date
                                      )}
                                    </div>
                                  </div>
                                ) : null}

                                {data?.expiration_date ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Expiration Date:
                                    </label>
                                    <div className="profiledetailscls">

                                      {moment(ExpirationTime(data?.expiration_date)).format(
                                        "DD-MMM-YYYY"
                                      )}
                                      , {''}
                                          {datepipeModel.isotime(
                                            data?.expiration_date
                                          )}
                                    </div>
                                  </div>
                                ) : null}
                                {data?.departure_time ? (
                                  <div className="col-md-6 mb-3 view-flex">
                                    <label className="profileheddingcls width-set">
                                      Departure Time:
                                    </label>
                                    <div className="profiledetailscls">

                                      {moment(DepartureTime).format(
                                        "DD-MMM-YYYY"
                                      )}, {''}
                                      {datepipeModel.isotime(
                                        data?.departure_time
                                      )}
                                    </div>
                                  </div>
                                ) : null}

                              </div>
                            </div>
                          </div>
                          {data?.notes || data?.frequency_description ? (
                            <div className="col-xl-12 height-set">
                              <div className=" white-bg-main mb-4">
                                {data?.notes && <> <div className=" white-head mb-3">
                                  <h5 class="profilelist">Notes</h5>
                                </div>

                                  <div className='row'>
                                    <div className="col-md-12 mb-3 view-flex">
                                      <div className="profiledetailscls">
                                        {methodModel.capitalizeFirstLetter(
                                          data?.notes
                                        ) || '--'}
                                      </div>
                                    </div>
                                  </div></>}
                                {data?.frequency_description && <> <div className=" white-head mb-3">
                                  <h5 class="profilelist">Frequency Descrition</h5>
                                </div>

                                  <div className='row'>
                                    <div className="col-md-12 mb-3 view-flex">
                                      <div className="profiledetailscls">
                                        {methodModel.capitalizeFirstLetter(
                                          data?.frequency_description
                                        ) || '--'}
                                      </div>
                                    </div>
                                  </div></>}


                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {data?.comments ? (
                            <div className="col-xl-12 height-set">
                              <div className=" white-bg-main mb-4">
                                <div className=" white-head mb-3">
                                  <h5 class="profilelist">Comments</h5>
                                </div>

                                <div className='row'>
                                  <div className="col-md-12 mb-3 view-flex">
                                    <div className="profiledetailscls">
                                      {methodModel.capitalizeFirstLetter(
                                        data?.comments
                                      ) || '--'}
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-xl-12 col-lg-12 height-set">
                            <div
                              className={`${data?.stops?.length > 1
                                ? 'col-lg-12 height-set'
                                : 'col-lg-12 '
                                }`}
                            >
                              {data?.stops?.length > 0 && <div className=" white-bg-main mb-4 ">
                                <div className=" white-head mb-3">
                                  <h5 class="profilelist">Stop Details</h5>
                                </div>
                                <div className="table-responsive table_section mt-0 p-3">
                                  <table className="table">
                                    <thead>
                                      <th>Stop No.</th>
                                      {data?.board_id?.id == environment?.contractId && <> <th>Stop NASS</th>
                                        <th>Stop Name</th></>}

                                      {data?.stops?.find((item, index) => item?.location_id) ? <th>Location Id</th> : <></>}
                                      <th>Address</th>
                                      {data?.board_id?.id == environment?.contractId && <> <th>Stop City</th>
                                        <th>Stop State</th></>}

                                    </thead>
                                    <tbody>
                                      {data?.stops?.map((itm, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            {data?.board_id?.id == environment?.contractId && <>   <td>{itm?.stop_nass || "--"}</td>
                                              <td>{itm?.stop_name || "--"}</td></>}

                                            {itm?.location_id ? <td>{itm?.location_id}</td> : <></>}
                                            <td>
                                              {' '}
                                              {/* <ReadMore
                                                content={methodModel.capitalizeFirstLetter(
                                                  itm?.address
                                                )}
                                                length={60}
                                              /> */}
                                              {itm?.address}
                                            </td>
                                            {data?.board_id?.id == environment?.contractId && <> <td>{itm?.stop_city || "--"}</td>
                                              <td>{itm?.stop_state || "--"}</td></>}

                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              }
                              {data?.arriveStops?.length > 0 && <div className=" white-bg-main mb-4 ">
                                <div className="white-head mb-3">
                                  <h5 class="profilelist">Arrive Stop Details</h5>
                                </div>
                                <div className="table-responsive table_section mt-0 p-3">
                                  <table className="table">
                                    <thead>
                                      <th>Arrive Stop No.</th>
                                      <th>Arrive Stop</th>
                                      <th>Arrive Day OffSet</th>

                                    </thead>
                                    <tbody>
                                      {data?.arriveStops?.map((itm, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{itm?.arrive_stop || "--"}</td>
                                            <td>{itm?.arrive_DayOffset || "--"}</td>

                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>}

                            </div>
                          </div>
                        </div>
                      </div>
                      <ApplyModal
                        data={data}
                        result={(e) => {
                          if (e.event == 'submit') {
                            setBidStatus(true);

                            setTimeout(() => {
                              setBidStatus(false);
                              GetLoad();
                            }, 2000);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout2>
    </>
  );
}

export default JobView2;
