import React, { useEffect, useState } from 'react';
import Layout2 from '../../components/global/Layout2';
import Pagination from 'react-pagination-js';
// import "./style.scss";
import rolesModel from '../../models/roles.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { useHistory } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Switch from 'react-switch';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import ApiClient from '../../methods/api/apiClient';
import { Tooltip } from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import loader from '../../methods/loader';
const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  // console.log(user,"useruseruseruser")
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState('');
  const [Max_rate, setMax_rate] = useState('');
  const [DeleteId, setDeleteId] = useState('');
  const latestSliderValue = React.useRef([0, 0]);
  const [idd, setuserid] = useState('');

  const [form, setform] = useState({
    updated_password: '',
    confirmPassword: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [eyes, setEyes] = useState({
    updated_password: false,
    confirmPassword: false,
  });
  const formValidation = [
    {
      key: 'confirmPassword',
      minLength: 8,
      confirmMatch: ['confirmPassword', 'updated_password'],
    },
    { key: 'currentPassword', minLength: 8 },
    { key: 'updated_password', minLength: 8 },
  ];
  const getError = (key) => {
    return methodModel.getError(key, form, formValidation);
  };
  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);
    // console.log("Filter changed. Calling GetAllprojects...");
    getData({ min_rate: min, max_rate: max });
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const setPriceFilter = () => {
    setFilter({ ...filters, min_rate: Min_rate, max_rate: Max_rate });
    getData({ min_rate: Min_rate, max_rate: Max_rate });
  };

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem('permission'));
  const Role = [
    {
      key: 'staff',
      name: 'Staff',
    },
    {
      key: 'carrier',
      name: 'Carrier',
    },
  ];
  let ListingData = [];
  if (user?.role == 'staff') {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };
  const setid = (userId) => {
    setform({
      updated_password: '',
      confirmPassword: '',
    });
    setuserid(userId);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;

    loader(true);
    ApiClient.put('admin/edit-user', {
      id: idd,
      updated_password: form?.updated_password,
    }).then((res) => {
      if (res.success) {
        // toast.success('Password Updated Successfully ');
        setform({
          updated_password: '',
          confirmPassword: '',
        });
        document.getElementById('closeReset').click();
      }
      loader(false);
    });
  };

  return (
    <Layout2 title="Carriers" title2="Approved Carriers">
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="mainareas">
        <div className="staff-full">
          <div className="d-flex justify-content-between align-items-center mb-4 wrapflex">
            <div className="bids-top">
              <h5 className="mb-0">
                Results <span>{total}</span>
              </h5>
            </div>

            <div className=" d-flex gap-2 align-items-center">
              <div className="searchParent ">
                <input
                  className="form-control "
                  placeholder="Search..."
                  type="search"
                  value={filters.search}
                  name="search"
                  onChange={(e) => Handlefilter(e)}
                />
                <span class="material-icons">search</span>
              </div>
              <div className="dropdown addDropdown chnagesg ms-0 p-0 borer-c d-flex align-items-center">
                <button
                  className="btn blck-border-btn dropdown-toggle removeBg"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters.status
                    ? filters.status == 'deactive'
                      ? 'Inactive'
                      : filters.status
                    : 'All Status'}
                </button>

                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className={
                      filters.status == ''
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('')}
                  >
                    All Status
                  </a>
                  <a
                    className={
                      filters.status == 'active'
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('active')}
                  >
                    Active
                  </a>
                  <a
                    className={
                      filters.status == 'Inactive'
                        ? 'dropdown-item active'
                        : 'dropdown-item'
                    }
                    onClick={() => ChangeStatus('deactive')}
                  >
                    Inactive
                  </a>
                </div>
              </div>
              {filters.status || filters.role ? (
                <>
                  <button
                    className="btn btn-primary undo  ms-2"
                    onClick={(e) => reset()}
                  >
                    <i class="fa fa-undo me-2" aria-hidden="true"></i>
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <>
            <div>


              <div className="table-responsive table_section job-sect-table new-table">
                <table class="table ">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data pointer">
                        User Name
                      </th>
                      {/* <th scope="col" className="table_data pointer">
                        Company Name
                      </th> */}
                      <th scope="col" className="table_data pointer">
                        Email
                      </th>

                      <th scope="col" className="table_data pointer">
                        Date Created
                      </th>

                      <th scope="col" className="table_data pointer">
                        Status
                      </th>
                      <th scope="col" className="table_data pointer">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loaging &&
                      ListingData &&
                      ListingData.map((itm, i) => {
                        return (
                          <tr>
                            <td>
                              {itm.fullName
                                ? methodModel.capitalizeFirstLetter(
                                    itm?.fullName
                                  )
                                : methodModel.capitalizeFirstLetter(
                                    itm?.firstName
                                  )}
                            </td>
                            {/* <td>{itm?.compnay_name}</td> */}
                            <td>{itm?.email}</td>
                            <td>{datepipeModel.date(itm.createdAt) || '--'}</td>

                            <td>
                              <div className={`${itm?.status}`}>
                                <span className="custom-toggle-btn heightbtn mt-0">
                                  {itm?.status == 'deactive' ? (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={false}
                                    />
                                  ) : (
                                    <Switch
                                      onChange={(e) => {
                                        setStatusData(itm);
                                        setShowActiveModal('block');
                                      }}
                                      checked={true}
                                    />
                                  )}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex align-items-center action-btns ">
                                <a
                                  class="  me-2"
                                  onClick={() => {
                                    Navigate.push(
                                      `/users/detail/${itm.id}?Name=Approved Carriers`
                                    );
                                  }}
                                >
                                  <span className="">
                                    <i class="fa fa-eye"></i>
                                  </span>{' '}
                                </a>

                                <a
                                  onClick={() => {
                                    Navigate.push(
                                      `/users/edit/${itm?.id}?Name=Approved Carriers`
                                    );
                                  }}
                                  class=" me-2 "
                                >
                                  <span className="">
                                    <i class="material-icons edit editButton">
                                      {' '}
                                      edit
                                    </i>
                                  </span>{' '}
                                </a>

                                {user?.position == 'dispatcher' ? (
                                  <></>
                                ) : (
                                  <a
                                    class="  "
                                    data-bs-toggle="modal"
                                    data-bs-target="#resets"
                                    onClick={() => setid(itm?.id)}
                                  >
                                    <span className="">
                                      <i class="material-icons edit editButton">
                                        {' '}
                                        restart_alt
                                      </i>
                                    </span>{' '}
                                  </a>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>

          {!loaging && total == 0 ? (
            <div className=" no-data">
              <img src="/assets/img/no-data.png" />
              No Data
            </div>
          ) : (
            <></>
          )}

          {!loaging && total > 0 ? (
            <div className="paginationWrapper main-pagination page-new">
              <div className="d-flex align-items-center">
                <div className="me-2 user-name-color">Show</div>
                {total > 0 && (
                  <div className="dropdown addDropdown chnagesname ">
                    <select
                      className="form-control"
                      value={filters?.count}
                      onChange={(e) => {
                        setFilter({ ...filters, count: e.target.value });
                        getData({ ...filters, count: e.target.value });
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="30">30</option>
                    </select>
                    <div
                      className="dropdown-menu shadow bg_hover"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 5 });
                        }}
                      >
                        5
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 10 });
                        }}
                      >
                        10
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 20 });
                        }}
                      >
                        20
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 30 });
                        }}
                      >
                        30
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 40 });
                        }}
                      >
                        40
                      </a>
                    </div>
                  </div>
                )}{' '}
                <div className="ms-2 user-name-color">
                  from {total} Approved Carriers
                </div>
              </div>

              <div className="">
                <Pagination
                  currentPage={filters.page}
                  totalSize={total}
                  sizePerPage={filters.count}
                  changeCurrentPage={pageChange}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {loaging ? (
            <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        class="modal fade"
        id="resets"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Reset Password
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeReset"
              ></button>
            </div>
            <form className="w-100" onSubmit={handleSubmit}>
              <div class="modal-body">
                <div className="mb-3">
                  <label className="mb-2">
                    Updated Password <span className="text-danger">*</span>
                  </label>
                  <div className="inputWrapper">
                    <input
                      className="form-control"
                      type={eyes.updated_password ? 'text' : 'password'}
                      maxLength="20"
                      value={form.updated_password}
                      onChange={(e) =>
                        setform({
                          ...form,
                          updated_password: e.target.value,
                        })
                      }
                      required
                    />
                    <i
                      className={
                        eyes.updated_password ? 'fa fa-eye' : 'fa fa-eye-slash'
                      }
                      onClick={() =>
                        setEyes({
                          ...eyes,
                          updated_password: !eyes.updated_password,
                        })
                      }
                    ></i>
                  </div>
                  {submitted && getError('updated_password').invalid ? (
                    <div className="invalid-feedback d-block">
                      Min Length must be 8 characters long
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="">
                  <label className="mb-2">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <div className="inputWrapper">
                    <input
                      className="form-control"
                      type={eyes.confirmPassword ? 'text' : 'password'}
                      maxLength="20"
                      value={form.confirmPassword}
                      onChange={(e) =>
                        setform({
                          ...form,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                    <i
                      className={
                        eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                      }
                      onClick={() =>
                        setEyes({
                          ...eyes,
                          confirmPassword: !eyes.confirmPassword,
                        })
                      }
                    ></i>
                  </div>
                  {submitted && getError('confirmPassword').invalid ? (
                    <>
                      {/* {getError('confirmPassword').err.minLength ? <div>Min Length must be 8 characters long</div> : <></>} */}
                      {getError('confirmPassword').err.confirmMatch ? (
                        <div className="invalid-feedback d-block">
                          Confirm Password is not matched with New Password
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" className="btn dark-btn mt-4">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default Html;
