import React, { useEffect, useState } from 'react';
import GooglePlaceAutoComplete from '../../../components/common/GooglePlaceAutoComplete';
import Layout2 from '../../../components/global/Layout2';
import 'react-datetime/css/react-datetime.css';
import SelectDropdown from '../../../components/common/SelectDropdown';
import dateFormat from 'dateformat';
import Datetime from 'react-datetime';
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import environment from '../../../environment';
import ApiClient from '../../../methods/api/apiClient';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import loader from '../../../methods/loader';
import addressModel from '../../../models/address.model';
import PhoneInput from 'react-phone-input-2';
import methodModel from '../../../methods/methods';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { IoSearch } from "react-icons/io5";
import ImageUpload from "../../../components/common/ImageUpload";

const Html = ({
  form,
  setVin,
  Vin,
  licence,
  setLicence,
  licenceLoader,
  handleSubmit,
  emailvalidation,
  setform,
  addressResult,
  submitted,
  detail,
}) => {

  const user = useSelector((state) => state.user);
  // const [Document, setdocuments] = useState([]);
  const [boards, setBoards] = useState([]);
  const { id } = useParams();

  const [filter, setfilter] = useState({});
  const [isSearch, setisSearch] = useState(false);
  const [Trucks, SetTruck] = useState([]);
  // useEffect(() => {
  //   setVin(form?.truck_details?.vin_number)
  // }, [form]);

  const GetTruck = (e) => {
    if (e?.search) {
      setisSearch(true);
    } else {
      setisSearch(false);
    }
    let addedBy
    if(user?.role=="carrier")
      {
        const userId=user?.userIds.join(",")
        addedBy=userId;
      }else if(user?.role=="user" && user?.isInvited==true)
      {
        const userId=user?.userIds.join(",")
        addedBy=userId;
      }
      else
      {
        addedBy=`${user?.addedBy},${user?.id || user?._id}`;
      }
    ApiClient.get('trucks', { ...e, addedBy: addedBy}).then((res) => {
      if (res.success) {
        SetTruck(res?.data?.data);
        // setVin()
      }
    });
  };
  // useEffect(() => {

  //   let fltr = Trucks?.filter((itm) => itm?.truck_id == form?.truck_id);

  //   if (fltr) {
  //     setVin(fltr[0]?.vin_number);

  //   }
  // }, [Trucks, form]);

  useEffect(() => {
    GetTruck();
    ApiClient.get('boards').then((res) => {
      setBoards(res?.data?.data);
    });
  }, []);

  const DestinationAddress = async (e) => {

    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      address: e.value || '',
      city: address.city || '',
      state: address.state || '',
      country: address.country || '',
      pincode: address.zipcode || '',
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  };

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);
  }, []);



  return (
    <>
      <Layout2 title2="Drivers">
        <div className="mainareas">
          <div className="pprofile1  edit-page-common new-edit">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head mb-0">
                  <h4 className="viewUsers mb-0 user-back">
                    <Link to="/drivers" className="">
                      <i
                        className="fa fa-arrow-left me-2 text-black"
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    {form && form.id ? 'Edit' : 'Add'} Driver
                  </h4>
                </div>
              </div>
            </div>

            <div className="main-row common-padding ">
              <form onSubmit={e=>{e.preventDefault();handleSubmit()}}>
                <div className="row ">
                  <div className="col-xl-9">
                    <div className="row">
                      <div className="col-lg-6 height-set">
                        <div class=" white-head mb-3">
                          <h5 class="profilelist">Basic Information</h5>
                        </div>
                        <div class=" white-bg-main mb-4">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label>
                                First Name
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i className="fa fa-user"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.firstName}
                                  required
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      firstName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {submitted && !form.firstName ? (
                                <div className="invalid-feedback d-block">
                                  First Name is Required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label>Last Name</label>

                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i className="fa fa-user"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.lastName}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      lastName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label>
                                Email <span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i class="fas fa-envelope"></i>
                                </div>
                                <input
                                  type="email"
                                  className="form-control"
                                  // required
                                  disabled={id}
                                  value={form.email}
                                  onChange={(e) =>
                                    setform({ ...form, email: e.target.value })
                                  }
                                />
                              </div>

                              {submitted && !form.email ?  (
                                <div className="invalid-feedback d-block">
                                  Email is Required
                                </div>
                              )
                              :<></>
                            }

                            </div>
                            <div class="col-lg-12">
                              <div className="row">
                              <div className="col-md-6 mb-3">
                                  <label className="">Mobile No</label>
                                  <div className="phoneInput_cls d-flex form-control p-0">
                                    <PhoneInput
                                      country={'us'}
                                      value={form?.dialCode}
                                      countryCodeEditable={false}
                                      enableSearch={true}
                                      placeholder=""
                                      onChange={(phone, country) => {
                                        setform({
                                          ...form,
                                          dialCode: country.dialCode,
                                        });
                                      }}
                                    />

                                    <input
                                      type="text"
                                      className="form-control phph"
                                      placeholder="Mobile No."
                                      value={(form && form.mobileNo) || ''}
                                      maxLength={12}
                                      onChange={(e) =>
                                        setform({
                                          ...form,
                                          mobileNo: methodModel.isNumber(e),
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label> License Number <span className="text-danger">*</span></label>

                                  <div className="input-new-design">
                                    <input
                                      type="text"
                                      pattern="^[a-zA-Z0-9]+$"
                                      onKeyPress={(e) => {
                                        var regex = new RegExp(
                                          '^[a-zA-Z0-9]+$'
                                        );
                                        var key = String.fromCharCode(
                                          !e.charCode ? e.which : e.charCode
                                        );
                                        if (!regex.test(key)) {
                                          e.preventDefault();
                                          return false;
                                        }
                                      }}
                                      className="form-control"
                                      required
                                      value={form.licence_number}
                                      onChange={(e) =>
                                        setform({
                                          ...form,
                                          licence_number: e.target.value,
                                        })
                                      }
                                    />
                                  </div>

                                  {submitted && !form.licence_number ? (
                                    <div className="invalid-feedback d-block">
                                      licence number is Required
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label> License Upload <span className="text-danger">*</span></label>

                               <div className='d-block license-upload'>
                               <ImageUpload
                                    idd="LicenceImage"
                                    value={form?.license_image}
                                    // multiple={true}
                                    result={(e) => {
                                      setform({...form,license_image:e.value})
                                    }}
                                  />
                                  {submitted && !form.license_image ? (
                                    <div className="invalid-feedback d-block">
                                      license Image is Required
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                               </div>
                                </div>



                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 height-set">
                        <div class=" white-head mb-3">
                          <h5 class="profilelist">Address</h5>
                        </div>
                        <div class=" white-bg-main mb-4">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label>
                                Address<span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design addressInput">
                                <div className="input-icon">
                                  <i class="fas fa-address-book"></i>
                                </div>
                                <GooglePlaceAutoComplete
                                  key="Map2"
                                  value={form?.address}
                                  result={DestinationAddress}
                                  id="address"
                                  placeholder=""
                                />
                              </div>

                              {submitted && !form.address ? (
                                <div className="invalid-feedback d-block">
                                  Address is Required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label>
                                City<span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i class="fas fa-building"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={form.city}
                                  onChange={(e) =>
                                    setform({ ...form, city: e.target.value })
                                  }
                                />
                              </div>

                              {submitted && !form.city ? (
                                <div className="invalid-feedback d-block">
                                  City is Required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label>
                                State<span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i class="fas fa-building"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={form.state}
                                  onChange={(e) =>
                                    setform({ ...form, state: e.target.value })
                                  }
                                />
                              </div>

                              {submitted && !form.state ? (
                                <div className="invalid-feedback d-block">
                                  State is Required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-md-6 mb-3">
                              <label>
                                Country<span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i class="fas fa-globe-asia"></i>
                                </div>
                                <input
                                  type="text"
                                  required
                                  className="form-control"
                                  value={form.country}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      country: e.target.value,
                                    })
                                  }
                                />
                              </div>

                              {submitted && !form.country ? (
                                <div className="invalid-feedback d-block">
                                  Country is Required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="col-md-6 mb-3">
                              <label>
                                Zipcode<span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i class="fas fa-map-pin"></i>
                                </div>
                                <input
                                  type="numberic"
                                  className="form-control"
                                  value={form.pincode}
                                  required
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      pincode: e.target.value,
                                    })
                                  }
                                />
                              </div>

                              {submitted && !form.pincode ? (
                                <div className="invalid-feedback d-block">
                                  Zipcode is Required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 height-set">
                    <div class=" white-head mb-3">
                      <h5 class="profilelist">Truck Details</h5>
                    </div>
                    <div class=" white-bg-main mb-4">
                      <div className="row search-truck">
                        <div className="col-md-12 ">
                          <img src="/assets/img/fast.svg" className='mb-3' />

                          <div className="input-new-design position-relative">
                            <div className="input-icon">
                              <i className='fa fa-search'></i>
                            </div>
                            {' '}


                            <input
                              placeholder="Search Trucks"
                              value={filter?.search}
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                GetTruck({ search: e.target.value });
                                setfilter({
                                  ...filter,
                                  search: e.target.value,
                                });
                              }}
                            />
                            {isSearch && (
                              <>
                                {Trucks &&
                                Trucks?.map((itm) => {
                                  if(itm)
                                  return ( <div className="dropspdiv dropsdivTwo">
                                      <span
                                        className="dropspans"
                                        onClick={() => {
                                          setform({
                                            ...form,
                                            truck_id: itm?.id,
                                            vin_number: itm?.vin_number,
                                          });
                                          setVin(itm?.vin_number);
                                          setisSearch(false);
                                          setfilter({ search: '' });
                                        }}
                                      >
                                        {itm?.truck_number}
                                      </span>


                              </div>  );
                              else
                              return <></>
                               })}
                              </>

                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label>
                            Truck
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-new-design">
                            <select
                              required
                              className="form-control"
                              onChange={(e) => {
                                setform({
                                  ...form,
                                  truck_id: e.target.value,
                                  vin_number: e.target.id,
                                });
                                let fltr = Trucks?.filter(
                                  (itm) => itm?.id == e.target.value
                                );
                                setVin(e.target.value==""?"":fltr[0]?.vin_number);
                              }}
                              value={form?.truck_id}
                            >
                              <option value="" id="">Select Truck</option>
                              {Trucks?.map((itm) => {
                                return (
                                  <option value={itm.id} id={itm?.vin_number}>
                                    {itm?.truck_number}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {submitted && !form.firstName ? (
                            <div className="invalid-feedback d-block">
                              Truck is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label>VIN Number</label>

                          <div className="input-new-design">
                            <div className="input-icon">
                              <i className="fa fa-user"></i>
                            </div>
                            <input
                              type="text"
                              required
                              disabled
                              className="form-control"
                              value={Vin}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-right pt-2 d-flex justify-content-end">
                  <button disabled={licenceLoader} type="submit" className="btn btn-primary text-end d-block btn-end-save">
                    Save {licenceLoader?<i className='fa fa-spinner fa-spin'></i>:''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openDriversModal" data-bs-target="#driversModal">
          Launch demo modal
        </button>
      </Layout2>





      <div class="modal fade driver-f" id="driversModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="d-flex justify-content-end p-2">
              {/* <h5 class="modal-title" id="exampleModalLabel">Drivers</h5> */}
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {licence.length ? <>
                <div className="col-md-12 mb-3">
                  <div className='font-weight-bold text-danger mb-3 text-center'>
                    <img src = "/assets/img/driver-fail.png" />
                  This driver was previously affiliated with other companies. Here is the driver's score and company information. If you wish to proceed, please click "Yes".
                    {/* There is {licence.length} profile{licence.length > 1 ? 's' : ''} associated with this License Number:
                    <span className='d-block modLicenceSpan'>{form.licence_number}</span>
                    <span>If you wish to proceed, please click "Yes".</span> */}
                  </div>
                 <div className='d-flex'>
                 {licence.map((itm, i) => {
                  <div className='driver-fail'>

                      return <div className='p-2  me-2' key={i}>
                        <h5>{itm.company_name}</h5>
                        <i className='fa fa-star'></i> {itm.avg_rating.toFixed(1)} ({itm.total_reviews})
                      </div>

                  </div>
                 })}
                  </div>
                </div>
              </> : <>

              </>}
              <div class="d-flex align-items-center justify-content-center">
              <button type="button" class="btn btn-secondary" id="closeDriverModal" data-bs-dismiss="modal">No</button>
              <button type="button" class="btn btn-primary ms-2" onClick={() => handleSubmit(true)}>Yes</button>
            </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default Html;
