import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useHistory, useParams } from "react-router-dom";
import environment from "../../../environment";
import { toast } from "react-toastify";
import addressModel from "../../../models/address.model";

const AddEditTruck = () => {
  const { role, id } = useParams();
  const [images, setImages] = useState({ image: "", logo: "" });
  const defaultvalue = userType;
  const [form, setform] = useState({
    truck_data: [],
  });

  const [set, setState] = useState();
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: "mobileNo", minLength: 10 },
    { key: "role", required: true },
    { key: "ic_number", minLength: 6 },
    { key: "password", minLength: 8 },
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "password"],
    },
  ];

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (form?.truck_data?.length < 1) {
      return false;
    }

    const LoadID = Math.floor(Math.random() * 1000000000);
    let method = "post";
    let url = "truck";
    let value = {
      ...form,
      truck_data: form?.truck_data,
    };
    // value.fullName=value.firstName+" "+value.lastName
    if (value.id) {
      method = "put";
      url = "truck";
      value = {
        truck_data: form?.truck_data,
      };
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        // toast.success(res.message);

        if (localStorage.getItem("newuser", true) && !value?.id) {
          history.push("/drivers1/add");

        } else {

          history.push("/trucks1");
        }

      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);

  };
  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      origin_address: e.value,
      origin_location_country: address.country || "",
      origin_location_city: address.city || "",
      origin_location_state: address.state || "",
      origin_location_postal_code: address.zipcode || "",
      origin_lat: `${address.lat}` || "",
      origin_lng: `${address.lng}` || "",
    });
  };

  const back = () => {
    history.goBack();
  };

  const getRoles = () => {
    ApiClient.get("roles/listing", { status: "active" }).then((res) => {
      if (res.success) {
        let newarray = [];
        res.data &&
          res.data.map((item, index) => {
            if (item.id != environment.adminRoleId) {
              newarray.push(item);
            }
          });
        setRoles(newarray);
      }
    });
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const GetLoadDetails = () => {
    ApiClient.get("load", { id }).then((res) => {
      if (res.success) {
        setform(res?.data);
      }
    });
  };
  useEffect(() => {
    setSubmitted(false);
    // setState()
    if (id) {
      GetLoadDetails();
    }
    // if (id) {
    //     loader(true)
    //     ApiClient.get("user/profile", { id }).then(res => {
    //         if (res.success) {
    //             let value=res.data
    //             setDetail(value)
    //             let payload = defaultvalue
    //             let oarr = Object.keys(defaultvalue)
    //             oarr.map(itm => {
    //                 payload[itm] = value[itm] || ''
    //             })
    //             payload.dialCode=value.dialCode
    //             payload.role=value.role._id
    //             payload.merchant_fee=value.merchant_fee
    //             payload.status=value.status
    //             payload.firstName=value.firstName
    //             payload.lastName=value.lastName
    //             payload.companyName=value.companyName
    //             setform({ ...payload, })
    //             images.image = payload?.image
    //             images.logo = payload?.logo
    //             setImages(images)
    //         }
    //         loader(false)
    //     })
    // }
    // getRoles()
  }, [id]);

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        roles={roles}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
      // DestinationAddress={DestinationAddress}
      />
    </>
  );
};

export default AddEditTruck;
