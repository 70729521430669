import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout2 from '../../../components/global/Layout2';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
// import "./style.scss";
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import environment from '../../../environment';
import moment from 'moment';
// import CountdownTimer from "../countdoenTimer";
import { Steps } from 'rsuite';

const LoadDetails2 = (p) => {
  // const [form,setform]=useState({resume:"",id:"",resumeDocument:""})
  // const [Comission,setComission]=useState("")
  // const [RejectionReason,setRejectionReason]=useState("");

  // const [Resume,setResume]=useState("")

  const [Stops, setStops] = useState([]);
  const [ActiveStop, setActiveStop] = useState(0);
  const [originaddress,setoriginaddress]=useState("")
  const [destinationaddress,setdestinationaddress]=useState("")
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();

  const [showItem, setShowItem] = useState({});

  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };

  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`load`, { id: did }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        console.log(newdata)
        const originaddress=res?.data?.origin_address
        const destinationaddress=res?.data?.destination_address
        setData(res.data);
        if (res?.data?.shipment_status == 'delivered') {
          setActiveStop(5);
        } else {
          setActiveStop(
            res?.data?.stops?.filter((itm) => itm?.checkin)?.length
          );
        }
        Stops.push(
          {
            address: res?.data?.origin_address,
          },
          {
            destination_address: res?.data?.destination_address,
            date: res?.data?.delivered_at,
          }
        );
        ApiClient.get(`address/detail`, { address: originaddress }).then((res) => {
          if (res.success) {
            setoriginaddress(res?.data)
                ApiClient.get(`address/detail`, { address: destinationaddress }).then((res) => {
                  if (res.success) {
                    setdestinationaddress(res?.data)

                  }
            })
          }
        })
        // if (res?.data?.role == "staff") {
        //   getPermission();
        // }
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  const edit = (id) => {
    let url = `/users1/edit/${id}`;
    if (role) url = `/users1/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };
  // useEffect(()=>{
  // GetEducation();
  // GetEmployementData();
  // },[])

  // const HandleAccpetReject=(e,status,role)=>{
  //     e.preventDefault();
  //     loader(true);
  //     const payload={isVerifiedDocument:status,id:id}
  //     ApiClient.put(`edit/profile`,payload).then(res=>{
  //         if(res.success){
  //           // toast.success(`${role} `)
  //             getDetail(id);
  //         }
  //     })
  //   }

  const [images, setimages] = useState([]);

  const [ProtofolioError, setProtofolioError] = useState(false);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = [];
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload('single/documents?modelName=document', {
          data: file,
        }).then((res) => {
          if (res.success) {

            const path = res?.data?.imagePath;
            setResume(path);
            setform({ ...form, resumeDocument: path });
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        SetPermission({
          carrier_add: res?.data?.carrier_add,
          carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
        });

        // setform({...form,permissions:res?.data})
      }
    });
  };

  const downloadFile = async (pdfUrl) => {
    try {
      const response = await fetch(`${environment.api}documents/${pdfUrl}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const downloadImage = async (fileUrl, format) => {
    try {
      const response = await fetch(`${environment.api}images/users/${fileUrl}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `document.${format}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes('_')) {
      return methodModel.capitalizeFirstLetter(str.split('_').join(' '));
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };
  const styles = {
    width: '200px',
    display: 'inline-table',
    verticalAlign: 'top',
  };
  //  For Deleting the Document
  // const HanldDocumentDelete = (e) => {
  //   setResume("")
  //   setform({...form,resumeDocument:""})
  // };
  //   const handleSubmit=(e,type="")=>{
  // e.preventDefault();
  // let payload=form;
  // if(type=="delete"){payload={resume:"",resumeDocument:"",id:form.id}}
  // loader(true);
  // ApiClient.put(`edit/profile`,payload).then(res=>{
  //     if(res.success){
  //         getDetail(form.id);
  //         toast.success(`Resume ${type=="delete"?"Deleted": "Updated"} Successfully`)
  //         document.getElementById("closeresume").click();
  //         loader(false);
  //     }
  // })
  //   }

  //    This is Method for Rejection
  // const handlerejectionSubmit=(e)=>{
  //     e.preventDefault();
  //     loader(true);
  //     const payload={isVerifiedDocument:"rejected",reason:RejectionReason,id:id}
  //     ApiClient.put(`edit/profile`,payload).then(res=>{
  //         if(res.success){
  //             document.getElementById("closerejection").click()
  //             toast.success(` ${data?.role&&data?.role?.name} Rejected Successfully`)
  //             getDetail(id);
  //         }
  //     })

  // }

  // function validateYouTubeUrl(url)
  // {
  //     // var url = $('#youTubeUrl').val();
  //         if (url != undefined || url != '') {
  //             var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  //             var match = url.match(regExp);
  //             if (match && match[2].length == 11) {
  //               return 'https://www.youtube.com/embed/' + match[2]
  //             }
  //             else {
  //               return ""
  //                 // Do anything for not being valid
  //             }
  //         }
  //         else{
  //           return false
  //         }
  // }

  // const HandleCommissionSubmit=(e)=>{
  //   e.preventDefault();
  //   loader(true);
  //   const payload={id:id,commissionPercentage:Comission}
  //   ApiClient.put(`edit/profile`,payload).then(res=>{
  //     if(res.success){
  //       toast.success("Profile updated successfully")
  //     }
  //     loader(false)
  //   })
  // }
  return (
    <Layout2 title2="Loads">
      <div className="mainareas">
        <div className="pprofile1 edit-page-common edit-page-commons ">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head mb-0">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="viewUsers mb-0 user-back">
                    {' '}
                    <a to="/users1" onClick={back}>
                      {' '}
                      <i
                        className="fa fa-arrow-left me-2  "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    Load Detail
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="common-padding">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-3">
                      <div className="col-xl-9 col-lg-8 height-set">
                        <div className=" white-bg-main mb-4 ">
                          <div className=" white-head-inner ">
                            <div className="bids-upp-m">
                              <div className="loc-flex">
                                <img src="/assets/img/origin.svg" />
                                <h5 className="">
                                  {originaddress?.city}
                                  <span>Origin City</span>
                                </h5>
                              </div>

                              <div className="loc-flex">
                                <img src="/assets/img/destination.svg" />
                                <h5>
                                  {destinationaddress?.city}
                                  <span>Destination City</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls origin-address">
                                    Origin Address
                                  </label>

                                  <div className="profiledetailscls read-more-new">
                                  {methodModel.capitalizeFirstLetter(
                                        data?.origin_address
                                      )}
                                  </div>
                                </div>

                                {/* <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    City :
                                  </label>
                                  <div className="profiledetailscls">
                                    {data && data?.origin_location_city}
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    State :
                                  </label>
                                  <div className="profiledetailscls">
                                    {methodModel.capitalizeFirstLetter(
                                     originaddress?.state || data?.origin_location_state
                                    ) || '--'}
                                  </div>
                                </div>
                                {data?.origin_location_postal_code?<>
                                  <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Postal Code :
                                  </label>
                                  <div className="profiledetailscls">
                                    {methodModel.capitalizeFirstLetter(
                                      data?.origin_location_postal_code
                                    ) || '--'}
                                  </div>
                                </div>
                                </>:<></>}

                                {originaddress?.country?<>
                                  <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Country :
                                  </label>
                                  <div className="profiledetailscls">
                                    {methodModel.capitalizeFirstLetter(
                                     originaddress?.country || data?.origin_location_country
                                    ) || '--'}
                                  </div>
                                </div>
                                </>:<></>}

                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls origin-address">
                                    Destination Address:
                                  </label>

                                  <div className="profiledetailscls read-more-new">

                                    {methodModel.capitalizeFirstLetter(

                                          data?.destination_address
                                      )}
                                  {/* {methodModel.capitalizeFirstLetter(
                                        data?.stops[data?.stops?.length - 1]
                                          ?.address ||
                                          data?.destination_location_street
                                      )} */}
                                  </div>
                                </div>

                                {/* <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    City :
                                  </label>
                                  <div className="profiledetailscls">
                                    {data && data?.destination_location_city}
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    State :
                                  </label>
                                  <div className="profiledetailscls">
                                    {methodModel.capitalizeFirstLetter(
                                      destinationaddress?.state || data?.destination_location_state
                                    ) || '--'}
                                  </div>
                                </div>
                                {data?.destination_location_postal_code?<>
                                  <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Postal Code :
                                  </label>
                                  <div className="profiledetailscls">
                                    {methodModel.capitalizeFirstLetter(
                                      data?.destination_location_postal_code
                                    ) || '--'}
                                  </div>
                                </div>
                                </>:<></>}


                              {destinationaddress?.country?<>
                                <div className="col-md-12 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Country :
                                  </label>
                                  <div className="profiledetailscls">
                                    {methodModel.capitalizeFirstLetter(
                                    destinationaddress?.country || data?.destination_location_country
                                    ) || '--'}
                                  </div>
                                </div>
                              </>:<></>}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 height-set">
                        <div className=" white-bg-main mb-4 ">
                          <div className=" white-head mb-3">
                            <h5 class="profilelist">Track Details</h5>
                          </div>
                          <div className="track-details mb-3">
                            <div className="col-md-12 mb-3 view-flex">
                              <label className="profileheddingcls">
                                Shipment Status :
                              </label>
                              <div className="profiledetailscls">
                                <div className="badge">
                                  {ReplaceUnderScoreWithSpace(
                                    data?.shipment_status
                                  ) || '--'}
                                </div>
                              </div>
                            </div>

                            {data?.pickedup_at != null ? (
                              <div className="col-md-12 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Pickup Date :
                                </label>
                                <div className="profiledetailscls">
                                  {moment(data?.pickedup_at).format(
                                    'DD-MM-YYYY , h:mm A'
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className={`${data?.load_start_date||data?.load_end_date?"col-xl-9 col-lg-8":"col-xl-12 col-lg-12"} height-set`} >
                        <div className=" white-bg-main mb-4 ">
                          <div className=" white-head mb-3">
                            <h5 class="profilelist">Basic Information</h5>
                          </div>
                          <div className="row">
                            {data?.load_id ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Load id:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.load_id}
                                </div>
                              </div>
                            ) : null}

                            {data?.lane_id ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Lane id:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.lane_id}
                                </div>
                              </div>
                            ) : null}
                            {data?.customer_name ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Customer's Name:
                                </label>
                                <div className="profiledetailscls">
                                  {data &&
                                    methodModel.capitalizeFirstLetter(
                                      data?.customer_name
                                    )}
                                </div>
                              </div>
                            ) : null}

                            {data?.total_distance ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Total Distance (Mi):
                                </label>
                                <div className="profiledetailscls">
                                  {data?.total_distance}
                                </div>
                              </div>
                            ) : null}

                            {data?.pu_rate ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  PU Rate:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.pu_rate}
                                </div>
                              </div>
                            ) : null}
                            {data?.bid_rate ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Bid Price:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.bid_rate}
                                </div>
                              </div>
                            ) : null}
                            {data?.notes ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Note:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.notes}
                                </div>
                              </div>
                            ) : null}
                            {data?.all_in_rate ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  All in Rate:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.all_in_rate}
                                </div>
                              </div>
                            ) : null}
                            {data?.capacity ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Capacity:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.capacity}
                                </div>
                              </div>
                            ) : null}
                            {data?.comments ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Cooment:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.comments}
                                </div>
                              </div>
                            ) : null}
                            {data?.dispatch_days ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Dispatch Days:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.dispatch_days}
                                </div>
                              </div>
                            ) : null}
                            {data?.est_volume ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  EST Volume:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.est_volume}
                                </div>
                              </div>
                            ) : null}
                            {data?.frequency ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Frequency:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.frequency}
                                </div>
                              </div>
                            ) : null}
                            {data?.lane_id ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Lane ID:
                                </label>
                                <div className="profiledetailscls">
                                  {data?.lane_id}
                                </div>
                              </div>
                            ) : null}
                            {data?.pu_date_time ? (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Lane ID:
                                </label>
                                <div className="profiledetailscls">
                                  {moment(data?.pu_date_time).format(
                                    'DD-MMM-YYYY h:mm A'
                                  )}
                                </div>
                              </div>
                            ) : null}

                            {/* {data?.load_start_date != null && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Load Start DateTime:
                                </label>
                                <div className="profiledetailscls">
                                  {moment(data?.load_start_date).format(
                                    'DD-MMM-YYYY HH:MM A'
                                  )}
                                </div>
                              </div>
                            )} */}
                            {data?.pu_date_time && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  PU Date Time:
                                </label>
                                <div className="profiledetailscls">
                                  {moment(data?.load_start_date).format(
                                    'DD-MMM-YYYY HH:MM A'
                                  )}
                                </div>
                              </div>
                            )}

                            {/* {data?.load_end_date && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  Load End DateTime:
                                </label>
                                <div className="profiledetailscls">
                                  {moment(data?.load_end_date).format(
                                    'DD-MMM-YYYY HH:MM A'
                                  )}
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                      {data?.load_start_date||data?.load_end_date? <div className="col-xl-3 col-lg-4 height-set">
                        <div className=" white-bg-main mb-4 ">
                          <div className=" white-head mb-3">
                            <h5 class="profilelist">Loads Status</h5>
                          </div>

                          <div className="track-details mb-3">

                              {data?.load_start_date != null && (
                                <div>
                                  <div className="loc-flex">
                                   <img src="/assets/img/picked.svg" />
                                  <h5 className="">
                                    {moment(data?.load_start_date).format(
                                      'DD-MMM-YYYY , HH:MM A'
                                    )}
                                    <span>Load Start DateTime:</span>
                                  </h5>
                                </div>
                                </div>
                              )}

                            {data?.load_end_date && data?.load_start_date?  <div className="arrow-center">

                            </div>:""}

                            {data?.load_end_date && (
                              <div className="loc-flex">
                                <img src="/assets/img/fast.svg" />
                                <h5>
                                  {moment(data?.load_end_date).format(
                                    'DD-MMM-YYYY , HH:MM A'
                                  )}
                                  <span> Load End DateTime:</span>
                                </h5>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>:<></>}

                    </div>

                    <div className="row">
                     {data?.stops?.length > 1
                     ?
                         <div className="col-lg-6 height-set">
                         <div className=" white-bg-main mb-4 ">
                           <div className="  white-head mb-3">
                             <h5 class="profilelist">Track Details</h5>
                           </div>
                           <div className="row">
                             <div className="col-lg-12">
                               <div className="steps-main steps-scroll">
                                 {data?.status == 'accepted' && (
                                   <div className=" mb-3 view-flex">
                                     <label className="profileheddingcls">
                                       {data?.delivered_at != null
                                         ? ' Delivered At :'
                                         : data?.transit_at && 'Transit At'}
                                     </label>
                                     <div className="profiledetailscls">
                                       {data?.shipment_status == 'delivered'
                                         ? moment(data?.delivered_at).format(
                                             'DD-MMM-YYYY h:mm A'
                                           )
                                         : data?.transit_at &&
                                           moment(data?.transit_at).format(
                                             'DD-MMM-YYYY h:mm A'
                                           )}
                                     </div>
                                   </div>
                                 )}
                                 {data?.status == 'active' && (
                                   <div className="p-3">
                                     {data?.stops?.length > 1 ? (
                                       <Steps
                                         vertical
                                         style={styles}
                                         current={ActiveStop}
                                       >
                                         <Steps.Item
                                           title={
                                             data?.stops[0]?.checkin
                                               ? 'Picked Up'
                                               : 'Pickup Pending'
                                           }
                                           description={data?.origin_address}
                                         />
                                         {data?.stops?.map((itm, index) => {
                                           return (
                                             <>
                                             {index>0?<Steps.Item
                                                 title={`${
                                                   (index <
                                                   data?.stops?.length - 1 && data?.shipment_status=="in_transit" )
                                                     ? 'In Transit -'
                                                     : ''
                                                 } ${itm?.address}`}
                                                 description={
                                                   itm.checkin &&
                                                   index ==
                                                     data?.stops?.length - 1
                                                     ? itm?.checkin &&
                                                       `Delivered at ${moment(
                                                         itm?.checkin
                                                       ).format(
                                                         'DD-MM-YYYY- HH:MM A'
                                                       )} ${itm?.address} `
                                                     : itm?.checkin &&
                                                       `Checkedin ${moment(
                                                         itm?.checkin
                                                       ).fromNow()}  ${
                                                         itm?.checkout == null
                                                           ? ''
                                                           : `|| checkedOut ${moment(
                                                               itm?.checkout
                                                             ).fromNow()}`
                                                       }`
                                                 }
                                               />:<></>}

                                             </>
                                           );
                                         })}
                                       </Steps>
                                     ) : (
                                       <>
                                         <Steps
                                           current={
                                             Stops[1]?.date ||
                                             Stops[1]?.delivered_at
                                               ? 2
                                               : 0
                                           }
                                           vertical
                                           style={styles}
                                         >
                                           {Stops?.map((itm, index) => {
                                             return (
                                               <>
                                                 <Steps.Item
                                                   title={
                                                     itm?.address ||
                                                     itm?.destination_address
                                                   }
                                                   description={
                                                     Stops[1]?.date
                                                       ? 'Delivery'
                                                       : 'No status'
                                                   }
                                                 />
                                               </>
                                             );
                                           })}
                                         </Steps>
                                       </>
                                     )}
                                   </div>
                                 )}
                                 {data?.pod_doc?.length != 0 &&
                                 data?.status == 'awarded' ? (
                                   <div className="col-md-12 mb-3 ">
                                     <label className="profileheddingcls">
                                       POD (Proof of Delivery) :
                                     </label>
                                     <div className="prodflex">
                                       {data?.pod_doc?.map((itm) => {
                                         return (
                                           <div className="profiledetailscls position-relative">
                                             <img
                                               src="/assets/img/pdf.png"
                                               className="pdfimg"
                                             />

                                             <i
                                               title="Download"
                                               onClick={() => {
                                                 if (itm?.includes('.pdf')) {
                                                   downloadFile(itm);
                                                 }

                                                 if (itm?.includes('png')) {
                                                   downloadImage(itm, 'png');
                                                 }
                                                 if (itm?.includes('.jpeg')) {
                                                   downloadImage(itm, 'jpeg');
                                                 }
                                                 if (itm?.includes('.jpg')) {
                                                   downloadImage(itm, 'jpg');
                                                 }
                                                 // downloadImage(itm);
                                               }}
                                               class="fa fa-download docdown"
                                             ></i>
                                           </div>
                                         );
                                       })}
                                     </div>
                                   </div>
                                 ) : null}

                                 {data?.bol_doc?.length != 0 &&
                                 data?.status == 'awarded' ? (
                                   <div className="col-md-12 mb-3 mt-4">
                                     <label className="profileheddingcls">
                                       BOL (Bill of Landing) :
                                     </label>
                                     <div className="prodflex">
                                       {data?.bol_doc?.map((itm) => {
                                         return (
                                           <div className="profiledetailscls position-relative">
                                             <img
                                               src="/assets/img/pdf.png"
                                               className="pdfimg"
                                             />
                                             <i
                                               title="Download"
                                               onClick={() => {
                                                 if (itm?.includes('.pdf')) {
                                                   downloadFile(itm);
                                                 }

                                                 if (itm?.includes('png')) {
                                                   downloadImage(itm, 'png');
                                                 }
                                                 if (itm?.includes('.jpeg')) {
                                                   downloadImage(itm, 'jpeg');
                                                 }
                                                 if (itm?.includes('.jpg')) {
                                                   downloadImage(itm, 'jpg');
                                                 }
                                                 // downloadImage(itm);
                                               }}
                                               class="fa fa-download docdown"
                                             ></i>
                                           </div>
                                         );
                                       })}
                                     </div>
                                   </div>
                                 ) : null}

                                 {data?.role == 'staff' && (
                                   <div className="col-md-12 mb-3">
                                     <label className="profileheddingcls">
                                       Permissions
                                     </label>
                                     {Permission && (
                                       <table className="table custom-table border">
                                         <thead>
                                           <tr>
                                             <th scope="col"> User Add</th>
                                             <th scope="col"> User Edit</th>
                                             <th scope="col"> User Read</th>
                                             <th scope="col">User Delete</th>
                                             {/* <th scope="col"> User Complete Access </th> */}
                                           </tr>
                                         </thead>
                                         <tbody>
                                           <tr>
                                             <td>
                                               <span class="material-icons rights-ic ">
                                                 {Permission?.carrier_add
                                                   ? 'done'
                                                   : 'clear'}
                                               </span>
                                             </td>
                                             <td>
                                               <span class="material-icons rights-ic ">
                                                 {Permission?.carrier_edit
                                                   ? 'done'
                                                   : 'clear'}
                                               </span>
                                             </td>
                                             <td>
                                               <span class="material-icons rights-ic ">
                                                 {Permission?.carrier_get
                                                   ? 'done'
                                                   : 'clear'}
                                               </span>
                                             </td>
                                             <td>
                                               <span class="material-icons rights-ic ">
                                                 {Permission?.carrier_delete
                                                   ? 'done'
                                                   : 'clear'}
                                               </span>
                                             </td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     )}
                                   </div>
                                 )}
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                        :
                        <></>}

                      <div className={`${data?.stops?.length >1?"col-lg-6 height-set":"col-lg-12 "}`}>
                        <div className=" white-bg-main mb-4 ">
                          <div className=" white-head mb-3">
                            <h5 class="profilelist">Stop Details</h5>
                          </div>
                          <div className="table-responsive table_section mt-0 p-3">
                          <table className="table">
                            <thead>
                              <th>Stop No.</th>
                              {data?.stops?.find((item, index) => item?.location_id) ? <th>Location Id</th> : <></>}
                              <th>Address</th>
                            </thead>
                            <tbody>
                              {data?.stops?.map((itm, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    {itm?.location_id ? <td>{itm?.location_id}</td> : <></>}
                                    <td>{itm?.address}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default LoadDetails2;
