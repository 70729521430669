import React, { useState } from "react";
import WebsiteHeader from "../../components/global/WebsiteHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import loader from "../../methods/loader";
import { IoIosCall } from "react-icons/io";
function Contact() {
  const history = useHistory();
  const [form2, setform2] = useState({});

  const [form, setform] = useState({});

  const LetSubscribe = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.post("addNewsLetter", form2).then((res) => {
      if (res.success) {
        loader(false);
        setform2({ email: "" });

        // toast.success(res?.message);
      }
      loader(false);
    });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();

    ApiClient.post("contact-us", form).then((res) => {
      if (res.success) {
        // toast.success("Query sent successfuly");
        setform({
          firstName: "",
          lastName: "",
          email: "",
          mobileNo: "",
          message: "",
        });
      }
    });
  };
  return (
    <>
      <WebsiteHeader />
      <div className="page_wrapper">
        {/* Hero section */}

        <div className="contact_hero">
          <div className="container">
            <div className="contact_hero_parent">
              <button className="contact_btn">Contact us</button>
              <h4 className="contact_heading">
                Initiative Customer Support Available
              </h4>
            </div>
          </div>
        </div>

        {/* Support section */}

        <div className="container">
          <div className="row support_row">
            <div className="col-lg-7 support_col mt-0">
              <h6 className="support_heading">Support Center</h6>
              <p className="support_child">
                At One Brokerage LLC, your satisfaction and success are our top
                priorities. Our support team is dedicated to providing you with
                the assistance you need, whenever you need it. Below, you’ll
                find information on common inquiries and how to resolve them
                quickly.
              </p>
              <p className="support_desc">
                <h3 className="trackthree mb-0"> Tracking Your Shipment</h3>

                <h6 class="story_header serivceshhh mt-0">Live Tracking: </h6>
                <p className="story_descs serivcespp">
                  Access real-time updates by logging into your account on our
                  website or using our mobile app.
                </p>
                <h6 class="story_header serivceshhh ">Shipment Details: </h6>
                <p className="story_descs serivcespp">
                  For specific queries about your shipment’s status or to report
                  a discrepancy, please contact our support team directly.
                </p>
                <h3 className="trackthree mb-0"> Billing and Payments</h3>

                <h6 class="story_header serivceshhh mt-0">
                  Invoice Questions:{" "}
                </h6>
                <p className="story_descs serivcespp">
                  If you have questions about your invoice or need clarification
                  on billing details, our accounts support team is ready to
                  assist you.
                </p>

                <h6 class="story_header serivceshhh ">Payment Processing: </h6>
                <p className="story_descs serivcespp">
                  For help with payment submissions or to discuss payment
                  options, please reach out to our billing department. Service
                  Modifications
                </p>
                <h3 className="trackthree mb-0"> Service Modifications</h3>

                <h6 class="story_header serivceshhh mt-0">
                  Change of Service:{" "}
                </h6>
                <p className="story_descs serivcespp">
                  Need to upgrade to expedited shipping or add white glove
                  service? Contact us to modify your service options.
                </p>
                <h6 class="story_header serivceshhh ">
                  Cancellation Requests:{" "}
                </h6>
                <p className="story_descs serivcespp">
                  If you need to cancel or reschedule a shipment, please get in
                  touch as soon as possible to avoid any charges.
                </p>
                <h3 className="trackthree mb-0"> Technical Support</h3>
                <h6 class="story_header serivceshhh mt-0">Website Issues: </h6>
                <p className="story_descs serivcespp">
                  Experiencing difficulties with our website or mobile app? Our
                  technical support team is here to help troubleshoot and
                  resolve issues quickly.
                </p>
                <h6 class="story_header serivceshhh ">Account Access: </h6>
                <p className="story_descs serivcespp">
                  If you’re having trouble accessing your account or need
                  assistance with account settings, please contact our technical
                  support.
                </p>
                <h3 className="trackthree mb-0 mt-2">
                  {" "}
                  Freight Management Consulting
                </h3>

                <h6 class="story_header serivceshhh mt-0">
                  Optimization Inquiries:{" "}
                </h6>
                <p className="story_descs serivcespp">
                  If you’re having trouble accessing your account or need
                  assistance with account settings, please contact our technical
                  support.{" "}
                </p>

                <h3 className="trackthree mb-0"> General Inquiries</h3>
                <h6 class="story_header serivceshhh mt-0">
                  Additional Information:{" "}
                </h6>
                <p className="story_descs serivcespp">
                  For any other questions or concerns, or to learn more about
                  our services, our customer representatives are ready to
                  provide you with detailed information and support.
                </p>
                <p></p>
              </p>
            </div>
            <div id="getInTouch" className="col-lg-5">
              <div className="support_card">
                <div className="card_heading_section">
                  <h4 className="Card_heading">Get In Touch</h4>
                  <p className="card_desc">Send your query</p>
                  <hr className="card_border"></hr>
                </div>
                <form onSubmit={HandleSubmit}>
                  <div className="row mt-3">
                    <div className="col-md-6 mb-4">
                      <label>
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        value={form?.firstName}
                        onChange={(e) =>
                          setform({ ...form, firstName: e.target.value })
                        }
                        required
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      {" "}
                      <label>
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        value={form?.lastName}
                        onChange={(e) =>
                          setform({ ...form, lastName: e.target.value })
                        }
                        required
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>
                        Email Id<span className="text-danger">*</span>
                      </label>
                      <input
                        value={form?.email}
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                        required
                        type="email"
                        class="form-control"
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>Phone (optional)</label>
                      <input
                        value={form?.mobileNo}
                        onChange={(e) =>
                          setform({ ...form, mobileNo: e.target.value })
                        }
                        // required
                        type="number"
                        class="form-control"
                      />
                    </div>
                    <div className="col-md-12 mb-4">
                      <label>
                        Message<span className="text-danger">*</span>
                      </label>
                      <textarea
                        value={form?.message}
                        onChange={(e) =>
                          setform({ ...form, message: e.target.value })
                        }
                        required
                        className="form-control border-0"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="col-md-12 text-end">
                      <button className="btn dark-btn  ">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Assistance section */}
          <div className="support_row">
            <h6 className="support_heading">Need Assistance?</h6>
            <div className="row mt-4 justify-content-center">
              {/* <div className="col-md-4 mb-3">
                <div className="assistance_card text-center">
                  <div>
                    <img
                      src="/assets/img/People.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p className="as_head ">Visit our Forum</p>
                  <p className="as_desc ">
                    Go to our community forum to ask questions and take
                    advantage of the incredible experience of the worldwide
                    television industry, 24 hours a day!
                  </p>
                  <button className="btn dark-btn ">Visit the Forum</button>
                </div>
              </div> */}
              <div className="col-md-4 mb-3">
                <div className="assistance_card text-center">
                  <div>
                    <img src="/assets/img/Email.svg" class="img-fluid" alt="" />
                  </div>
                  <p className="as_head ">Email Us </p>
                  <p className="as_desc ">
                    When you need to ask us a specific question, simply contact
                    us via email and one of our support engineers will get back
                    to you with an answer.
                  </p>
                  <a href="#getInTouch">
                    <button className="btn dark-btn ">Send us an Email</button>
                  </a>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="assistance_card text-center">
                  <div>
                    <img src="/assets/img/Phone.svg" class="img-fluid" alt="" />
                  </div>
                  <p className="as_head ">Telephone Us</p>
                  <p className="as_desc ">
                    When you need urgent help, call the Blackmagic Design
                    support office closest to you. If you need out of hours
                    help, call one of our worldwide support offices.
                  </p>
                  <a
                    onClick={() => {
                      document.getElementById("OpenContactModal").click();
                    }}
                  >
                    <button className="btn dark-btn ">Contact Us</button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Growth section */}
          <div className="growth_div support_row">
            <h3 className="growth_header">Start Your Growth Today</h3>
            <p className="growth_desc">
              Here are more ways to discover how we can help you.
            </p>
            <div className="growth_btns d-flex">
              <button
                className="btn dark-btn"
                onClick={() => {
                  document.getElementById("OpenContactModal").click();
                }}
              >
                Contact Us
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  history.push("/our-services");
                }}
              >
                About Us
              </button>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer_section section-padding">
        <div class="container">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-12  col-lg-8">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                  <div class="footer_space">
                    <img
                      src="assets/img/footer_logo.png"
                      class="img-fluid"
                      alt="logo"
                    />
                    <p class="footer_details">
                      Your trusted partner in transportation, delivering results
                      that move businesses forward.
                    </p>
                    <ul class="footer_iconlist">
                      <li>
                        <a href="#">
                          <div class="footer_img ">
                            <img
                              src="assets/img/youtube.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/twitter.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/insta.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div class="footer_img">
                            <img
                              src="assets/img/facebook.png"
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                  <div class="row">
                    <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                      <div class="footer_space">
                        <h5 class="footer_heading">Quick Links</h5>

                        <ul class="footer_items">
                          <li>
                            <a href="#"> About Us</a>
                          </li>
                          {/* <li>
                            <a href="#"> Service</a>
                          </li> */}
                          <li>
                            <a href="/support"> Support</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class=" col-12 col-sm-7 col-md-7 col-lg-7">
                      <div class="footer_space">
                        <h5 class="footer_heading">Contact Us</h5>

                        <ul class="footer_items">
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/message.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">hello@website.com</p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/map.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">
                                Riverside Building, County Hall, London SE1 7PB,
                                United Kingdom
                              </p>
                            </div>
                          </li>
                          <li>
                            <div class="footer_list">
                              <img
                                src="assets/img/call.png"
                                class="all_icon"
                                alt=""
                              />
                              <p class="list_name">+15055221111</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
              <div class="footer_space">
                <h5 class="footer_heading">Newsletter</h5>
                <div class="position-relative">
                  <form onSubmit={LetSubscribe}>
                    <input
                      type="email"
                      value={form2?.email}
                      required
                      onChange={(e) =>
                        setform2({ ...form2, email: e.target.value })
                      }
                      class="form-control enter_mail"
                      placeholder="Enter your email"
                    />
                    <button class="subscribe_btn">Subscribe</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button
        type="button"
        id="OpenContactModal"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#ContactModal"
      >
        Launch demo modal
      </button>
      <div
        class="modal fade"
        id="ContactModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Contact Us
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="text-center">
              <img src="/assets/img/call.jpg" className="callme" />
            </div>
            <div class="modal-body documentation_modal m-auto calldiv my-5">
              <div className="d-flex gap-1 callflex my-3">
                <IoIosCall />
                <p class="list_name text-dark m-0">+15055221111</p>
              </div>
            </div>
            {/* <div class="modal-footer border-0"></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
