import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./style.scss";
import methodModel from "../../methods/methods";
import { logout, toggleMode } from "../../actions/user";

import ApiClient from "../../methods/api/apiClient";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import environment from "../../environment";
import moment from "moment";
import loader from "../../methods/loader";

const WebsiteHeader = () => {
  const [token, setToken] = useState("");
  const websitedetails = useSelector((state) => state.website);
  const user = useSelector((state) => state.user);
  const mode = useSelector((state) => state.user);
  const [scrollClass, setScrollClass] = useState("bgtrans");
  const history = useHistory();
  const [total, settotal] = useState(0);
  const dispatch = useDispatch();
  const [List, setList] = useState([]);
  let location = useLocation();


  const GetNotification = () => {
    ApiClient.get("notification/all", {
      send_to: user?.id,
      status: "unread",
    }).then((res) => {
      if (res.success) {
        settotal(res?.data?.total_count);
        setList(res?.data?.data);
      }
    });
  };

  const handleModeChange = () => {
    dispatch(toggleMode());
  };

  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    // toast.success("You have log out successfuly");
    history.push("/");
  };

  const [Pages, setPages] = useState([]);
  const GetWebsitePages = () => {
    ApiClient.get(
      `cms/listing?search=&status=&category=657afeaed8d855aee5289478`
    ).then((res) => {
      if (res.success) {
        setPages(res?.data);
      }
    });
  };
  useEffect(() => {
    // GetWebsitePages()
  }, []);

  useEffect(() => {
    if (user && user?.id)
      if(token)
      {
        GetNotification();
      }

  }, [user]);

  const PlaidLinkOptions = {
    onSuccess: (public_token, metadata) => {
      ApiClient.post("plaid/exchange-public-token", {
        public_token: public_token,
      }).then((res) => {
        console.log(res, "resssss======public_token");
      });
      console.log(public_token, "public_token, metadata");
    },
    onExit: (err, metadata) => {
      console.log(err, metadata, "err, metadata");
    },
    onEvent: (eventName, metadata) => {
      console.log(eventName, metadata, "eventName, metadata");
    },
    token: token,
  };
  const { open, exit, ready } = usePlaidLink(PlaidLinkOptions);

  useEffect(() => {
    const handleScroll = () => {
      // Add logic to determine when to change the class based on scroll position
      if (window.scrollY > 100) {
        setScrollClass("dark-header");
      } else {
        setScrollClass("bgtrans");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const UpdateNotificationStatus = (id, status) => {
    loader(true);
    ApiClient.put("notification/change-status", {
      id: id,
      status: status,
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();
      }
      loader(false);
      GetNotification();
    });
  };
  const UpdateNotificationStatusAll = (id, status) => {
    loader(true);
    ApiClient.put("notification/change-status-all", {
      status: "read",
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();
      }
      loader(false);
      GetNotification();
    });
  };
  return (
    <>
      {/* Header Section */}

      <header
        className={location.pathname == "/" ? scrollClass : "dark-header "}
      >
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid ">
            <a class="navbar-brand ">
              <img
                src="/assets/img/logo.png"
                onClick={() => {
                  history.push("/");
                }}
                class="onelogo"
              />
            </a>
            <div className="d-flex gap-2">
              <div className="phonenoti">
                {" "}
                {user && user?.id ? (
                  <div class="dropdown secdrop2">
                    {total>0?<span className="notcount">{total}</span>:<></>}

                    <a
                      id="dLabel"
                      role="button"
                      data-bs-toggle="dropdown"
                      data-target="#"
                      href="/page.html"
                      onClick={() => {
                        GetNotification();
                      }}
                    >
                      <i class="fa fa-bell"></i>
                    </a>

                    <ul
                      class="dropdown-menu notifications notifilayout "
                      role="menu"
                      aria-labelledby="dLabel"
                    >
                      <div class="notification-heading ">
                        <h4 class="menu-title">Notifications</h4>
                        {/* <h4 class="menu-title pull-right">
                      View all
                      <i class="glyphicon glyphicon-circle-arrow-right"></i>
                    </h4> */}
                        {List?.length > 0 && (
                          <button
                            onClick={() => {
                              UpdateNotificationStatusAll("read");
                            }}
                            className="btn btn-primary"
                          >
                            Clear All
                          </button>
                        )}
                      </div>
                      <li class="divider"></li>
                      <div class="notifications-wrapper">
                        {List?.length > 0 ? (
                          List?.map((itm, index) => {
                            return (
                              <a class="content">
                                <div
                                  class="notification-item"
                                  onClick={() => {
                                    UpdateNotificationStatus(itm?.id, "read");
                                    history.push(`bids/Detail/${itm?.bid_id}`);
                                  }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    {" "}
                                    <img
                                      className="noteimg"
                                      src={`${environment.api}/images/users/${itm?.addedBy_image}`}
                                      alt=""
                                    />
                                    {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                    <p class="m-0 messagep pl-1">
                                      {" "}
                                      {itm?.message}{" "}
                                    </p>
                                  </div>{" "}
                                  <p className="text-end m-0 nottime">
                                    {moment(itm?.createdAt).format(
                                      "DD MMM YYYY h:m A"
                                    )}
                                  </p>
                                </div>
                              </a>
                            );
                          })
                        ) : (
                          <a class="content">
                            <div class="pt-3">
                              <h6>No Notifications</h6>
                            </div>
                          </a>
                        )}
                      </div>
                      <li class="divider"></li>
                    </ul>
                  </div>
                ) : null}
              </div>

              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarTogglerDemo02"
            >
              <ul class="navbar-nav  mb-2 mb-lg-0">
                {/* <li class="nav-item">
                  <Link
                    class="nav-link active"
                    aria-current="page"
                    to="/about-us"
                  >
                    About Us
                  </Link>
                </li> */}
                <li class="nav-item">
                  <Link class="nav-link" to="/our-services">
                    About Us
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to={`${user?.loggedIn?"/jobs":"/login"}`}>
                    Loads
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/contact-us">
                    Contact us
                  </Link>
                </li>
              </ul>
              {user && user?.id ? (
                <div class="dropdown secdrop2 desknoti">
                  {total>0?<span className="notcount">{total}</span>:<></>}
                  <a
                    id="dLabel"
                    role="button"
                    data-bs-toggle="dropdown"
                    data-target="#"
                    href="/page.html"
                    onClick={() => {
                      GetNotification();
                    }}
                  >
                    <i class="fa fa-bell"></i>
                  </a>

                  <ul
                    class="dropdown-menu notifications notifilayout "
                    role="menu"
                    aria-labelledby="dLabel"
                  >
                    <div class="notification-heading ">
                      <h4 class="menu-title">Notifications</h4>
                      {/* <h4 class="menu-title pull-right">
                      View all
                      <i class="glyphicon glyphicon-circle-arrow-right"></i>
                    </h4> */}
                      {List?.length > 0 && (
                        <button
                          onClick={() => {
                            UpdateNotificationStatusAll("read");
                          }}
                          className="btn btn-primary"
                        >
                          Clear All
                        </button>
                      )}
                    </div>
                    <li class="divider"></li>
                    <div class="notifications-wrapper">
                      {List?.length > 0 ? (
                        List?.map((itm, index) => {
                          return (
                            <a class="content">
                              <div
                                class="notification-item"
                                onClick={() => {
                                  UpdateNotificationStatus(itm?.id, "read");
                                  history.push(`bids/Detail/${itm?.bid_id}`);
                                }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  {" "}
                                  <img
                                    className="noteimg"
                                    src={`${environment.api}/images/users/${itm?.addedBy_image}`}
                                    alt=""
                                  />
                                  {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                  <p class="m-0 messagep pl-1">
                                    {" "}
                                 {itm?.message}{" "}
                                  </p>
                                </div>{" "}
                                <p className="text-end m-0 nottime">
                                  {moment(itm?.createdAt).format(
                                    "DD MMM YYYY h:m A"
                                  )}
                                </p>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <a class="content">
                          <div class="pt-3">
                            <h6>No Notifications</h6>
                          </div>
                        </a>
                      )}
                    </div>
                    <li class="divider"></li>
                  </ul>
                </div>
              ) : null}
              {localStorage.getItem("token") ? (
                <div class="dropdown first_dropdown secdrop">
                  <button
                    class="btn  dropdown-toggle c-dropdown"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      alt="image"
                      src={methodModel.userImg(user.image)}
                      width={30}
                      height={30}
                      className="rounded-circle mr-1"
                    />
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover prodiv_cl"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link to="/dashboard" className="dropdown-item has-icon">
                        <i class="fa fa-th-large me-1" aria-hidden="true"></i>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile" className="dropdown-item has-icon">
                        <i className="far fa-user mr-1" /> Profile
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/profile" className="dropdown-item has-icon ">
                        <i className="fa fa-cog mr-1" aria-hidden="true"></i>{" "}
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <a
                        id="WantLogout"
                        onClick={() => {
                          Logout();
                        }}
                        className="dropdown-item has-icon"
                      >
                        <i className="fas fa-sign-out-alt mr-1" /> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="header-btns  ms-4">
                  <ul className="d-flex p-0 m-0">
                    <li>
                      <button
                        className="btn btn-primary"
                        onClick={() => history.push("/login")}
                      >
                        Sign In
                      </button>
                    </li>
                    <li className="ms-2">
                      <button
                        className="btn btn-secondary"
                        onClick={() => history.push("/signup")}
                      >
                        Sign Up
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default WebsiteHeader;
