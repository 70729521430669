import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import { ToastsStore } from 'react-toasts';
import methodModel from '../../../methods/methods';
import { login_success } from '../../../actions/user';
import './style.scss';
import { userType } from '../../../models/type.model';
import Html from './Html';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { toast } from 'react-toastify';

const EditProfile = p => {
  const user = useSelector((state) => state.user);
  const [addressSellected,setAddressSellected]=useState(false);
  const [ProtofolioError,setProtofolioError]=useState(false);
  const [images,setimages]=useState([]);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({ id:'',mobileNo:'',company:'',description:"",address:"",dialCode:"1",location:'',country:'',title:'',hourlyRate:"",startDate:'',endDateDate:'' ,description:'',portfolioUrl:'',timeZone:'',business_name:""});
  const dispatch = useDispatch();
  const history=useHistory()
  const [submitted, setSubmitted] = useState(false)

  const formValidation = [
    // { key: 'mobileNo', minLength: 10 },
    // { key: 'dialCode', dialCode: true },
  ]

  useEffect(() => {
    setForm({...form,dialCode:'1' })
  }, [user])

  const gallaryData = () => {
    loader(true)
    ApiClient.get(`user/detail`,{id:user.id}).then(res => {
      if (res.success) {
        setForm({form,...res.data,role:res?.data?.role,dialCode: '1' })
        setData(res.data)
      }
      loader(false)
    })
  };

  const getError = (key) => {
    return methodModel.getError(key, form, formValidation)
  }

  const handleSubmit = e => {
    setProtofolioError(false)
    e.preventDefault();
    setSubmitted(true)
    let invalid = methodModel.getFormError(formValidation, form)
    if (invalid) return
    if(form.address=="")return
    let value = { fullName: form.fullName, dialCode:form.dialCode, mobileNo: form.mobileNo, image: form.image, id: form.id,address:form.address,email:form?.email
      // hourlyRate:form.hourlyRate,country:form.country,city:form.city,state:form.state
    }
    loader(true)
    ApiClient.put('user/profile', value).then(res => {
      if (res.success) {
        let uUser = { ...user, ...value }
        dispatch(login_success(uUser))
        history.push("/profile1")
        // toast.success(res.message)
      }
      loader(false)
    })
  };

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value })
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('upload/image?modelName=users', { file: file}).then(res => {
      if (res.success) {
        let image = res?.data?.fullpath
        setForm({ ...form, image: image, baseImg: '' })
      } else {
        setForm({ ...form, baseImg: '' })
      }
      loader(false)
    })
  }



  useEffect(
    () => {
      if (user && user?.id) {
        gallaryData();
      }
    },
    [user]
    // here '[]' is used for, loop se bachne k liye
  );

  return (
    <>
    <Layout>
     <Html
     handleSubmit={handleSubmit}
     setForm={setForm}
     form={form}
     uploadImage={uploadImage}
     getError={getError}
     ProtofolioError={ProtofolioError}
     setProtofolioError={setProtofolioError}
     addressSellected={addressSellected}
     setAddressSellected={setAddressSellected}
     images={images}
     setimages={setimages}
     submitted={submitted}
     />
     </Layout>
    </>
  );
};

export default EditProfile;
